import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Input, { InputComponentType } from '../../../components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import communication from '../../../communication';
import { getAccessToken } from '../../../functions/auth';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useWindowSize from '../../../hooks/useResize';
import useAgencyStore from '../../../store/agency';
import Sidebar from '../../../components/sideModal';
import SendIOSModal from '../sendIOS';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Success from '../../../components/successModal';
import ErrorModal from '../../../components/errorModal';

interface Props {
    onFilterChange: Function;
}
interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const ReportFilters: FunctionComponent<Props> = ({ onFilterChange }) => {
    const [clients, setClients] = useState<Array<{ value: string; label: string }>>([]);
    const token = getAccessToken();
    const { agency } = useAgencyStore();
    const userId = agency.user_id;
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [sendModal, setSendModal] = useState(false);
    const [client_id, setClient_id] = useState<string>('');
    const [clientEmail, setClientEmail] = useState<string>('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [filters, setFilters] = useState<FilterProps>({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
        type: 'total',
    });
    const searchTypes = [
        { value: 'total', label: t('pages.reports.incomes').text },
        { value: 'client_single', label: t('pages.reports.incomesPerClient').text },
    ];

    const currencyTypes = [
        { value: '', label: t('pages.reports.alls').text },
        { value: 'RSD', label: 'RSD' },
        { value: 'EUR', label: 'EUR' },
        { value: 'USD', label: 'USD' },
        { value: 'CHF', label: 'CHF' },
        { value: 'GBP', label: 'GBP' },
        { value: 'AUD', label: 'AUD' },
        { value: 'CAD', label: 'CAD' },
        { value: 'NOK', label: 'NOK' },
        { value: 'RUB', label: 'RUB' },
    ];

    const successfullSend = (): void => {
        setSuccess(true);
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        setClients([{ value: 'all', label: 'Svi' }]);
        communication.getAllClientsForStatistic(userId).then((response: any) => {
            response.data.data.map((client: any) => {
                setClients((prevState) => [...prevState, { value: client.id, label: client.company_name }]);
            });
        });
    }, []);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    useEffect(() => {
        setClient_id(filters?.client_id ? filters.client_id : '');
    }, [filters.client_id]);

    useEffect(() => {
        communication.getClient(client_id).then((response: any) => {
            setClientEmail(response.data.data.email);
        });
    }, [client_id]);

    return (
        <>
            {loaderVisible && <Loader />}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={'IOS je uspešno poslat'} />
                </Modal>
            )}
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendIOSModal
                        close={() => setSendModal(false)}
                        t={t}
                        filters={filters}
                        success={() => {
                            successfullSend();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmail={clientEmail}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            <FiltersWrapper>
                <article className={'filter-field'}>
                    <div className={'statistics-form-container'}>
                        {isMobileWidth ? (
                            <Row>
                                <div>
                                    <div className={'label-text large'}>{t('pages.reports.period_from').text}</div>
                                    <Input
                                        type={InputComponentType.Date}
                                        className={'datepicker'}
                                        date={!filters.start_date ? null : moment(filters.start_date).toDate()}
                                        onChange={(value: Date | undefined | null) => {
                                            setFilters((prevState) => ({
                                                ...prevState,
                                                start_date: moment(value).format('YYYY-MM-DD'),
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: '12px' }}>
                                    <div className={'label-text '}>{t('pages.reports.period_to').text}</div>
                                    <Input
                                        type={InputComponentType.Date}
                                        className={'datepicker'}
                                        date={!filters.end_date ? null : moment(filters.end_date).toDate()}
                                        onChange={(value: Date | undefined | null) =>
                                            setFilters((prevState) => ({
                                                ...prevState,
                                                end_date: moment(value).format('YYYY-MM-DD'),
                                            }))
                                        }
                                    />
                                </div>
                            </Row>
                        ) : (
                            <div className="central-date">
                                <div className="left">
                                    <div className={'label-text large'}>{t('pages.reports.period_from').text}</div>
                                    <Input
                                        type={InputComponentType.Date}
                                        className={'datepicker'}
                                        date={!filters.start_date ? null : moment(filters.start_date).toDate()}
                                        onChange={(value: Date | undefined | null) => {
                                            setFilters((prevState) => ({
                                                ...prevState,
                                                start_date: moment(value).format('YYYY-MM-DD'),
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="right">
                                    <div className={'label-text '}>{t('pages.reports.period_to').text}</div>
                                    <div className="some-input">
                                        <Input
                                            type={InputComponentType.Date}
                                            className={'datepicker'}
                                            date={!filters.end_date ? null : moment(filters.end_date).toDate()}
                                            onChange={(value: Date | undefined | null) =>
                                                setFilters((prevState) => ({
                                                    ...prevState,
                                                    end_date: moment(value).format('YYYY-MM-DD'),
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        className={'download-main'}
                        onClick={async () => {
                            const response = await communication.downloadPdf(token, filters);
                            // Creating new object of PDF file
                            const file = new Blob([response?.data], { type: 'application/pdf' });
                            const fileURL = window.URL.createObjectURL(file);
                            // Setting various property values
                            const alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = t('pages.reports.report_pdf').text;
                            alink.click();
                        }}
                    >
                        <p>{t('pages.reports.download_pdf').text}</p>
                        <FontAwesomeIcon
                            icon={faDownload}
                            style={{ color: 'var(--black)' }}
                            width={'33px'}
                            height={'22px'}
                        />
                    </div>
                </article>
                <article className={'filter-field'}>
                    <div className={'statistics-form-container'}>
                        <div className={'label-text large'}>{t('pages.reports.search_type').text}</div>
                        <SelectComponent
                            className={'select-type'}
                            optionList={searchTypes}
                            defaultSelectedOption={searchTypes[0]}
                            handleSelectedValue={(value: string) =>
                                setFilters((prevState) => ({ ...prevState, type: value }))
                            }
                        />
                        {filters.type === 'client_single' && (
                            <div className="select-search-container">
                                <SelectComponent
                                    className={'select-client'}
                                    optionList={clients}
                                    placeholder={t('pages.reports.chooseClient').text}
                                    handleSelectedValue={(value: any) => {
                                        value !== 'all'
                                            ? setFilters((prevState) => ({ ...prevState, client_id: value }))
                                            : setFilters((prevState) => {
                                                  const { client_id: _client_id, ...rest } = prevState;
                                                  return { ...rest };
                                              });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={'download-main'}
                        onClick={async () => {
                            const response = await communication.downloadXlsx(token, filters);
                            // Creating new object of PDF file
                            const file = new Blob([response?.data], { type: 'application/vnd.ms-excel' });
                            const fileURL = window.URL.createObjectURL(file);
                            // Setting various property values
                            const alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = t('pages.reports.report_xlsx').text;
                            alink.click();
                        }}
                    >
                        <p>{t('pages.reports.download_xlsx').text}</p>
                        <FontAwesomeIcon
                            icon={faDownload}
                            style={{ color: 'var(--black)' }}
                            width={'33px'}
                            height={'22px'}
                        />
                    </div>
                </article>
                <article className={'filter-field'}>
                    <div className={'statistics-form-container'}>
                        <div className={'label-text large'}>{t('pages.reports.invoices_in_value').text} </div>
                        <SelectComponent
                            className={'select-type'}
                            optionList={currencyTypes}
                            defaultSelectedOption={currencyTypes[0]}
                            handleSelectedValue={(value: string) =>
                                setFilters((prevState) => ({ ...prevState, currency: value }))
                            }
                        />
                    </div>
                    {filters.type === 'client_single' && filters.client_id && (
                        <Ios>
                            <div
                                className={'download-main'}
                                onClick={async () => {
                                    setSendModal(true);
                                }}
                            >
                                <p>{t('pages.reports.send_ios').text}</p>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ color: 'var(--black)' }}
                                    width={'33px'}
                                    height={'22px'}
                                />
                            </div>
                            <div
                                className={'download-main'}
                                onClick={async () => {
                                    const iosFilters = {
                                        ...filters,
                                        is_ios: true,
                                    };
                                    const response = await communication.downloadPdf(token, iosFilters);
                                    // Creating new object of PDF file
                                    const file = new Blob([response?.data], { type: 'application/pdf' });
                                    const fileURL = window.URL.createObjectURL(file);
                                    // Setting various property values
                                    const alink = document.createElement('a');
                                    alink.href = fileURL;
                                    alink.download = t('pages.reports.report_ios').text;
                                    alink.click();
                                }}
                            >
                                <p>{t('pages.reports.download_ios').text}</p>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    style={{ color: 'var(--black)' }}
                                    width={'33px'}
                                    height={'22px'}
                                />
                            </div>
                        </Ios>
                    )}
                </article>
            </FiltersWrapper>
        </>
    );
};
const FiltersWrapper = styled.div`
    .central-date {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1320px) {
            flex-direction: column;
        }
        .right {
            display: flex;
            @media (max-width: 1320px) {
                // justify-content: flex-start;
                margin-top: 12px;
            }
        }
        .some-input {
            @media (max-width: 1320px) {
                margin-left: 105px;
            }
        }
    }
    background-color: var(--purple);
    padding: 10px 0;
    .filter-field {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
        }
        padding: 5px 15px 5px 0;
        vertical-align: middle;
        position: relative;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        &:first-of-type {
            .download-main {
                @media (min-width: 768px) {
                    margin-bottom: -25px;
                }
            }
        }
    }
    .select-search-container {
        position: absolute;
        margin-left: 5px;
        text-align-last: left;
        display: inline-block;
        font-family: inherit;
        border-style: hidden;
        font-size: 16px;
        cursor: pointer;
        flex-direction: column;
        width: 200px;
        @media (max-width: 670px) {
            position: inherit;
            margin-top: 5px;
        }
    }

    .statistics-form-container {
        position: relative;
        .select-wrapper {
            display: inline-block;
            background: white;
            color: var(--gray);
            font-size: 14px;
            min-width: 242px;
        }
        @media (max-width: 670px) {
            text-align: end;
            display: grid;
            justify-items: end;
        }
    }
    .datepicker-input-wrapper {
        padding-right: 5px;
        padding-left: 5px;
        input {
            font-size: 14px;
        }
    }
    .label-text {
        color: var(--white);
        font-size: 14px;
        line-height: 12px;
        text-transform: uppercase;
        padding: 10px 10px 10px 15px;
        display: inline-block;
        vertical-align: middle;
    }
    .large {
        width: 155px;
    }
    .datepicker {
        display: inline-block;
        width: auto;
        min-width: 252px;
        position: relative;
        vertical-align: middle;
        font-size: 14px;
        color: var(--gray);
        line-height: 20px;
        text-transform: uppercase;
        float: none;
        margin: 0;
        div:first-child {
            margin-bottom: 0;
            padding-right: 10px;
        }
        .date-container {
            background-color: var(--white);
        }
        .datepicker-input-wrapper {
            input {
                color: var(--gray);
            }
        }
    }
    .download-main {
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 670px) {
            justify-content: flex-end;
            margin-top: 12px;
        }
        p {
            color: var(--white);
            font-size: 14px;
        }
    }
`;
const Row = styled.div`
    @media (max-width: 670px) {
        display: flex;
        flex-direction: column;
        .datepicker {
            width: 190px;
            float: right;
            min-width: unset;
        }
    }
`;
const Ios = styled.div`
    display: flex;
    @media (max-width: 670px) {
        justify-content: flex-end;
    }
`;

export default ReportFilters;
