import { FunctionComponent, useState, useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
export type SelectOption = {
    value: string | number;
    label: string;
};
interface Props {
    label?: string;
    labelVariant?: 'light' | 'dark';
    className?: string;
    placeholder?: string;
    optionList: SelectOption[];
    value?: string | null | Array<{ value: any; label: any }>;
    handleSelectedValue?: Function;
    handleCreateOption?: (value: string) => void;
    width?: string;
    color?: string;
    defaultSelectedOption?: { value: string | number; label: string };
    isMultiple?: boolean;
    isCreatable?: boolean;
    isSearchable?: boolean;
    backgroundColor?: string;
    menuTextColor?: string;
    bottomBorder?: string;
}

interface SelectProps {
    width?: string;
    labelVariant: 'light' | 'dark';
}

const SelectComponent: FunctionComponent<Props> = ({
    label,
    labelVariant = 'light',
    className,
    placeholder,
    optionList,
    value,
    handleSelectedValue,
    width,
    color,
    defaultSelectedOption,
    isMultiple = false,
    isCreatable = false,
    handleCreateOption,
    isSearchable,
    backgroundColor,
    menuTextColor,
    bottomBorder,
}) => {
    const [selectedOptions, setSelectedOptions] = useState(value ? optionList.find((v) => v.value === value) : null);
    const handleSelect = (data: SelectOption): void => {
        if (isMultiple) {
            handleSelectedValue && handleSelectedValue(data);
            setSelectedOptions(data);
        } else {
            handleSelectedValue && handleSelectedValue(data.value);
            setSelectedOptions(data);
        }
    };

    useEffect(() => {
        if (defaultSelectedOption) setSelectedOptions(defaultSelectedOption);
    }, [defaultSelectedOption]);

    const colourStyles: StylesConfig<any> = {
        control: (base) => ({
            ...base,
            backgroundColor: 'transparent',
            borderRadius: '0px',
            cursor: 'pointer',
            boxShadow: 'none',
            height: 'fit-content',
            fontSize: '14px',
            border: '1px solid var(--border-color)',
            color: color,
            minHeight: 'none',
            ':hover': {
                border: '1px solid var(--border-color)',
            },
        }),
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: '',
                color: menuTextColor ? menuTextColor : 'var(--black)',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderWidth: '2px',
                borderRadius: '0',
                borderBottom: bottomBorder ? bottomBorder : '1px solid var(--border-color)',

                ':focus': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':active': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':hover': {
                    color: 'var(--gray)',
                },
            };
        },
        input: (styles) => ({
            ...styles,
            borderWidth: '2px',
            fontSize: '15px',
            color: color,
            lineHeight: '1.428571429',
            verticalAlign: 'middle',
            transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: color,
        }),
        singleValue: (styles) => ({
            ...styles,
            color: color,
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '250ms',
            width: '14px',
            height: '14px',
            padding: 0,
            marginRight: '5px',
            color: 'currentcolor',
        }),
        indicatorsContainer: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '32px',
        }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
        menu: (base) => ({
            ...base,
            maxHeight: '200px',
            overflow: 'auto',
            backgroundColor: backgroundColor ? backgroundColor : 'var(--white)',
            borderRadius: '0px',
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 'inherit',
            overflow: 'inherit',
        }),
    };

    return (
        <StyledSelectContainer width={width} labelVariant={labelVariant} className={'select-wrapper'}>
            <div className={`${className ?? ''}`}>
                {label ? <label>{label}</label> : <></>}
                {isCreatable ? (
                    <CreatableSelect
                        className={className}
                        classNamePrefix="select"
                        options={optionList}
                        onCreateOption={handleCreateOption}
                        onChange={handleSelect}
                        styles={colourStyles}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={isMultiple}
                        placeholder={selectedOptions ? selectedOptions.label : placeholder}
                        // defaultValue={optionList[0]}
                    />
                ) : (
                    <Select
                        classNamePrefix="select"
                        options={optionList}
                        onChange={handleSelect}
                        styles={colourStyles}
                        isClearable={false}
                        value={value}
                        isSearchable={isSearchable === false ? false : true}
                        isMulti={isMultiple}
                        placeholder={placeholder ? placeholder : selectedOptions && selectedOptions.label}
                        menuPlacement="auto"
                        // defaultValue={value === '1' ? optionList[1] : optionList[0]}
                    />
                )}
            </div>
        </StyledSelectContainer>
    );
};
const lightModeLabelStyles = `
color: var(--light-gray);
font-size: 13px;
padding: 5px;
line-height: 18px;
letter-spacing: -0.3px;
`;

const darkModeLabelStyles = `
font-size: 15px;
color: var(--gray);
margin-bottom: 2px;
&:hover {
    color: var(--purple);
}
`;

const StyledSelectContainer = styled.div<SelectProps>`
    text-align-last: left;
    display: flex;
    font-family: inherit;
    border-style: hidden;
    font-size: 15px;
    cursor: pointer;
    flex-direction: column;
    .select__placeholder {
        font-size: 15px;
    }
    .select-employment-type {
        margin-bottom: 8px;
        label {
            margin-bottom: 10px;
            padding: 0px;
            font-size: 15px;
        }
        .select__control {
            background-color: var(--white) !important;
            box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
            border-radius: 0;
        }
    }
    .select-client {
        text-align: left;
    }
    .select-lang {
        z-index: 0;
        width: 105px;
        font-size: 12px !important;
        label {
            color: var(--gray);
            margin-bottom: 10px;
            padding: 0px;
            font-size: 12px;
        }
        .select__control {
            border-radius: 0;
            border: none;
            font-size: 12px;
        }
        .select__placeholder {
            font-size: 13px;
        }
        .select__value-container {
            padding: 0px;
        }
    }
    .select-month {
        .select__control {
            height: auto;
        }
    }
    .select-items {
        .select__control {
            height: auto;
        }
    }
    .einvoices {
        label {
            color: var(--gray);
            margin-bottom: 10px;
            padding: 0px;
            font-size: 15px;
        }
        .select__control {
            border-radius: 0;
            border: 1px solid var(--purple);
        }
        margin-bottom: 8px;
    }

    width: ${(props) => (props.width ? `${props.width}` : '200px')};
    label {
        ${(props) => (props.labelVariant === 'light' ? lightModeLabelStyles : darkModeLabelStyles)}
    }
    &option {
        background-color: red;

        &--is-focused {
            background-color: green;
        }
    }
`;
export default SelectComponent;
