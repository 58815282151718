import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import DragAndDropItem from '../dragAndDrop/dragAndDropItem';
import comunication from '../../communication';
import useLanguageStore from '../../store/language';
import useTranslations from '../..//hooks/useTranslation';
import { dragAndDropIcon } from '../../assets/base64';
import Modal from '../../components/modal';
import ConfirmModal from '../../components/confirmModal';
import moment from 'moment';
import SelectComponent from '../../components/select';
import useAgencyStore from '../../store/agency';
import Loader from '../../components/loader';
import ErrorModal from '../../components/errorModal';
import { ObjectKeys } from '../../types/objectKeys';
import Pagination from '../../components/pagination';

interface Props {
    className?: string;
    fileTypes?: Array<string>;
    type?: string;
    description?: string;
    uploadFunction?: Function;
    multiple?: boolean;
    searchYear?: string;
    taxCurrentYear?: SelectOption;
}

type SelectOption = {
    value: string;
    label: string;
};

interface InstructionProps {
    agency?: Object;
    created_at: string;
    file_name: string;
    hashed_name: string;
    id: string;
    object: string;
    type: string;
    updated_at: string;
    user: Object;
    user_id: string;
}

const DragAndDrop: FunctionComponent<Props> = ({
    className = 'col-6 offset-3',
    fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif'],
    type,
    description,
    uploadFunction,
    multiple,
    searchYear,
    taxCurrentYear,
}) => {
    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: `year:${searchYear}`,
        searchFields: 'year:=',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [files, setFiles] = useState<Array<InstructionProps>>();
    const [meta, setMeta] = useState<ObjectKeys>({});
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>('');
    const [yearsForTaxSolution, setYearsForTaxSolution] = useState<SelectOption[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>(moment().year().toString());
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    const [overSizeModal, setOverSizeModal] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const agency = useAgencyStore((props) => props.agency);

    const getDocuments = async (): Promise<void> => {
        setLoaderVisible(true);
        if (type !== 'tax') {
            const response = await comunication.getAllInstructions();
            const res = response.data.data;
            setFiles(res.filter((file: { type: string }) => file.type === type));
        } else {
            const response = await comunication.getAllTaxSolutions(params);
            setMeta(response.data.meta);
            const res = response.data.data;
            setFiles(res);
        }
        setLoaderVisible(false);
    };
    useEffect(() => {
        setLoaderVisible(true);
        getDocuments();
        if (type === 'tax') {
            const registartionYear = moment(agency.date_of_registration).year();
            const currentYear = moment().year();
            for (let i = currentYear; i >= registartionYear; i--) {
                setYearsForTaxSolution((prevState) => [...prevState, { label: i.toString(), value: i.toString() }]);
            }
        }
        setLoaderVisible(false);
    }, [type]);
    useEffect(() => {
        getDocuments();
    }, [params.page]);

    useEffect(() => {
        setSelectedYear(taxCurrentYear ? taxCurrentYear?.value : yearsForTaxSolution[0]?.value);
    }, [taxCurrentYear?.value]);

    const handleChange = async (file: Blob[]): Promise<void> => {
        setLoaderVisible(true);
        if (uploadFunction) uploadFunction(file);
        else {
            if (type === 'tax') {
                for (let i = 0; i < file.length; i++) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (file[i].size > 5242880) {
                        setOverSizeModal(true);
                    } else {
                        await comunication.uploadTaxSolutions(file[i], selectedYear);
                        await comunication.sendTaxSolutionNotification();
                    }
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (file.size > 5242880) {
                    setOverSizeModal(true);
                } else {
                    await comunication.uploadForeignInstruction(file, '', type);
                }
            }
        }
        getDocuments();
        setLoaderVisible(false);
        // onChange(file);
    };

    const handleDelete = async (id: string): Promise<void> => {
        if (type === 'tax') {
            await comunication.deleteTaxSolution(id);
        } else {
            await comunication.deleteInstruction(id);
        }
        await getDocuments();
    };

    const handleDownload = async (id: string): Promise<void> => {
        if (type === 'tax') {
            await comunication.downloadTaxSolution(id, token);
        } else {
            await comunication.downloadInstruction(id, token);
        }
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <CustomFileUploader className={`uploader-wrapper ${className}`}>
                {modalActive && (
                    <Modal modalVisible={modalActive} closeModal={() => setModalActive(!modalActive)}>
                        <ConfirmModal
                            action={async () => {
                                setLoaderVisible(true);
                                setModalActive(false);
                                await handleDelete(selectedId);
                            }}
                            close={() => setModalActive(false)}
                            active={modalActive}
                            message={
                                type === 'tax'
                                    ? t('pages.agency.taxSolutions.delete').text
                                    : t('pages.agency.paymentInstructions.delete').text
                            }
                        />
                    </Modal>
                )}
                {wrongTypeModal && (
                    <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                        <ErrorModal
                            t={t}
                            errorMessage={
                                type === 'tax'
                                    ? t('pages.agency.taxSolutions.wrongType').text
                                    : t('pages.agency.paymentInstructions.wrongType').text
                            }
                            setOpenModal={setWrongTypeModal}
                        />
                    </Modal>
                )}
                {overSizeModal && (
                    <Modal modalVisible={overSizeModal} closeModal={() => setOverSizeModal(!overSizeModal)}>
                        <ErrorModal
                            t={t}
                            errorMessage={t('pages.error.overSize').text}
                            setOpenModal={setOverSizeModal}
                        />
                    </Modal>
                )}
                <div className="uploaded-files">
                    {files &&
                        files.length > 0 &&
                        files.map((file, index) => (
                            <DragAndDropItem
                                key={index}
                                name={file.file_name}
                                date={file.created_at}
                                id={file.id}
                                handleDelete={(id) => {
                                    setSelectedId(id);
                                    setModalActive(true);
                                }}
                                handleDownload={(id) => handleDownload(id)}
                                label={
                                    type === 'tax'
                                        ? t('pages.agency.taxSolutions.uploaded').text
                                        : t('pages.agency.paymentInstructions.uploaded').text
                                }
                            />
                        ))}
                </div>
                {files && meta.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${meta?.pagination?.current_page === meta?.pagination?.total_pages ? '' : '>'}`}
                        previousLabel={`${meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={meta?.pagination?.current_page - 1}
                    />
                )}
                <div className="description">
                    {description
                        ? description
                        : type === 'tax'
                        ? t('pages.agency.taxSolutions.description').text
                        : null}
                </div>
                {type === 'tax' && (
                    <div className={'year-container'}>
                        {t('pages.agency.taxSolutions.year').text}{' '}
                        <SelectComponent
                            optionList={yearsForTaxSolution}
                            defaultSelectedOption={taxCurrentYear ? (taxCurrentYear as any) : yearsForTaxSolution[0]}
                            handleSelectedValue={(value: any) => {
                                setSelectedYear(value);
                            }}
                        />
                    </div>
                )}
                {type === 'tax' && (
                    <FileUploader
                        multiple={multiple ? multiple : false}
                        handleChange={handleChange}
                        readFileAsBinary={true}
                        name="files"
                        types={fileTypes}
                        onTypeError={() => {
                            setWrongTypeModal(true);
                        }}
                    >
                        <div className={'uploader-wrapper'}>
                            <img src={dragAndDropIcon} className="upload-icon" />
                            <div className="upload-text">
                                {type === 'tax'
                                    ? t('pages.agency.taxSolutions.addDocument').text
                                    : t('pages.agency.paymentInstructions.addDocument').text}
                            </div>
                        </div>
                    </FileUploader>
                )}
            </CustomFileUploader>
        </>
    );
};

const CustomFileUploader = styled.article`
    display: inline-block;
    vertical-align: top;
    align-self: flex-end;
    border-radius: 10px;
    box-shadow: rgb(221, 221, 221) 0px 0px 5px 5px;
    margin-bottom: 30px;
    padding: 30px;
    color: rgb(146, 165, 172);
    width: 100%;

    .description {
        text-align: center;
        color: #92a5ac;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .uploader-wrapper {
        width: 100%;
        cursor: pointer;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7;
        color: #92a5ac;
        border-radius: 10px;
        padding: 50px;
        vertical-align: middle;
        text-align: center;
        :hover {
            opacity: 0.7;
        }
    }
    .upload-icon {
        margin-bottom: 20px;
        height: 100px;
    }
    .upload-text {
        text-align: center;
        padding-top: 20px !important;
        border-top: 1px solid #e1e3eb;
    }

    .year-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: end;
        margin-bottom: 20px;
    }
`;

export default DragAndDrop;
