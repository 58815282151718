import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import useInvoicesStore from '../../../store/invoices';
import useParamsStore from '../../../store/params';

const getAllInvoices = (formattedParams?: string): Promise<ObjectKeys> => {
    return new Promise((resolve, reject) => {
        const page = useParamsStore.getState().page;
        const search = formattedParams ? 'is_prepaid:0;' + formattedParams : 'is_prepaid:0;';
        const searchFields = formattedParams ? 'is_prepaid:=;' + formattedParams : 'is_prepaid:=;';

        communication
            .getInvoices({
                limit: '10',
                page: page ? page : 1,
                search,
                searchFields,
                searchJoin: 'and',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    useInvoicesStore.setState({ invoiceData: res.data });
                    resolve(res);
                } else {
                    reject(new Error(`Failed to fetch invoices. Status: ${res.status}`));
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};
export default getAllInvoices;
