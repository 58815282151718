import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useWindowSize from '../../hooks/useResize';
import styled from 'styled-components';
import SelectComponent from '../../components/select';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import Input, { InputComponentType } from '../../components/input';
import communication from '../../communication';
import useAgencyStore from '../../store/agency';
import useProfileStore from '../../store/profile';
import { FileUploader } from 'react-drag-drop-files';
import { dragAndDropIcon } from '../../assets/base64';
import comunication from '../../communication';
import { ObjectKeys } from '@/types/objectKeys';
// import Loader from '../../components/loader';
import Modal from '../../components/modal';
import ErrorModal from '../../components/errorModal';
import { useNavigate } from 'react-router-dom';
import useCharLengthValidation from '../../hooks/useCharLengthValidation';

interface Props {
    yearsOptions: Array<{ value: any; label: any }>;
    closeModal: Function;
    setIsLoader: Function;
    setOpenInfoModal: Function;
    refresh?: Function;
    callNumber: number;
    year: string;
    transferOrders?: ObjectKeys;
    currentMonth?: string;
}

export interface ChangeAmountTypes {
    year: string;
    months: Array<any>;
    user_id: string;
    model_number_recipient: number;
    call_number_recipient: string;
    total_amount: {
        doprinos_za_pio: number;
        porez_na_prihode_od_samostalne_delatnosti: number;
        doprinos_za_zdravstveno_osiguranje?: number;
        doprinos_za_osiguranje_nezaposlenih?: number;
    };
}

export interface HelpWithTaxTypes {
    year: string;
}

const ManageOrdersModal: FunctionComponent<Props> = ({
    yearsOptions,
    closeModal,
    setIsLoader,
    setOpenInfoModal,
    callNumber,
    refresh,
    year,
    transferOrders,
    currentMonth,
}) => {
    const { currentLang } = useLanguageStore();
    const navigate = useNavigate();
    const t = useTranslations(currentLang);
    const [tabIndex, setTabIndex] = useState(0);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [selectedYear, setSelectedYear] = useState<string>(year);
    const agency = useAgencyStore((state) => state.agency);
    const fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif'];
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    // const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { profile } = useProfileStore();
    const [choosenMonths, setChoosenMonths] = useState<Array<{ value: any; label: any }>>([]);

    const monthsOptions: Array<{ value: any; label: any }> = [
        { value: '00', label: t('pages.transfers.change_for_all').text },
        { value: '01', label: t('pages.transfers.payment_lists.january').text },
        { value: '02', label: t('pages.transfers.payment_lists.february').text },
        { value: '03', label: t('pages.transfers.payment_lists.march').text },
        { value: '04', label: t('pages.transfers.payment_lists.april').text },
        { value: '05', label: t('pages.transfers.payment_lists.may').text },
        { value: '06', label: t('pages.transfers.payment_lists.june').text },
        { value: '07', label: t('pages.transfers.payment_lists.july').text },
        { value: '08', label: t('pages.transfers.payment_lists.august').text },
        { value: '09', label: t('pages.transfers.payment_lists.september').text },
        { value: '10', label: t('pages.transfers.payment_lists.october').text },
        { value: '11', label: t('pages.transfers.payment_lists.november').text },
        { value: '12', label: t('pages.transfers.payment_lists.december').text },
    ];

    useEffect(() => {
        if (currentMonth) {
            const currentMonthNumber = currentMonth.length > 1 ? currentMonth : `0${currentMonth}`;
            setChoosenMonths([
                {
                    value: currentMonthNumber,
                    label: monthsOptions.find((month) => month.value === currentMonthNumber)?.label,
                },
            ]);
        }
    }, [currentMonth]);

    const [changeAmountsData, setChangeAmountsData] = useState<ChangeAmountTypes>({
        year: year,
        months: choosenMonths.map((month) => parseInt(month.value)),
        user_id: profile.id,
        model_number_recipient: 97,
        call_number_recipient: callNumber ? callNumber.toString() : '',
        total_amount: {
            doprinos_za_pio:
                transferOrders?.length > 0
                    ? transferOrders?.find((order: ObjectKeys) => order.payment_slip_heading === 'DOPRINOS ZA PIO')
                          ?.total_amount || 0
                    : 0,
            porez_na_prihode_od_samostalne_delatnosti:
                transferOrders?.length > 0
                    ? transferOrders?.find(
                          (order: ObjectKeys) =>
                              order.payment_slip_heading === 'POREZ NA PRIHODE OD SAMOSTALNE DELATNOSTI',
                      )?.total_amount || 0
                    : 0,
            doprinos_za_zdravstveno_osiguranje:
                transferOrders?.length > 0
                    ? transferOrders?.find(
                          (order: ObjectKeys) => order.payment_slip_heading === 'DOPRINOS ZA ZDRAVSTVENO OSIGURANJE',
                      )?.total_amount || 0
                    : 0,
            doprinos_za_osiguranje_nezaposlenih:
                transferOrders?.length > 0
                    ? transferOrders?.find(
                          (order: ObjectKeys) => order.payment_slip_heading === 'DOPRINOS ZA OSIGURANJE NEZAPOSLENIH',
                      )?.total_amount || 0
                    : 0,
        },
    });

    const callNoValidation = useCharLengthValidation(
        changeAmountsData.call_number_recipient,
        parseInt(selectedYear) > 2018 ? 19 : 1,
        true,
    );

    const pioValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_pio
            ? changeAmountsData?.total_amount.doprinos_za_pio.toString()
            : '',
        1,
        true,
    );
    const formHealthValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_zdravstveno_osiguranje
            ? changeAmountsData?.total_amount.doprinos_za_zdravstveno_osiguranje.toString()
            : '',
        1,
        true,
    );
    const formUnemploymentValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_osiguranje_nezaposlenih
            ? changeAmountsData?.total_amount.doprinos_za_osiguranje_nezaposlenih.toString()
            : '',
        1,
        true,
    );
    const formIncomeValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.porez_na_prihode_od_samostalne_delatnosti
            ? changeAmountsData?.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString()
            : '',
        1,
        true,
    );

    useEffect(() => {
        setChangeAmountsData((prevState) => ({
            ...prevState,
            months: choosenMonths.map((month) => parseInt(month.value)),
        }));
    }, [choosenMonths]);

    const [helpWithTaxData, setHelpWithTaxData] = useState<HelpWithTaxTypes>({
        year: year,
    });

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        setChangeAmountsData((prevState) => ({ ...prevState, year: selectedYear }));
        setHelpWithTaxData((prevState) => ({ ...prevState, year: selectedYear }));
        setChangeAmountsData((prevState) => ({ ...prevState, user_id: agency?.user?.data?.id }));
    }, [selectedYear, year]);

    const resetData = (): void => {
        setHelpWithTaxData({ year: '' });
        setChangeAmountsData({
            year: selectedYear,
            months: [],
            user_id: '',
            model_number_recipient: 97,
            call_number_recipient: changeAmountsData.call_number_recipient,
            total_amount: {
                doprinos_za_pio: 0,
                porez_na_prihode_od_samostalne_delatnosti: 0,
                doprinos_za_zdravstveno_osiguranje: 0,
                doprinos_za_osiguranje_nezaposlenih: 0,
            },
        });
    };

    const sendNewTaxOrder = async (): Promise<void> => {
        setIsLoader(true);
        if (agency.employment_type === 'flat_rate_agency') {
            if (
                changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih === 0 ||
                changeAmountsData.total_amount.doprinos_za_pio === 0 ||
                changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje === 0 ||
                changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti === 0
            ) {
                setError(true);
                setIsLoader(false);

                return;
            }
        } else {
            if (
                changeAmountsData.total_amount.doprinos_za_pio === 0 ||
                changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti === 0
            ) {
                setError(true);
                setIsLoader(false);

                return;
            }
        }

        if (callNoValidation !== '') {
            setIsLoader(false);
            setError(true);
        } else if (choosenMonths.length === 0) {
            setError(true);
            setIsLoader(false);
        } else {
            const updateData = { ...changeAmountsData };
            updateData.total_amount.doprinos_za_pio = Number(
                updateData.total_amount.doprinos_za_pio.toString().replace(',', '.'),
            );
            updateData.total_amount.porez_na_prihode_od_samostalne_delatnosti = Number(
                updateData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString().replace(',', '.'),
            );
            updateData.total_amount.doprinos_za_zdravstveno_osiguranje = Number(
                updateData.total_amount.doprinos_za_zdravstveno_osiguranje?.toString().replace(',', '.'),
            );
            updateData.total_amount.doprinos_za_osiguranje_nezaposlenih = Number(
                updateData.total_amount.doprinos_za_osiguranje_nezaposlenih?.toString().replace(',', '.'),
            );
            const res = await communication.createTaxOrder(updateData);
            if (res.status === 200) {
                const payload = {
                    month: choosenMonths.map((month) => parseInt(month.value)),
                    year: selectedYear,
                    status: 'pending',
                    paid_date: new Date().toISOString().split('T')[0],
                };
                communication.sendTaxCalendar(payload);
                closeModal();
                setIsLoader(false);
                if (refresh) {
                    refresh();
                }
                resetData();
            } else {
                setIsLoader(false);
            }
        }
    };

    const handleSaveTaxHelpWithTax = async (file: [Blob]): Promise<void> => {
        setIsLoader(true);
        if (file[0].size > 5242880) {
            setIsLoader(false);
            setWrongTypeModal(true);
        } else {
            const res = await comunication.uploadTaxSolutions(file[0], helpWithTaxData.year);
            comunication.sendTaxSolutionNotification();
            if (res.status === 200) {
                closeModal();
                setOpenInfoModal(true);
            }
            setIsLoader(false);
        }
    };
    const tabs = [t('pages.transfers.change_amounts').text, t('pages.transfers.help_upload_tax').text];

    const handleInputChange = (value: string): void => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setChangeAmountsData((prevState) => ({
            ...prevState,
            call_number_recipient: sanitizedValue,
        }));
    };

    const handleMonths = (value: Array<{ value: any; label: any }>): void => {
        const containsZero = value.some((month) => month.value === '00');
        if (containsZero) {
            setChoosenMonths([
                { value: '01', label: t('pages.transfers.payment_lists.january').text },
                { value: '02', label: t('pages.transfers.payment_lists.february').text },
                { value: '03', label: t('pages.transfers.payment_lists.march').text },
                { value: '04', label: t('pages.transfers.payment_lists.april').text },
                { value: '05', label: t('pages.transfers.payment_lists.may').text },
                { value: '06', label: t('pages.transfers.payment_lists.june').text },
                { value: '07', label: t('pages.transfers.payment_lists.july').text },
                { value: '08', label: t('pages.transfers.payment_lists.august').text },
                { value: '09', label: t('pages.transfers.payment_lists.september').text },
                { value: '10', label: t('pages.transfers.payment_lists.october').text },
                { value: '11', label: t('pages.transfers.payment_lists.november').text },
                { value: '12', label: t('pages.transfers.payment_lists.december').text },
            ]);
        } else {
            setChoosenMonths(value.filter((month) => month.value !== '00'));
        }
    };

    return (
        <>
            {/* {loader && <Loader />} */}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={t('pages.transfers.wrongType').text}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
                <h1>{t('pages.transfers.manage_transfers').text}</h1>
                <Tabs className={'tabs'} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        {tabs.map((tab, index) => (
                            <Tab className={'custom-tab'} key={index}>
                                {tab}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanel>
                        <div className={'change-amounts first-tab'}>
                            <h4>{t('pages.transfers.choose_year_for_generating').text}</h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label className="font_small">{t('pages.transfers.choose_months').text}</label>
                                        <SelectComponent
                                            optionList={monthsOptions}
                                            value={choosenMonths}
                                            className={'select-month'}
                                            handleSelectedValue={(months: Array<{ value: any; label: any }>) =>
                                                handleMonths(months)
                                            }
                                            isMultiple={true}
                                            placeholder={t('pages.transfers.choose').text}
                                        />
                                        {error && choosenMonths.length === 0 ? (
                                            <span className="error">
                                                {t('pages.transfers.choose_month_warning').text}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className={'select'}>
                                        <label className="font_small">{`${
                                            t('pages.transfers.edit_tax_transfers_modal_year').text
                                        } ${selectedYear}`}</label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) => {
                                                setSelectedYear(value);
                                                setChangeAmountsData((prevState) => ({
                                                    ...prevState,
                                                    call_number_recipient: '',
                                                }));
                                                navigate(`/transfer-orders/${selectedYear}`);
                                            }}
                                        />
                                    </div>
                                    <Input
                                        type={InputComponentType.Text}
                                        label={`${
                                            t('pages.transfers.edit_tax_transfers_modal__call_number_year').text
                                        } ${selectedYear}`}
                                        onChange={(value: string) => {
                                            handleInputChange(value);
                                        }}
                                        value={changeAmountsData.call_number_recipient}
                                        validation={callNoValidation && error ? callNoValidation : ''}
                                        maxLength={19}
                                        className={'input-tax-orders'}
                                    />
                                    <p className="label-text">{t('pages.transfers.form_pio').text}</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={
                                            !changeAmountsData.total_amount.doprinos_za_pio
                                                ? ''
                                                : changeAmountsData.total_amount.doprinos_za_pio
                                                      ?.toString()
                                                      .replace(/\./g, ',')
                                        }
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    doprinos_za_pio: value,
                                                },
                                            }));
                                        }}
                                        validation={error ? pioValidation : ''}
                                        className={'input'}
                                    />
                                    {agency.employment_type === 'flat_rate_agency' ? (
                                        <>
                                            <p className="label-text">{t('pages.transfers.form_health').text}</p>
                                            <Input
                                                type={InputComponentType.Amount}
                                                value={
                                                    !changeAmountsData?.total_amount?.doprinos_za_zdravstveno_osiguranje
                                                        ? ''
                                                        : changeAmountsData?.total_amount?.doprinos_za_zdravstveno_osiguranje
                                                              ?.toString()
                                                              .replace(/\./g, ',')
                                                }
                                                onChange={(value: string) => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    setChangeAmountsData((prevState) => ({
                                                        ...prevState,
                                                        total_amount: {
                                                            ...prevState.total_amount,
                                                            doprinos_za_zdravstveno_osiguranje: value,
                                                        },
                                                    }));
                                                }}
                                                validation={error ? formHealthValidation : ''}
                                                className={'input'}
                                            />
                                            <p className="label-text">{t('pages.transfers.form_unemployment').text}</p>
                                            <Input
                                                type={InputComponentType.Amount}
                                                value={
                                                    !changeAmountsData?.total_amount
                                                        ?.doprinos_za_osiguranje_nezaposlenih
                                                        ? ''
                                                        : changeAmountsData?.total_amount?.doprinos_za_osiguranje_nezaposlenih
                                                              ?.toString()
                                                              .replace(/\./g, ',')
                                                }
                                                onChange={(value: string) => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    setChangeAmountsData((prevState) => ({
                                                        ...prevState,
                                                        total_amount: {
                                                            ...prevState.total_amount,
                                                            doprinos_za_osiguranje_nezaposlenih: value,
                                                        },
                                                    }));
                                                }}
                                                validation={error ? formUnemploymentValidation : ''}
                                                className={'input'}
                                            />
                                        </>
                                    ) : null}
                                    <p className="label-text">{t('pages.transfers.form_income').text}</p>
                                    <Input
                                        type={InputComponentType.Amount}
                                        value={
                                            !changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti
                                                ? ''
                                                : changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti
                                                      .toString()
                                                      .replace(/\./g, ',')
                                        }
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    porez_na_prihode_od_samostalne_delatnosti: value,
                                                },
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error ? formIncomeValidation : ''}
                                    />
                                </article>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.close').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => sendNewTaxOrder()}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={'change-amounts'}>
                            <h4 className="change-amounts-subtitle">
                                {t('pages.transfers.choose_year_for_uploading').text}
                            </h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label>{`${
                                            t('pages.transfers.edit_tax_transfers_modal_year').text
                                        } ${selectedYear}`}</label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) =>
                                                setHelpWithTaxData((prevState) => ({ ...prevState, year: value }))
                                            }
                                        />
                                    </div>
                                </article>
                            </div>
                            <div>
                                <FileUploader
                                    multiple={true}
                                    handleChange={handleSaveTaxHelpWithTax}
                                    readFileAsBinary={true}
                                    name="files"
                                    types={fileTypes}
                                    onTypeError={() => {
                                        setWrongTypeModal(true);
                                    }}
                                >
                                    <div className={isMobileWidth ? 'mobile dropzone' : 'dropzone'}>
                                        <img src={dragAndDropIcon} />
                                    </div>
                                </FileUploader>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.back').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => handleSaveTaxHelpWithTax}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                </Tabs>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    display: inline-block;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        color: var(--purple);
        border-bottom: 3px solid #9b7fe9 !important;
    }
    h1 {
        color: #4c595f;
        font-weight: 400;
        font-size: 20px;
        padding: 25px 50px 10px;
    }
    h4 {
        font-size: 16px;
        color: var(--gray);
    }
    .change-amounts {
        padding: 15px 20px 75px;
    }
    article {
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        position: relative;
        text-align: left;
        .select {
            color: #92a5ac;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            width: 100%;
            display: block;
            .font_small {
                font-size: 16px;
            }
        }
        .select-wrapper {
            width: 100%;
        }
    }
    .upload-tax {
        box-shadow: none !important;
        label {
            width: 100%;
        }
    }
    .description {
        display: none;
    }
    .modal-wrapper:has(> .change-amounts) {
        max-height: 90% !important;
        overflow: auto !important;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button:first-child {
            border-radius: 0 0 0 6px;
        }
        button:last-child {
            border-radius: 0 0 6px 0;
        }
    }
    .tabs {
        ul {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .first-tab {
        padding: 15px 50px 10px;
        input {
            width: 100%;
        }
    }
    .dropzone {
        max-width: 100%;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7 !important;
        color: #92a5ac;
        border-radius: 10px;
        margin: 20px 60px 10px;
        padding: 50px;
        img {
            width: 50px;
            margin-bottom: 50px;
            pointer-events: none;
        }
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 3px;
        display: block;
    }
    .label-text {
        font-size: 16px;
        color: var(--gray);
        margin-top: 10px;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }
`;

export default ManageOrdersModal;
