import React, { FunctionComponent } from 'react';
import { ObjectKeys } from '@/types/objectKeys';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUser, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../../communication';
import { getAccessToken } from '../../../../functions/auth';

type SolutionType = {
    solution: ObjectKeys;
    showEmail: boolean;
};

const AdminSolutionCard: FunctionComponent<SolutionType> = ({ solution, showEmail }) => {
    const token = getAccessToken();

    const downloadSolution = async (): Promise<void> => {
        await communication.downloadSolution(solution.id, token);
    };
    return (
        <Card>
            <span className={'title'} onClick={() => downloadSolution()}>
                <FontAwesomeIcon icon={faFile} className={'icon'} />
                {solution.file_name}
            </span>
            {showEmail && (
                <span className={'email'}>
                    <FontAwesomeIcon icon={faUser} className={'icon'} />
                    {solution?.user?.data?.email}
                </span>
            )}

            <span className={'date'}>
                <FontAwesomeIcon icon={faCalendarAlt} className={'icon'} />
                {moment(solution.created_at).format('DD. MM. YYYY ')}
            </span>
        </Card>
    );
};

const Card = styled.div`
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    background-color: var(--white);
    padding: 0 15px;
    margin-bottom: 5px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    span {
        display: inline-block;
    }
    .title {
        -webkit-box-flex: 3;
        -ms-flex-positive: 3;
        flex-grow: 3;
        color: var(--purple);
        cursor: pointer;
    }
    .email {
        color: var(--gray);
        width: 350px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .date {
        color: var(--gray);
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
    .icon {
        margin-right: 10px;
    }
`;

export default AdminSolutionCard;
