import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faEdit, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import ServiceForm from './serviceForm';
import { formatTwoDecimals } from '../../../functions/format';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import ConfirmModal from '../../../components/confirmModal';
import communication from '../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Input, { InputComponentType } from '../../../components/input';
import SelectComponent from '../../../components/select';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Pagination from '../../../components/pagination';
import { usePasswordProtect } from '../../../hooks/passwordProtect';

interface Service {
    id: string;
    name_rs: string;
    name_en: string;
    name_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    price: number;
}

const Services: FunctionComponent = () => {
    usePasswordProtect(['/admin/services']);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [serviceForm] = useState({
        name_rs: '',
        name_en: '',
        name_ru: '',
        description_rs: '',
        description_en: '',
        description_ru: '',
        price: 0,
    });

    const [services, setServices] = useState<ObjectKeys>();
    const [selectedService, setSelectedService] = useState<ObjectKeys | null>(null);
    const [isEditing, setEditing] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const [searchValue, setSearchValue] = useState('');

    const [params, setParams] = useState({
        page: 1,
        limit: '10',
        search: '',
        searchFields: '',
        searchJoin: 'and',
        orderBy: 'name_rs',
        sortedBy: 'asc',
    });
    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };

    const createService = (service: Service): void => {
        const updatedService = {
            ...service,
            price: parseFloat(service.price.toString().replace(/,/g, '.')),
        };

        setLoader(true);
        communication
            .createAdminService(updatedService)
            .then(() => {
                setLoader(false);
                toast.success('Usluga uspesno kreirana');
                getAllServices();
            })
            .catch((error: any) => {
                console.error('Error:', error);
                if (error.response.status === 422) {
                    toast.error('Greška prilikom kreiranja usluge - usluga već postoji');
                }
                setLoader(false);
            });
    };

    const getAllServices = (): void => {
        setLoader(true);
        communication
            .getAllAdminServices(params)
            .then((response: ObjectKeys) => {
                setServices(response.data);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    useEffect(() => {
        getAllServices();
    }, [params]);

    useEffect(() => {
        setParams({ ...params, search: `name_rs:${searchValue};`, searchFields: 'name_rs:like;', page: 1 });
    }, [searchValue]);

    const handleUpdateService = (service: Service, id: string): void => {
        setLoader(true);
        const updatedService = {
            ...service,
            price: parseFloat(service.price.toString().replace(/,/g, '.')),
        };
        communication
            .updateAdminService(id, updatedService)
            .then(() => {
                getAllServices();
                setLoader(false);
                setEditing(false);
                setSelectedService(null);
                toast.success('Usluga uspešno izmenjena');
            })
            .catch((error: any) => {
                if (error.response.status === 422) {
                    toast.error('Greška prilikom kreiranja usluge - usluga već postoji');
                } else {
                    toast.error(error.response.data.message);
                }
                setLoader(false);
            });
    };

    const handleDeleteService = (service: ObjectKeys): void => {
        setDeleteModal(true);
        setSelectedService(service);
    };

    const onDelete = (): void => {
        if (selectedService) {
            communication
                .deleteAdminService(selectedService.id)
                .then(() => {
                    getAllServices();
                    toast.success('Usluga uspesno obrisana');
                })
                .catch((error: any) => {
                    console.error('Error:', error);
                });
        }
    };

    const handleEditClick = (service: ObjectKeys): void => {
        setSelectedService(service);
        setEditing(true);
    };

    const handleCancelEdit = (): void => {
        setEditing(false);
        setSelectedService(null);
    };

    useEffect(() => {
        getAllServices();
    }, []);

    return (
        <>
            <ToastContainer />
            {loader && <Loader />}
            {deleteModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setDeleteModal(false);
                            onDelete();
                            // Update services
                        }}
                        close={() => {
                            setDeleteModal(false);
                        }}
                        active={deleteModal}
                        message={'Da li ste sigurni da zelite da obrisete uslugu?'}
                    />
                </Modal>
            )}
            <Header>
                <h1>Usluge</h1>
            </Header>
            <Create>
                <p className="create-title" onClick={() => setDropdownOpen(!isDropdownOpen)}>
                    <span>{isDropdownOpen ? 'Zatvori' : 'Dodaj uslugu'}</span>
                    <FontAwesomeIcon icon={isDropdownOpen ? faCaretUp : faCaretDown} className="fa-icon" />
                </p>
                <ServiceForm
                    initialData={serviceForm}
                    onSave={(data) => {
                        createService(data);
                        setDropdownOpen(false);
                    }}
                    onCancel={() => {
                        setDropdownOpen(false);
                    }}
                    isOpen={isDropdownOpen}
                    type={'create'}
                />
                <div className="search-container">
                    <div>
                        <ShowSelect>
                            <p className="show">{t('pages.invoices.filters.show.label').text}</p>
                            <SelectComponent
                                optionList={showItems}
                                handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                                defaultSelectedOption={showItems[0]}
                            />
                            <p>Usluga</p>
                        </ShowSelect>
                    </div>
                    <div className="search-input">
                        <FontAwesomeIcon icon={faSearch} />
                        <Input
                            type={InputComponentType.Text}
                            onChange={(value: string) => {
                                setSearchValue(value);
                            }}
                            value={searchValue}
                            maxLength={50}
                            placeholder="Pretraga"
                            className={'input'}
                        />
                    </div>
                </div>
            </Create>

            {isEditing && selectedService && (
                <ServiceForm
                    initialData={selectedService}
                    onSave={(data, id) => {
                        if (id) {
                            handleUpdateService(data, id);
                        }
                        handleCancelEdit();
                    }}
                    onCancel={handleCancelEdit}
                    isOpen={isEditing}
                    type={'edit'}
                    id={selectedService.id}
                />
            )}
            <ListContainer>
                <ServiceItemHeader>
                    <div className="items">
                        <p>Usluga</p>
                    </div>
                    <div className="items-price">
                        <p>Cena (RSD)</p>
                    </div>
                    <div className="items-action">
                        <p>Akcije</p>
                    </div>
                </ServiceItemHeader>
                {services && services.data && services?.data.length > 0 && (
                    <>
                        {services?.data.map((service: ObjectKeys) => (
                            <ServiceItem key={service.id}>
                                <div>
                                    <p className="wrap">{service.name_rs}</p>
                                </div>
                                <div className="items-price">
                                    <p>{formatTwoDecimals(service.price.toString())}</p>
                                </div>

                                <div className="toolkit">
                                    <Icon>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{ color: colors.blue }}
                                            onClick={() => handleEditClick(service)}
                                        />
                                    </Icon>
                                    <Icon>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ color: colors.danger }}
                                            onClick={() => handleDeleteService(service)}
                                        />
                                    </Icon>
                                </div>
                            </ServiceItem>
                        ))}
                    </>
                )}
                {services?.data?.length > 0 && services?.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={services?.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            services?.meta?.pagination?.current_page === services?.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${services?.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={services?.meta?.pagination?.current_page - 1}
                    />
                )}
            </ListContainer>
        </>
    );
};

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const Icon = styled.div`
    cursor: pointer;
`;
const ListContainer = styled.div`
    padding-bottom: 20px;
    .toolkit {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        p {
            margin-left: 10px;
            cursor: pointer;
            &:hover {
                color: var(--purple);
            }
        }
    }
    .items {
        display: flex;
        justify-content: space-between;
    }
    .items-price {
        display: flex;
        justify-content: space-between;
        margin-left: 200px;
    }
    .items-action {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
`;

const ServiceItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px 20px;

    > div {
        flex: 1; /* Each child takes up equal width */
        margin-right: 10px; /* Adjust margin as needed */
    }

    > div:last-child {
        margin-right: 0; /* Remove margin for the last child */
    }
    .items {
        overflow-wrap: break-word;
    }
    .wrap {
        word-break: break-all;
    }
`;
const ServiceItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-color);
    background-color: var(--purple);
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    padding: 20px;

    > div {
        flex: 1; /* Each child takes up equal width */
        margin-right: 10px; /* Adjust margin as needed */
    }

    > div:last-child {
        margin-right: 0; /* Remove margin for the last child */
    }
`;

const Create = styled.div`
    margin-bottom: 20px;
    .create-title {
        font-size: 14px;
        font-weight: bold;
        color: var(--purple);
        cursor: pointer;
    }
    .fa-icon {
        margin-left: 5px;
        color: var(--purple);
    }
    .search-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .search-input {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
    .show {
        margin-right: 6px;
    }
`;
const ShowSelect = styled.div`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: var(--gray);
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: var(--gray);
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
`;

export default Services;
