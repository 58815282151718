import { FunctionComponent, useEffect, useState } from 'react';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { ObjectKeys } from '@/types/objectKeys';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Input, { InputComponentType } from '../../../components/input';
import Checkbox from '../../../components/checkbox';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import useAllUsersStore from '../../../store/admin/allUsers';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import CartCard from './cartCard';
import useServicesStore from '../../../store/services';
import ServiceCard from './serviceCard';
import ExcelJS from 'exceljs';
import { formatTwoDecimals } from '../../../functions/format';
// import { formatParams } from '../../../functions/formatParams';
// import colors from '../../../global/colors';
// import Button, { ButtonVariant } from '../../../components/button';
type SelectOption = {
    value: string;
    label: string;
};

const Requests: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const { allUsers } = useAllUsersStore();
    const [loader, setLoader] = useState<boolean>(false);
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [pibChecked, setPibChecked] = useState(true);
    const [servicesChecked, setServicesChecked] = useState(false);
    const [renderKey, setRenderKey] = useState(0);

    const [serviceData, setServiceData] = useState<ObjectKeys>();

    const [chosenService, setChosenService] = useState<SelectOption>({
        value: '',
        label: '',
    });

    const [clickUpread, setClickUpRead] = useState(false);

    const [filters, setFilters] = useState<ObjectKeys>({
        start_date: '',
        end_date: '',
    });

    const [params, setParams] = useState({
        page: 1,
        limit: '10',
        search: `type:service;${selectedUserId ? `user_id:${selectedUserId}` : ''}`,
        searchFields: `find:like%;type:=;${selectedUserId ? 'user_id:=;' : ''}`,
        searchJoin: 'and',
        include: 'user',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });

    const [servicesParams, setServicesParams] = useState({
        page: 1,
        limit: '10',
        // id: `${chosenService.value}`,
        search: `type:service;package_service_id:${chosenService.value} : ''}`,
        searchFields: 'type:=;package_service_id:=',
        searchJoin: 'and',
        // orderBy: 'created_at',
        // sortedBy: 'desc',
    });
    // FILTERS FOR SERVICES
    // 'cart_id'
    // 'payment_package_id'
    // 'package_service_id'
    // 'price'
    // 'type'

    const [cartsData, setCartsData] = useState<ObjectKeys>();

    const formatStartDate = (date: Date): string => {
        if (!date) return '';
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        const year = newDate.getFullYear();
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');
        // const hours = newDate.getHours().toString().padStart(2, '0');
        // const minutes = newDate.getMinutes().toString().padStart(2, '0');
        // const seconds = newDate.getSeconds().toString().padStart(2, '0');

        // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return `${year}-${month}-${day}`;
    };

    const formatEndDate = (date: Date): string => {
        if (!date) return '';
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        const year = newDate.getFullYear();
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');
        // const hours = newDate.getHours().toString().padStart(2, '0');
        // const minutes = newDate.getMinutes().toString().padStart(2, '0');
        // const seconds = newDate.getSeconds().toString().padStart(2, '0');

        // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        useUserStore.setState({ userBOid: '' });
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        if (allUsersOptions.length > 0) {
            setSelectedUserId(allUsersOptions[0].value);
        }
    }, [allUsersOptions]);

    const [servicesOptions, setServicesOptions] = useState<Array<{ value: string; label: string }>>([]);
    const getAllServices = (): void => {
        setLoader(true);
        communication
            .getAllAdminServices({ page: 1, limit: 1000, sort: 'name', order: 'asc' })
            .then((response: ObjectKeys) => {
                useServicesStore.setState({ servicesData: response.data.data });
                const formattedOptions = response.data.data.map((service: any) => ({
                    value: service.id,
                    label: service.name_rs,
                }));
                const additionalOption = {
                    value: '',
                    label: 'Sve usluge',
                };
                const optionsWithAdditional = [additionalOption, ...formattedOptions];
                setServicesOptions(optionsWithAdditional);
                setChosenService(optionsWithAdditional[0]);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getItems = (): void => {
        setLoader(true);
        communication
            .getItems(servicesParams)
            .then((response: ObjectKeys) => {
                if (response && response.data) {
                    setServiceData(response.data);
                } else {
                    setServiceData({});
                }
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getCarts = (): void => {
        setLoader(true);
        communication
            .getAdminCarts(params)
            .then((response: ObjectKeys) => {
                if (response && response.data) {
                    setCartsData(response.data);
                } else {
                    setCartsData({});
                }
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const handlePageClick = (e: any): void => {
        const selectedPage = e.selected + 1;
        setParams({ ...params, page: selectedPage });
    };

    const handleServicePageClick = (e: any): void => {
        const selectedPage = e.selected + 1;
        setServicesParams({ ...servicesParams, page: selectedPage });
    };

    useEffect(() => {
        setParams({
            ...params,
            search: `type:service${selectedUserId ? `;user_id:${selectedUserId}` : ''}`,
        });
        setFilters({ ...filters, start_date: '', end_date: '' });
    }, [selectedUserId]);

    useEffect(() => {
        if (filters.start_date !== '' && filters.end_date !== '' && pibChecked) {
            setParams({
                ...params,
                search: `type:service;${selectedUserId ? `user_id:${selectedUserId};` : ''}created_at:${formatStartDate(
                    filters.start_date,
                )},${formatEndDate(filters.end_date)};`,
                searchFields: `find:like%;type:=;${selectedUserId ? 'user_id:=;' : ''}created_at:between;`,
            });
        } else if (filters.start_date !== '' && filters.end_date !== '' && servicesChecked) {
            setServicesParams({
                ...params,
                search: `type:service;${
                    chosenService.value !== '' ? `package_service_id:${chosenService.value};` : ''
                }created_at:${formatStartDate(filters.start_date)},${formatEndDate(filters.end_date)};`,
                searchFields: `find:like%;type:=;${
                    chosenService.value !== '' ? 'package_service_id:=;' : ''
                }created_at:between;`,
            });
        }
    }, [filters]);

    useEffect(() => {
        if (pibChecked) {
            getCarts();
        }
    }, [params]);

    useEffect(() => {
        if (servicesChecked) {
            getItems();
        }
    }, [servicesParams]);

    useEffect(() => {
        getAllServices();
        getCarts();
        // getItems();
    }, []);

    useEffect(() => {
        if (pibChecked && filters.start_date !== '' && filters.end_date !== '') {
            setParams({
                ...params,
                search: `type:service;${selectedUserId ? `user_id:${selectedUserId};` : ''}created_at:${formatStartDate(
                    filters.start_date,
                )},${formatEndDate(filters.end_date)};`,
                searchFields: `find:like%;type:=;${selectedUserId ? 'user_id:=;' : ''}created_at:between;`,
            });
        } else if (servicesChecked && filters.start_date !== '' && filters.end_date !== '') {
            setServicesParams({
                ...params,
                search: `type:service;${
                    chosenService.value !== '' ? `package_service_id:${chosenService.value}` : ''
                }created_at:${formatStartDate(filters.start_date)},${formatEndDate(filters.end_date)};`,
                searchFields: `find:like%;type:=;${chosenService.value !== '' ? 'package_service_id:=;' : ''}`,
            });
        } else if (pibChecked && filters.start_date === '' && filters.end_date === '') {
            setParams({
                ...params,
                search: `type:service;${selectedUserId ? `user_id:${selectedUserId}` : ''}`,
                searchFields: `find:like%;type:=;${selectedUserId ? 'user_id:=;' : ''}`,
            });
        } else if (servicesChecked && filters.start_date === '' && filters.end_date === '') {
            setServicesParams({
                ...params,
                search: `type:service;${chosenService.value !== '' ? `package_service_id:${chosenService.value}` : ''}`,
                searchFields: `find:like%;type:=;${chosenService.value !== '' ? 'package_service_id:=;' : ''}`,
            });
        }
    }, [pibChecked, servicesChecked]);

    useEffect(() => {
        if (servicesChecked) {
            setServicesParams({
                ...params,
                search: `type:service;${chosenService.value ? `package_service_id:${chosenService.value}` : ''}`,
                searchFields: `find:like%;type:=;${chosenService.value ? 'package_service_id:=;' : ''}`,
            });
        }
    }, [chosenService]);

    const returnUserData = (id: string): string => {
        const user = allUsers.find((useR: any) => useR.id === id);
        return user ? `${user.email}` : 'N/A';
    };
    const returnUserPackage = (id: string): string => {
        const user = allUsers.find((useR: any) => useR.id === id);
        return user ? `${user.package_name}` : 'N/A';
    };
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
        const year = date.getFullYear();

        return `${day}.${month}.${year}.`;
    };

    const downloadXLS = (): void => {
        setLoader(true);
        if (pibChecked) {
            communication
                .getCartsAdmin(params)
                .then(async (response: any) => {
                    const responseData = response.data.data;
                    const workbook = new ExcelJS.Workbook();
                    const sheet = workbook.addWorksheet('Responses');

                    // Define header row and apply styles
                    const header = [
                        'Email korisnika',
                        'Paket korisnika',
                        'Usluga',
                        'Kolicina',
                        'Cena (RSD)',
                        'Napomena',
                        'Cena sa popustom',
                        'Datum uplate',
                        'Status',
                    ];
                    const headerRow = sheet.addRow(header);

                    // Style for the header row
                    headerRow.eachCell((cell) => {
                        cell.font = {
                            bold: true,
                            color: { argb: 'FFFFFFFF' },
                            size: 12,
                        };
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF9B7FE9' }, // Example: Blue background
                            bgColor: { argb: 'FF9B7FE9' },
                        };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    });
                    headerRow.height = 30;
                    // Set columns to a standard width and enable text wrapping
                    sheet.columns.forEach((column) => {
                        column.width = 25; // Set a standard width. Adjust as necessary.
                    });

                    // Continue with your code for adding data rows
                    responseData.forEach((cart: any) => {
                        cart.items.data.forEach((item: any) => {
                            const row = sheet.addRow([
                                returnUserData(cart.user_id),
                                returnUserPackage(cart.user_id),
                                item.service ? item.service.data.name_rs : 'N/A',
                                item.quantity,
                                formatTwoDecimals(item.price.toString()),
                                cart.note || '',
                                formatTwoDecimals(
                                    (item.price - (item.price * cart.packet_service_discount) / 100).toString(),
                                ),
                                formatDate(cart.created_at),
                                cart.status,
                            ]);

                            row.eachCell((cell) => {
                                cell.font = {
                                    size: 12,
                                };
                                // Removed the cell.fill attribute to keep the background transparent
                                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                            });
                        });
                    });

                    // Generate and download the file as before
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Zahtevi.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setLoader(false);
                })
                .catch((error: any) => {
                    console.error('Error:', error);
                    setLoader(false);
                });
        } else {
            communication
                .getServicesAdmin(servicesParams)
                .then(async (response: any) => {
                    const responseData = response.data.data;
                    const workbook = new ExcelJS.Workbook();
                    const sheet = workbook.addWorksheet('Responses');

                    // Define header row and apply styles
                    const header = [
                        'Usluga',
                        'Email korisnika',
                        'Paket korisnika',
                        'Cena (RSD)',
                        'Datum uplate',
                        'Količina',
                        'Pročitano',
                    ];
                    const headerRow = sheet.addRow(header);

                    // Style for the header row
                    headerRow.eachCell((cell) => {
                        cell.font = {
                            bold: true,
                            color: { argb: 'FFFFFFFF' },
                            size: 12,
                        };
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF9B7FE9' }, // Example: Blue background
                            bgColor: { argb: 'FF9B7FE9' },
                        };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    });

                    // Set columns to a standard width and enable text wrapping
                    sheet.columns.forEach((column) => {
                        column.width = 25; // Set a standard width. Adjust as necessary.
                    });

                    // Continue with your code for adding data rows
                    responseData.forEach((item: any) => {
                        const row = sheet.addRow([
                            item.service && item.service.data ? item.service.data.name_rs : 'N/A',
                            returnUserData(item.user_id),
                            returnUserPackage(item.user_id),
                            item?.service?.data?.price
                                ? formatTwoDecimals(item?.service?.data?.price?.toString())
                                : 'N/A',
                            formatDate(item?.service?.data?.created_at),
                            item.quantity,
                            item.read === 1 ? 'Da' : 'Ne',
                        ]);

                        row.eachCell((cell) => {
                            cell.font = {
                                size: 12,
                            };
                            // Removed the cell.fill attribute to keep the background transparent
                            cell.alignment = { vertical: 'middle', horizontal: 'center' };
                        });
                    });

                    // Generate and download the file as before
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Usluge.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setLoader(false);
                })
                .catch((error: any) => {
                    console.error('Error:', error);
                    setLoader(false);
                });
        }
    };

    const downloadPDF = (): void => {
        // setLoader(true);
        if (pibChecked) {
            communication.downloadPdfCarts(params).then((res: ObjectKeys) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Zahtevi.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setLoader(false);
            });
        } else {
            communication.downloadPdfServices(servicesParams).then((res: ObjectKeys) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Usluge.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setLoader(false);
            });
        }
    };

    return (
        <>
            {loader && <Loader />}
            <Header>
                <h1>Zahtevi</h1>
            </Header>
            <Filters>
                <div className="filter-top">
                    <div className="filter-date">
                        <p>ZA PERIOD</p>
                        <p>OD:</p>
                        <Input
                            type={InputComponentType.Date}
                            date={filters.start_date}
                            onChange={(value: Date) => {
                                setFilters({ ...filters, start_date: value });
                            }}
                            inputClassName={'requests-datepicker'}
                        />
                        <p>DO:</p>
                        <Input
                            type={InputComponentType.Date}
                            date={filters.end_date}
                            onChange={(value: Date) => {
                                setFilters({ ...filters, end_date: value });
                            }}
                            inputClassName={'requests-datepicker'}
                            // maxDate={new Date()}
                        />
                    </div>
                    <div className="icon" onClick={() => downloadPDF()}>
                        <FontAwesomeIcon icon={faDownload} />
                        <p>Preuzmi PDF</p>
                    </div>
                </div>
                <div className="filter-bottom">
                    <div className="checkbox-container">
                        <p>PRETRAGA PO:</p>
                        <div key={renderKey}>
                            <CheckBox>
                                <Checkbox
                                    defaultChecked={pibChecked}
                                    onChange={(isChecked: boolean) => {
                                        if (isChecked) setServicesChecked(false);
                                        else setServicesChecked(true);
                                        setPibChecked(isChecked);
                                        setRenderKey(renderKey + 1);
                                    }}
                                />
                                <p>PIB-u ili e-mailu</p>
                            </CheckBox>
                            <CheckBox>
                                <Checkbox
                                    defaultChecked={servicesChecked}
                                    onChange={(isChecked: boolean) => {
                                        if (isChecked) setPibChecked(false);
                                        else setPibChecked(true);
                                        setServicesChecked(isChecked);
                                        setRenderKey(renderKey + 1);
                                    }}
                                />
                                <p>Uslugama</p>
                            </CheckBox>
                        </div>
                    </div>
                    <div>
                        <div className="icon" onClick={() => downloadXLS()}>
                            <FontAwesomeIcon icon={faDownload} />
                            <p>Preuzmi XLSX</p>
                        </div>
                    </div>
                </div>
                <div className="filter-search">
                    {pibChecked ? (
                        <SelectWrapper>
                            <p>Izaberi korisnika:</p>
                            <div>
                                <SearchCustomComponent
                                    value={allUsersOptions.find((v) => v.value === userBOid)}
                                    allUsersOptions={allUsersOptions}
                                    handleSelectedValue={(data: { value: string; label: string }) => {
                                        useUserStore.setState({ userBOid: data.value });
                                        setSelectedUserId(data.value);
                                    }}
                                    placeholder={t('pages.admin.users.chooseUser').text}
                                    className="user-selector"
                                />
                            </div>
                        </SelectWrapper>
                    ) : (
                        <SelectWrapper>
                            <p>Izaberi uslugu:</p>
                            {/* test */}
                            <div>
                                <SearchCustomComponent
                                    value={chosenService}
                                    allUsersOptions={servicesOptions}
                                    handleSelectedValue={(data: { value: string; label: string }) => {
                                        setChosenService(data);
                                    }}
                                    placeholder={'Izaberi uslugu'}
                                    className="user-selector"
                                />
                            </div>
                        </SelectWrapper>
                    )}
                </div>
            </Filters>
            {pibChecked ? (
                <>
                    <PageContainer>
                        <CheckBoxClick>
                            <p>Clickup nepročitano</p>
                            <Checkbox
                                defaultChecked={clickUpread}
                                onChange={(isChecked: boolean) => {
                                    if (isChecked) {
                                        setClickUpRead(false);
                                        setParams({
                                            ...params,
                                            search: `type:service;read:0${
                                                selectedUserId ? `;user_id:${selectedUserId}` : ''
                                            }`,
                                            searchFields: `read:like;find:like%;type:=${
                                                selectedUserId ? `;user_id:${selectedUserId}` : ''
                                            }`,
                                        });
                                    } else {
                                        setClickUpRead(true);
                                        setParams({
                                            ...params,
                                            search: `type:service;${selectedUserId ? `user_id:${selectedUserId}` : ''}`,
                                            searchFields: `find:like%;type:=${
                                                selectedUserId ? `user_id:${selectedUserId}` : ''
                                            }`,
                                        });
                                    }
                                }}
                            />
                        </CheckBoxClick>
                        {cartsData && cartsData.data.length > 0 ? (
                            <CartTable>
                                <thead>
                                    <tr>
                                        <TableHeader>Korisnik - paket</TableHeader>
                                        <TableHeader>Ukupna cena (RSD)</TableHeader>
                                        <TableHeader>Datum uplate</TableHeader>
                                        <TableHeader>Status</TableHeader>
                                        <TableHeader>Clickup (pročitano)</TableHeader>
                                        <TableHeader>Fiskalni račun</TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartsData.data.map((cart: any) => {
                                        return <CartCard key={cart.id} cart={cart} fetchCarts={() => getCarts()} />;
                                    })}
                                </tbody>
                            </CartTable>
                        ) : (
                            <NoDataMessage>No carts available</NoDataMessage>
                        )}
                    </PageContainer>
                    {cartsData && cartsData?.data?.length > 0 && cartsData.meta?.pagination?.total_pages > 1 && (
                        <Pagination
                            pageCount={cartsData.meta?.pagination?.total_pages}
                            onPageChange={(e: object) => handlePageClick(e)}
                            nextLabel={`${
                                cartsData.meta?.pagination?.current_page === cartsData.meta?.pagination?.total_pages
                                    ? ''
                                    : '>'
                            }`}
                            previousLabel={`${cartsData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                            breakLabel="..."
                            initialPage={cartsData.meta?.pagination?.current_page - 1}
                        />
                    )}
                </>
            ) : (
                <>
                    <PageContainer>
                        <CheckBoxClick>
                            <p>Clickup nepročitano</p>
                            <Checkbox
                                defaultChecked={clickUpread}
                                onChange={(isChecked: boolean) => {
                                    if (isChecked) {
                                        setClickUpRead(false);
                                        setServicesParams({
                                            ...params,
                                            search: `type:service;read:0${
                                                chosenService.value ? `package_service_id:${chosenService.value}` : ''
                                            }`,
                                            searchFields: `read:like;find:like%;type:=${
                                                chosenService.value ? 'package_service_id:=;' : ''
                                            }`,
                                        });
                                    } else {
                                        setClickUpRead(true);
                                        setServicesParams({
                                            ...params,
                                            search: `type:service;${
                                                chosenService.value ? `package_service_id:${chosenService.value}` : ''
                                            }`,
                                            searchFields: `find:like%;type:=${
                                                chosenService.value ? 'package_service_id:=;' : ''
                                            }`,
                                        });
                                    }
                                }}
                            />
                        </CheckBoxClick>
                        {serviceData && serviceData.data.length > 0 ? (
                            <CartTable>
                                <thead>
                                    <tr>
                                        <TableHeader>Usluga</TableHeader>
                                        <TableHeader>Korisnik</TableHeader>
                                        <TableHeader>Cena (RSD)</TableHeader>
                                        <TableHeader>Količina</TableHeader>
                                        <TableHeader>Datum uplate</TableHeader>
                                        <TableHeader>Clickup (pročitano)</TableHeader>
                                        <TableHeader>Fiskalni račun</TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceData.data.map((cart: any) => {
                                        return <ServiceCard key={cart.id} cart={cart} fetchCarts={() => getItems()} />;
                                    })}
                                </tbody>
                            </CartTable>
                        ) : (
                            <NoDataMessage>No carts available</NoDataMessage>
                        )}
                    </PageContainer>
                    {serviceData && serviceData?.data?.length > 0 && serviceData.meta?.pagination?.total_pages > 1 && (
                        <Pagination
                            pageCount={serviceData.meta?.pagination?.total_pages}
                            onPageChange={(e: object) => handleServicePageClick(e)}
                            nextLabel={`${
                                serviceData.meta?.pagination?.current_page === serviceData.meta?.pagination?.total_pages
                                    ? ''
                                    : '>'
                            }`}
                            previousLabel={`${serviceData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                            breakLabel="..."
                            initialPage={serviceData.meta?.pagination?.current_page - 1}
                        />
                    )}
                </>
            )}
        </>
    );
};

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const CheckBox = styled.div`
    display: flex;
`;
const CheckBoxClick = styled.div`
    display: flex;
    justify-content: flex-end;
    p {
        margin-right: 10px;
    }
`;
const PageContainer = styled.div`
    padding: 20px;
`;

const CartTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    padding: 10px;
    border: 1px solid #ddd;
    background-color: var(--purple);
    color: white;
`;

const NoDataMessage = styled.p`
    text-align: center;
    color: #999;
`;
const Filters = styled.div`
    padding: 10px;
    background-color: var(--purple);
    color: var(--white);
    .datepicker {
        background-color: var(--white);
    }
    p {
        font-size: 16px;
    }
    .icon {
        display: flex;
        align-items: center;
        p {
            margin-left: 5px;
        }
        cursor: pointer;
    }
    .filter-top {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        .filter-date {
            display: flex;
            align-items: center;
            gap: 20px;
            p {
                margin: 0;
                margin-bottom: 5px;
            }
        }
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        margin-top: 10px;
        .checkbox-container {
            display: flex;
            align-items: center;
            gap: 20px;
            color: var(--white) !important;
        }
    }
    .filter-search {
        display: flex;
        margin: 10px;
    }
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--white);
        width: 150px;
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;

export default Requests;
