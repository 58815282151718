import communication from '../communication';
import useUserStore from '../store/user';
import useYearsStore from '../store/years';
import useCountriesStore from '../store/countries';
import useCurrencyStore from '../store/currency';
import useAgencyStore from '../store/agency';
import useUserProfileStore from '../store/profile';
import useProfileStore from '../store/admin/profile';
import useAllUsersStore from '../store/admin/allUsers';
import useServicesStore from '../store/services';
import { getAccessToken } from '../functions/auth';
import { ObjectKeys } from '../types/objectKeys';
import useLanguageStore from '../store/language';
import srJson from '../components/language/rs.json';
import enJson from '../components/language/en.json';
import ruJson from '../components/language/ru.json';
import { flatten } from '../functions/flatten';
import CryptoJS from 'crypto-js';

const initFetch = async (): Promise<void> => {
    const role = localStorage.getItem('role');
    let userInfo: ObjectKeys;

    //Get activity codes
    await communication
        .getActivityCodes()
        .then((res: ObjectKeys) => {
            useUserStore.setState({ activityCodes: res?.data?.data });
        })
        .catch((error: ObjectKeys) => {
            if (error) {
                useUserStore.setState({ activityCodes: [] });
            }
        });

    //Get municipalities
    await communication
        .getMunicipalities()
        .then((res: ObjectKeys) => {
            useUserStore.setState({ municipalities: res?.data?.data });
        })
        .catch((error: ObjectKeys) => {
            if (error) {
                useUserStore.setState({ municipalities: [] });
            }
        });

    //Get countries
    await communication
        .getCountries()
        .then((res: ObjectKeys) => {
            useCountriesStore.setState({ countries: res?.data?.data });
        })
        .catch((error: ObjectKeys) => {
            if (error) {
                useCountriesStore.setState({ countries: [] });
            }
        });

    if (getAccessToken()) {
        //Get years
        await communication
            .getYears()
            .then((res: ObjectKeys) => {
                useYearsStore.setState({ years: res?.data?.years });
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    useYearsStore.setState({ years: [] });
                }
            });

        //Get currency
        await communication
            .fetchCurrencies()
            .then((res: ObjectKeys) => {
                useCurrencyStore.setState({ currency: res?.data });
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    useCurrencyStore.setState({ currency: [] });
                }
            });

        await communication.getAllAdminServices({ page: 1, limit: 1000 }).then((res: ObjectKeys) => {
            useServicesStore.setState(() => ({
                servicesData: res.data.data,
            }));
        });
        // const currencyData = await communication.fetchCurrencies();
        // useCurrencyStore.setState({ currency: currencyData?.data });
        if (role !== 'admin') {
            const userProfileInfo = await communication.getUserProfile();
            userInfo = userProfileInfo;
            useUserStore.setState({ userInfo: userProfileInfo?.data?.data });
            useUserProfileStore.setState({
                profile: userProfileInfo?.data?.data,
            });
            useAgencyStore.setState({
                agency: userProfileInfo?.data?.data?.agency?.data[0],
            });
            const name = userProfileInfo?.data?.data?.agency?.data[0].user.data.lang;
            if (name === 'en') {
                const flattenData = flatten(enJson);
                useLanguageStore.setState({ currentLang: flattenData });
                localStorage.setItem('language', 'English');
            } else if (name === 'ru') {
                const flattenData = flatten(ruJson);
                useLanguageStore.setState({ currentLang: flattenData });
                localStorage.setItem('language', 'Русский');
            } else if (name === 'rs') {
                const flattenData = flatten(srJson);
                useLanguageStore.setState({ currentLang: flattenData });
                localStorage.setItem('language', 'Srpski');
            }
        }
        if (role === 'admin') {
            //Get profile info
            const profileInfo = await communication.getProfile();
            useProfileStore.setState({ profile: profileInfo?.data?.data });

            //Get all users info
            const allUsersInfo = await communication.getAllUsers();
            useAllUsersStore.setState({ allUsers: allUsersInfo?.data });
        }

        communication.getTawkTo().then(async (hash: ObjectKeys) => {
            const oldUser = userInfo?.data.data.old_user;
            const tawkToLink = oldUser
                ? 'https://embed.tawk.to/66713e1c981b6c56477e5f16/1i0l5l8h7'
                : 'https://embed.tawk.to/6671476d981b6c56477e6247/1i0l7u11p';
            let Tawk_API = (window as any).Tawk_API || {}; // eslint-disable-line
            let Tawk_LoadStart = new Date(); // eslint-disable-line
            Tawk_API.setAttributes = {
                name: `${userInfo?.data.data.first_name} ${userInfo?.data.data.last_name}`,
                email: userInfo?.data.data.email,
                hash: hash.data,
                userId: userInfo?.data.data.id,
            };
            (function () {
                const s1 = document.createElement('script'),
                    s0 = document.getElementsByTagName('script')[0];
                s1.async = false;
                s1.src = tawkToLink;
                s1.charset = 'UTF-8';
                s1.setAttribute('crossorigin', '*');
                s0.parentNode?.insertBefore(s1, s0);
            })();
        });
    }
};

export default initFetch;
