import { FunctionComponent, useEffect, useState } from 'react';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SelectSearch from '../../selectSearch';
import useAgencyStore from '../../../store/agency';
import Button, { ButtonVariant } from '../../button';
import colors from '../../../global/colors';
import { ObjectKeys } from '../../../types/objectKeys';
import communication from '../../../communication';
import { useNavigate } from 'react-router-dom';

interface Props {
    active?: boolean;
    className?: string;
    children?: string | any;
    close: Function;
}

const AccountExpiration: FunctionComponent<Props> = ({ close }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const { agency } = useAgencyStore();

    const navigate = useNavigate();

    const [amount, setAmount] = useState('0,00');
    const [paymentPurpose, setPaymentPurpose] = useState('Mesečna pretplata Pausal aplikacije');
    const [qrCode, setQrCode] = useState('');

    const subscriptions = [
        {
            value: '830,00',
            label: t('pages.accountExpiration.subscriptionModel.monthly').text,
        },
        {
            value: '7.920,00',
            label: t('pages.accountExpiration.subscriptionModel.yearly').text,
        },
    ];

    useEffect(() => {
        const purpose = lang === 'Русский' ? 'Mesečna pretplata Pausal aplikacije' : paymentPurpose;
        async function getAgency(): Promise<void> {
            const rr = `K:PR|V:01|C:1|R:340000001101368970|N:Clever doo, Starine Novaka 22, Beograd|I:RSD${
                amount === '7.920,00' ? '7920,00' : amount === '830,00' ? '830,00' : '0,00'
            }|SF:221|S:${purpose.slice(0, 35)}|P:${agency.full_name.slice(0, 70).trimEnd()}|RO:00${agency.pib}`;
            await communication
                .generateAccountExpirationQR(rr)
                .then((response) => {
                    setQrCode(response.i);
                })
                .catch((error) => {
                    if (error) {
                        console.error(error);
                    }
                });
        }
        getAgency();
    }, [amount]);

    const handlePrint = async (): Promise<void> => {
        const response = await communication.generateTemplateTransfer({
            call_number_debtor: '',
            call_number_recipient: agency?.pib,
            currency: 'RSD',
            debtor_account: agency?.first_account_number,
            model_number_debtor: '',
            model_number_recipient: '',
            orderer: agency?.full_name,
            payment_code: '221',
            payment_slip_heading:
                amount === '830,00' ? 'Mesečna Clever Pretplata - Generisan' : 'Godišnja Clever Pretplata - Generisan',
            purpose_of_payment:
                amount === '830,00' ? 'Mesečna pretplata Pausal aplikacije' : 'Godišnja pretplata Pausal aplikacije',
            recipient: 'Clever doo, Starine Novaka 22, Beograd',
            recipient_account: '340-0000011013689-70',
            total_amount: amount === '830,00' ? '830' : '7920',
            year: 2017,
        });

        const token = localStorage.getItem('access_token');
        if (token) {
            await communication.downloadTransferOrder(
                response.data.data.id,
                token,
                qrCode && `data:image/jpeg;base64,${qrCode}`,
            );
        }
    };

    return (
        <Container>
            <Header>
                <h2>{t('pages.accountExpiration.title').text.toUpperCase()}</h2>
                <Icon onClick={() => close()}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: '#92a5ac' }} />
                </Icon>
            </Header>
            <Body>
                <div className="select">
                    <div className="left">
                        <p> {t('pages.accountExpiration.subscriptionModel.title').text}</p>
                        <SelectSearch
                            placeholder={t('pages.accountExpiration.subscriptionModel.title').text}
                            optionList={subscriptions}
                            handleSelectedValue={(v: ObjectKeys) => {
                                setPaymentPurpose(v.label);
                                setAmount(v.value);
                            }}
                        />
                    </div>
                    <div className="right">
                        <Button
                            onClick={() => {
                                navigate('/settings/subscription/1');
                                close();
                            }}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="small"
                            size={200}
                        >
                            Izbor pretplate / plati karticom
                        </Button>
                    </div>
                </div>
                <div className="column">
                    <div>
                        <span>{t('pages.accountExpiration.orderer').text}</span>
                        <Input>{agency?.full_name}</Input>
                    </div>
                    <div>
                        <span>{t('pages.accountExpiration.purpose').text}</span>
                        <Input>
                            {amount === '830,00'
                                ? 'Mesečna pretplata Pausal aplikacije'
                                : amount === '7.920,00'
                                ? 'Godišnja pretplata Pausal aplikacije'
                                : 'Pretplata Pausal aplikacije'}
                        </Input>
                    </div>
                    <div>
                        <span>{t('pages.accountExpiration.recipient').text}</span>
                        <Input>Clever doo, Starine Novaka 22, Beograd</Input>
                    </div>
                </div>
                <div className="column right">
                    <div className="row-field">
                        <span>{t('pages.accountExpiration.code').text}</span>
                        <Input className="small">221</Input>
                    </div>
                    <div className="row-field">
                        <span>{t('pages.accountExpiration.currency').text}</span>
                        <Input className="small">RSD</Input>
                    </div>
                    <div className="row-field">
                        <span>{t('pages.accountExpiration.amount').text}</span>
                        <Input className="small">{amount}</Input>
                    </div>
                    <div className="small-field">
                        <span>{t('pages.accountExpiration.payee').text}</span>
                        <Input className="small">{agency?.first_account_number}</Input>
                    </div>

                    <div className="small-field">
                        <span>{t('pages.accountExpiration.modelOfPayment').text}</span>
                        <div style={{ display: 'flex' }}>
                            <Input className="small column1"></Input>
                            <Input className="small column2"></Input>
                        </div>
                    </div>

                    <div className="small-field">
                        <span>{t('pages.accountExpiration.recipientBank').text}</span>
                        <Input className="small">340-0000011013689-70</Input>
                    </div>

                    <div className="small-field">
                        <span>{t('pages.accountExpiration.modelAndPayment').text}</span>
                        <div style={{ display: 'flex' }}>
                            <Input className="small column1"></Input>
                            <Input className="small column2">{agency?.pib}</Input>
                        </div>
                    </div>
                </div>
            </Body>

            <Footer>
                <div className="date-location">
                    <span>{t('pages.accountExpiration.payeeStamp').text}</span>
                </div>
                <div className="date-location">
                    <span>{t('pages.accountExpiration.dateAndPlace').text}</span>
                </div>
                <div className="date-location">
                    <span>{t('pages.accountExpiration.executionDate').text}</span>
                </div>
            </Footer>

            {qrCode && (
                <QRContainer>
                    <Button
                        onClick={handlePrint}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={92}
                    >
                        {t('pages.accountExpiration.print').text}
                    </Button>
                    <img src={`data:image/jpeg;base64,${qrCode}`}></img>
                </QRContainer>
            )}
        </Container>
    );
};
export default AccountExpiration;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    overflow-y: auto;
    padding: 15px;
    z-index: 10016;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    width: 800px;
    max-width: 100%;
    background: var(--white);
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
    overflow: auto;
    max-height: 100vh;

    span {
        font-size: 13px;
        color: var(--gray);
        display: block;
        margin-bottom: 5px;
    }

    @media only screen and (max-width: 425px) {
        height: 100%;
        overflow: auto;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    padding: 2px 15px 20px;
    width: -webkit-fill-available;

    h2 {
        font-size: 24px;
        color: var(--black);
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
    }
`;
const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
`;
const Body = styled.div`
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;

    @media only screen and (max-width: 425px) {
        display: unset;
    }

    .select {
        flex: 1 1 100%;
        margin: 10px 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right {
            display: flex;
            justify-content: flex-end;
        }

        &.select {
            > div {
                width: 300px;
            }

            .css-1jqq78o-placeholder {
                color: var(--gray);
                font-size: 15px;
            }
        }
        .input-dropdown {
            height: 34px;
            color: var(--gray);
        }
        p {
            margin-bottom: 10px;
            font-size: 13px;
            color: var(--gray);

            :hover {
                color: var(--purple);
            }
        }
    }
    .column {
        flex: 1 1 50%;
        &.right {
            > div {
                margin-bottom: 16px;
            }
        }

        > div {
            margin: 10px 10px 20px;
        }
    }

    .small-field {
        flex: 1 1 100%;
        margin: 10px 10px 20px;
        overflow: hidden;
        margin-bottom: 16px;
    }
    .row-field {
        display: inline-table;
        width: calc(29% - 10px);
        margin-right: 0;
        margin-bottom: 6px !important;
    }
`;
const Input = styled.div`
    display: block;
    font-size: 12px;
    font-weight: 700;
    min-height: 88px;
    padding: 12px;
    border: 1px solid #adbcc1;
    line-height: 25px;

    &.small {
        min-height: auto;
        min-height: 39px;
        padding: 6px 12px;
        &.column1 {
            width: 70px;
        }
        &.column2 {
            float: left;
            margin-left: 10px;
            width: calc(100% - 80px);
        }
    }
`;

const Footer = styled.div`
    margin: 10px 10px 20px;
    width: 100%;
    height: 53px;
    @media only screen and (max-width: 425px) {
        height: 124px;
    }
    > div {
        border: 0;
        border-top: 1px solid #000;
        width: 170px;
        margin-top: 5px;
        display: block;
        margin-right: 40px;
        float: left;
        &.date-location {
            margin-top: 40px;
        }
    }
`;
const QRContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    button {
        float: left;
        font-size: 12px;
    }
    img {
        height: 200px;
        width: 200px;
    }
    @media only screen and (max-width: 425px) {
        width: 100%;
    }
`;
