import React, { FunctionComponent, useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useResize';
import styled from 'styled-components';
import SelectComponent from '../../components/select';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import Input, { InputComponentType } from '../../components/input';
import { ObjectKeys } from '@/types/objectKeys';
import useCharLengthValidation from '../../hooks/useCharLengthValidation';
// import communication from '../../communication';
import Loader from '../../components/loader';
import { useNavigate } from 'react-router-dom';

interface Props {
    yearsOptions: Array<{ value: any; label: any }>;
    closeModal: Function;
    user?: ObjectKeys;
    setIsConfirmModal: Function;
    setData: Function;
    year: string;
    isEmployed: boolean;
    callNumber: string;
    transferOrders?: ObjectKeys;
    currentMonth?: string;
}

export interface ChangeAmountTypesAdmin {
    year: string;
    months: Array<any>;
    user_id: string;
    model_number_recipient: number;
    call_number_recipient: string;
    total_amount: {
        doprinos_za_zdravstveno_osiguranje: number;
        doprinos_za_osiguranje_nezaposlenih: number;
        porez_na_prihode_od_samostalne_delatnosti: number;
        doprinos_za_pio: number;
    };
}

export interface HelpWithTaxTypes {
    year: string;
}

const ManageOrdersModalAdmin: FunctionComponent<Props> = ({
    yearsOptions,
    closeModal,
    user,
    setIsConfirmModal,
    setData,
    year,
    isEmployed,
    callNumber,
    transferOrders,
    currentMonth,
}) => {
    const size = useWindowSize();
    const navigate = useNavigate();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [selectedYear, setSelectedYear] = useState<string>(year);
    const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [choosenMonths, setChoosenMonths] = useState<Array<{ value: any; label: any }>>([]);

    const [changeAmountsData, setChangeAmountsData] = useState<ChangeAmountTypesAdmin>({
        year: year,
        months: [],
        user_id: '',
        model_number_recipient: 97,
        call_number_recipient: callNumber ? callNumber.toString() : '',
        total_amount: {
            doprinos_za_pio:
                transferOrders?.length > 0
                    ? transferOrders
                          ?.find((order: ObjectKeys) => order.payment_slip_heading === 'DOPRINOS ZA PIO')
                          ?.total_amount.toString()
                          .replace(/\./g, ',') || 0
                    : 0,
            porez_na_prihode_od_samostalne_delatnosti:
                transferOrders?.length > 0
                    ? transferOrders
                          ?.find(
                              (order: ObjectKeys) =>
                                  order.payment_slip_heading === 'POREZ NA PRIHODE OD SAMOSTALNE DELATNOSTI',
                          )
                          ?.total_amount.toString()
                          .replace(/\./g, ',') || 0
                    : 0,
            doprinos_za_zdravstveno_osiguranje:
                transferOrders?.length > 0
                    ? transferOrders
                          ?.find(
                              (order: ObjectKeys) =>
                                  order.payment_slip_heading === 'DOPRINOS ZA ZDRAVSTVENO OSIGURANJE',
                          )
                          ?.total_amount.toString()
                          .replace(/\./g, ',') || 0
                    : 0,
            doprinos_za_osiguranje_nezaposlenih:
                transferOrders?.length > 0
                    ? transferOrders
                          ?.find(
                              (order: ObjectKeys) =>
                                  order.payment_slip_heading === 'DOPRINOS ZA OSIGURANJE NEZAPOSLENIH',
                          )
                          ?.total_amount.toString()
                          .replace(/\./g, ',') || 0
                    : 0,
        },
    });

    const monthsOptions: Array<{ value: any; label: any }> = [
        { value: '00', label: 'Promeni za sve' },
        { value: '01', label: 'Januar' },
        { value: '02', label: 'Februar' },
        { value: '03', label: 'Mart' },
        { value: '04', label: 'April' },
        { value: '05', label: 'Maj' },
        { value: '06', label: 'Jun' },
        { value: '07', label: 'Jul' },
        { value: '08', label: 'Avgust' },
        { value: '09', label: 'Septembar' },
        { value: '10', label: 'Oktobar' },
        { value: '11', label: 'Novembar' },
        { value: '12', label: 'Decembar' },
    ];

    useEffect(() => {
        if (currentMonth) {
            const currentMonthNumber = currentMonth.length > 1 ? currentMonth : `0${currentMonth}`;
            setChoosenMonths([
                {
                    value: currentMonthNumber,
                    label: monthsOptions.find((month) => month.value === currentMonthNumber)?.label,
                },
            ]);
        }
    }, [currentMonth]);

    const { currentLang } = useLanguageStore();

    const t = useTranslations(currentLang);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        resetData();
        setChangeAmountsData((prevState) => ({ ...prevState, year: selectedYear }));
        setChangeAmountsData((prevState) => ({ ...prevState, user_id: user?.agency_id }));
    }, [selectedYear, year, user]);

    const resetData = (): void => {
        setChangeAmountsData({
            year: selectedYear,
            months: choosenMonths.map((month) => parseInt(month.value)),
            user_id: '',
            model_number_recipient: 97,
            call_number_recipient: callNumber ? callNumber.toString() : '',
            total_amount: {
                doprinos_za_pio:
                    transferOrders?.length > 0
                        ? transferOrders
                              ?.find((order: ObjectKeys) => order.payment_slip_heading === 'DOPRINOS ZA PIO')
                              ?.total_amount.toString()
                              .replace(/\./g, ',') || ''
                        : '',
                porez_na_prihode_od_samostalne_delatnosti:
                    transferOrders?.length > 0
                        ? transferOrders
                              ?.find(
                                  (order: ObjectKeys) =>
                                      order.payment_slip_heading === 'POREZ NA PRIHODE OD SAMOSTALNE DELATNOSTI',
                              )
                              ?.total_amount.toString()
                              .replace(/\./g, ',') || ''
                        : '',
                doprinos_za_zdravstveno_osiguranje:
                    transferOrders?.length > 0
                        ? transferOrders
                              ?.find(
                                  (order: ObjectKeys) =>
                                      order.payment_slip_heading === 'DOPRINOS ZA ZDRAVSTVENO OSIGURANJE',
                              )
                              ?.total_amount.toString()
                              .replace(/\./g, ',') || ''
                        : '',
                doprinos_za_osiguranje_nezaposlenih:
                    transferOrders?.length > 0
                        ? transferOrders
                              ?.find(
                                  (order: ObjectKeys) =>
                                      order.payment_slip_heading === 'DOPRINOS ZA OSIGURANJE NEZAPOSLENIH',
                              )
                              ?.total_amount.toString()
                              .replace(/\./g, ',') || ''
                        : '',
            },
        });
    };
    const handleMonths = (value: Array<{ value: any; label: any }>): void => {
        const containsZero = value.some((month) => month.value === '00');
        if (containsZero) {
            setChoosenMonths([
                { value: '01', label: 'Januar' },
                { value: '02', label: 'Februar' },
                { value: '03', label: 'Mart' },
                { value: '04', label: 'April' },
                { value: '05', label: 'Maj' },
                { value: '06', label: 'Jun' },
                { value: '07', label: 'Jul' },
                { value: '08', label: 'Avgust' },
                { value: '09', label: 'Septembar' },
                { value: '10', label: 'Oktobar' },
                { value: '11', label: 'Novembar' },
                { value: '12', label: 'Decembar' },
            ]);
        } else {
            setChoosenMonths(value.filter((month) => month.value !== '00'));
        }
    };

    useEffect(() => {
        setChangeAmountsData((prevState) => ({
            ...prevState,
            months: choosenMonths.map((month) => parseInt(month.value)),
        }));
    }, [choosenMonths]);

    const callNoValidation = useCharLengthValidation(
        changeAmountsData.call_number_recipient,
        parseInt(selectedYear) > 2018 ? 19 : 1,
        true,
    );

    const pioValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_pio
            ? changeAmountsData?.total_amount.doprinos_za_pio.toString()
            : '',
        1,
        true,
    );
    const formHealthValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_zdravstveno_osiguranje
            ? changeAmountsData?.total_amount.doprinos_za_zdravstveno_osiguranje.toString()
            : '',
        1,
        true,
    );
    const formUnemploymentValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.doprinos_za_osiguranje_nezaposlenih
            ? changeAmountsData?.total_amount.doprinos_za_osiguranje_nezaposlenih.toString()
            : '',
        1,
        true,
    );
    const formIncomeValidation = useCharLengthValidation(
        changeAmountsData?.total_amount.porez_na_prihode_od_samostalne_delatnosti
            ? changeAmountsData?.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString()
            : '',
        1,
        true,
    );

    const handleInputChange = (value: string): void => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setChangeAmountsData((prevState) => ({
            ...prevState,
            call_number_recipient: sanitizedValue,
        }));
    };
    return (
        <>
            {loader && <Loader />}
            <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
                <h1>{t('pages.transfers.manage_transfers').text}</h1>
                <div className={'change-amounts first-tab'}>
                    <h4>{t('pages.transfers.choose_year_for_generating').text}</h4>
                    <div className="select-year-container">
                        <article>
                            <div className={'select'}>
                                <label className="font_small">{'Odaberi koje mesece da generišemo'}</label>
                                <SelectComponent
                                    optionList={monthsOptions}
                                    value={choosenMonths}
                                    className={'select-month'}
                                    handleSelectedValue={(months: Array<{ value: any; label: any }>) =>
                                        handleMonths(months)
                                    }
                                    isMultiple={true}
                                />
                                {error && choosenMonths.length === 0 ? (
                                    <span className="error">Izaberite najmanje jedan mesec.</span>
                                ) : null}
                            </div>
                            <div className={'select'}>
                                <label>{`${
                                    t('pages.transfers.edit_tax_transfers_modal_year').text
                                } ${selectedYear}`}</label>

                                <SelectComponent
                                    optionList={yearsOptions}
                                    defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                    className={'select-year'}
                                    placeholder={selectedYear}
                                    handleSelectedValue={(value: string) => setSelectedYear(value)}
                                />
                            </div>
                            <Input
                                type={InputComponentType.Text}
                                value={changeAmountsData.call_number_recipient}
                                label={`${
                                    t('pages.transfers.edit_tax_transfers_modal__call_number_year').text
                                } ${selectedYear}.`}
                                onChange={(value: string) => {
                                    handleInputChange(value);
                                }}
                                validation={callNoValidation && error ? callNoValidation : ''}
                                maxLength={19}
                                className={'input'}
                            />
                            <Input
                                type={InputComponentType.Amount}
                                label={`${t('pages.transfers.form_pio').text} `}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            doprinos_za_pio: value,
                                        },
                                    }));
                                }}
                                validation={error ? pioValidation : ''}
                                className={'input'}
                                value={changeAmountsData.total_amount.doprinos_za_pio.toString()}
                            />
                            {!isEmployed ? (
                                <>
                                    <Input
                                        type={InputComponentType.Amount}
                                        label={`${t('pages.transfers.form_health').text}`}
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    doprinos_za_zdravstveno_osiguranje: value,
                                                },
                                            }));
                                        }}
                                        validation={error ? formHealthValidation : ''}
                                        className={'input'}
                                        value={changeAmountsData?.total_amount?.doprinos_za_zdravstveno_osiguranje?.toString()}
                                    />
                                </>
                            ) : null}
                            {!isEmployed ? (
                                <>
                                    <Input
                                        type={InputComponentType.Amount}
                                        label={`${t('pages.transfers.form_unemployment').text}`}
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    doprinos_za_osiguranje_nezaposlenih: value,
                                                },
                                            }));
                                        }}
                                        validation={error ? formUnemploymentValidation : ''}
                                        className={'input'}
                                        value={changeAmountsData?.total_amount?.doprinos_za_osiguranje_nezaposlenih?.toString()}
                                    />
                                </>
                            ) : null}
                            <Input
                                type={InputComponentType.Amount}
                                label={`${t('pages.transfers.form_income').text}`}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            porez_na_prihode_od_samostalne_delatnosti: value,
                                        },
                                    }));
                                }}
                                validation={error ? formIncomeValidation : ''}
                                className={'input'}
                                value={changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString()}
                            />
                        </article>
                    </div>
                </div>
                <div className={'buttons'}>
                    <Button
                        variant={ButtonVariant.outlined}
                        size={'50%'}
                        color={colors.purple}
                        onClick={() => closeModal()}
                    >
                        {t('pages.transfers.close').text}
                    </Button>
                    <Button
                        variant={ButtonVariant.solid}
                        size={'50%'}
                        color={colors.purple}
                        onClick={() => {
                            if (callNoValidation !== '') {
                                setError(true);
                                return;
                            } else if (choosenMonths.length === 0) {
                                setError(true);
                                return;
                            } else {
                                if (!isEmployed) {
                                    if (
                                        changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih === 0 ||
                                        changeAmountsData.total_amount.doprinos_za_pio === 0 ||
                                        changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje === 0 ||
                                        changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti === 0
                                    ) {
                                        setError(true);
                                        return;
                                    }
                                } else {
                                    if (
                                        changeAmountsData.total_amount.doprinos_za_pio === 0 ||
                                        changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti === 0
                                    ) {
                                        setError(true);
                                        return;
                                    }
                                }
                                setData(changeAmountsData);
                                closeModal();
                                navigate(`/admin/transfer-orders/${selectedYear}`);
                                setIsConfirmModal(true);
                            }
                        }}
                    >
                        {t('pages.transfers.send_solution').text}
                    </Button>
                </div>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
    }
    display: inline-block;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
    h1 {
        color: #4c595f;
        font-weight: 400;
        font-size: 20px;
        padding: 25px 50px 10px;
    }
    h4 {
        font-size: 18px;
        color: #9b7fe9;
    }
    .change-amounts {
        padding: 35px 20px 75px;
    }
    article {
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        position: relative;
        text-align: left;
        .select {
            color: #92a5ac;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            width: 100%;
            display: block;
        }
        .select-wrapper {
            width: 100%;
        }
    }
    .upload-tax {
        box-shadow: none !important;
        label {
            width: 100%;
        }
    }
    .description {
        display: none;
    }
    .modal-wrapper:has(> .change-amounts) {
        max-height: 90% !important;
        overflow: auto !important;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button:first-child {
            border-radius: 0 0 0 6px;
        }
        button:last-child {
            border-radius: 0 0 6px 0;
        }
    }
    .tabs {
        ul {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .first-tab {
        padding: 35px 50px 10px;
        input {
            width: 100%;
        }
    }
    .dropzone {
        max-width: 100%;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7 !important;
        color: #92a5ac;
        border-radius: 10px;
        margin: 20px 60px 10px;
        padding: 50px;
        img {
            width: 50px;
            margin-bottom: 50px;
        }
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 3px;
        display: block;
    }
`;

export default ManageOrdersModalAdmin;
