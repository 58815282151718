import { FunctionComponent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faEraser, faBars } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import Input from '../../input';
import { InputComponentType } from '../../input';
import SelectComponent from '../../select';
import { formatNumber, formatToSerbianNumber } from '../../../functions/format';
import useNumberValidation from '../../../hooks/useNumberValidation';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useWindowSize from '../../../hooks/useResize';
import useCurrencyStore from '../../../store/currency';
import communication from '../../../communication';

export type Currencies = [
    {
        currency: string;
        name: string;
        object: string;
        sre: string;
        pairs: any;
    },
];

interface Props {
    onClose: Function;
    width?: string;
    height?: string;
}
interface ContainerProps {
    width?: string;
    height?: string;
}

const Nbs: FunctionComponent<Props> = ({ onClose, width, height }) => {
    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
        setIsSmallScreenWidth(!!size.width && size.width < 1079 && size.width > 768);
    }, [size.width]);

    const [currencies, setCurrencies] = useState<Currencies>();
    const [course, setCourse] = useState('');
    const [yourCourseVisible, setYourCourseVisible] = useState(false);
    const [yourCourse, setYourCourse] = useState(0);
    const [result, setResult] = useState(0);
    const [inputValue, setInputValue] = useState(0);
    const [leftValue, setLeftValue] = useState(1);
    const [rightValue, setRightValue] = useState(1);
    const [date, setDate] = useState(new Date());
    const [onLoading, setOnLoading] = useState(false);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const data = useCurrencyStore();
    useEffect(() => {
        setCurrencies(data.currency as Currencies);
        setLeftValue(Number(data.currency[0]?.sre));
    }, []);

    useEffect(() => {
        if (yourCourse.toString().includes('.')) {
            setResult(0);
            return;
        }
        if (yourCourse !== 0) {
            setResult(Number(yourCourse.toString().replace(',', '.')) * inputValue);
        } else {
            const parsedCourse = course !== '' ? parseFloat(course.replace(',', '.')) : 0;
            setResult((parsedCourse * leftValue) / rightValue);
        }
    }, [course, leftValue, rightValue, yourCourse]);

    const getCurrencies = async (choosen_date: string): Promise<void> => {
        setOnLoading(true);
        communication
            .fetchPickedCurrencies(choosen_date)
            .then((res: any) => {
                setOnLoading(false);
                useCurrencyStore.setState({ currency: res?.data });
            })
            .catch((error: any) => {
                console.error(error);
                setOnLoading(false);
                if (error) {
                    useCurrencyStore.setState({ currency: [] });
                }
            });
    };

    const handleClear = (): void => {
        setResult(0);
        setInputValue(0);
        setCourse('');
        setYourCourse(0);
        setLeftValue(1);
        setRightValue(1);
    };

    const rsdSelectOption = currencies?.find((x) => x.currency === 'rsd');

    useEffect(() => {
        setCurrencies(data.currency as Currencies);
        setLeftValue(Number(data.currency[0]?.sre));
    }, [data]);

    return (
        <>
            {currencies && (
                <Container width={width} height={height}>
                    {!(isMobileWidth || isSmallScreenWidth) ? (
                        <Row>
                            <Left onClick={() => handleClear()}>
                                <FontAwesomeIcon style={{ cursor: 'pointer' }} color={colors.purple} icon={faEraser} />
                            </Left>
                            <Right
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <FontAwesomeIcon style={{ cursor: 'pointer' }} color={colors.purple} icon={faXmark} />
                            </Right>
                        </Row>
                    ) : (
                        <MobileHeader>
                            <Row>
                                <Left onClick={() => handleClear()}>
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        color={colors.purple}
                                        icon={faEraser}
                                    />
                                </Left>
                                <Right onClick={() => onClose()}>
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        color={colors.purple}
                                        icon={faXmark}
                                    />
                                </Right>
                            </Row>
                        </MobileHeader>
                    )}

                    <div className="datepicker">
                        <p>{t('navbar.nbs.title').text}</p>
                        <Input
                            type={InputComponentType.Date}
                            date={date}
                            onChange={(value: Date) => {
                                setDate(value);
                                const year = value.getFullYear();
                                const month = ('0' + (value.getMonth() + 1)).slice(-2);
                                const day = ('0' + value.getDate()).slice(-2);
                                const formattedDate = `${year}-${month}-${day}`;
                                getCurrencies(formattedDate);
                            }}
                            inputClassName={'nbs-datepicker'}
                            maxDate={new Date()}
                        />
                    </div>
                    {onLoading ? (
                        <>
                            <div className="skeleton">
                                <SkeletonPurple />
                                <Skeleton />
                                <SkeletonPurple />
                                <Skeleton />
                                <SkeletonPurple />
                                <Skeleton />
                                <SkeletonPurple />
                                <Skeleton />
                            </div>
                        </>
                    ) : (
                        <Currencies>
                            {!(isMobileWidth || isSmallScreenWidth) ? (
                                <h4>{t('navbar.nbs.subtitle').text}</h4>
                            ) : (
                                <MobileFont>
                                    <h4>{t('navbar.nbs.subtitle').text}</h4>
                                </MobileFont>
                            )}

                            {!(isMobileWidth || isSmallScreenWidth) ? (
                                <Table>
                                    {currencies?.map((c, index) => (
                                        <div key={c.currency}>
                                            {c.currency !== 'rsd' && (
                                                <Row className={index % 2 === 0 ? 'odd' : ''}>
                                                    <Left key={c.currency}>{c.currency.toUpperCase()}</Left>
                                                    <Right>{formatNumber(parseFloat(c.sre), 4, 3, '.', ',')}</Right>
                                                </Row>
                                            )}
                                        </div>
                                    ))}
                                </Table>
                            ) : (
                                <Table>
                                    {currencies?.map((c, index) => (
                                        <div key={c.currency}>
                                            {c.currency !== 'rsd' && (
                                                <MobileRow className={index % 2 === 0 ? 'odd' : ''}>
                                                    <Left key={c.currency}>{c.currency.toUpperCase()}</Left>
                                                    <Right>{formatNumber(parseFloat(c.sre), 2, 3, '.', ',')}</Right>
                                                </MobileRow>
                                            )}
                                        </div>
                                    ))}
                                </Table>
                            )}
                        </Currencies>
                    )}

                    <Footer>
                        <AddCurrency
                            onClick={() => {
                                if (yourCourseVisible) {
                                    setYourCourse(0);
                                    setCourse('');
                                    setResult(0);
                                    setInputValue(0);
                                    setLeftValue(Number(data.currency[0]?.sre));
                                }
                                setYourCourseVisible(!yourCourseVisible);
                            }}
                        >
                            <FontAwesomeIcon style={{ cursor: 'pointer' }} color={colors.purple} icon={faBars} />

                            <p>{t('navbar.nbs.yourExchange').text}</p>
                        </AddCurrency>
                        {yourCourseVisible && (
                            <InputContainer>
                                <Input
                                    className="bordered"
                                    type={InputComponentType.Amount}
                                    value={yourCourse?.toString()}
                                    onChange={(value: number) => {
                                        setYourCourse(value);
                                    }}
                                />
                            </InputContainer>
                        )}

                        <CurrencyValues>
                            <SelectComponent
                                handleSelectedValue={(v: number) => {
                                    setLeftValue(v);
                                }}
                                optionList={currencies.map(({ currency, sre }) => ({
                                    label: currency.toUpperCase(),
                                    value: sre,
                                }))}
                                width={!(isMobileWidth || isSmallScreenWidth) ? '83px' : '50%'}
                                color={colors.gray}
                                value={currencies[0].sre}
                            />
                            <SelectComponent
                                handleSelectedValue={(v: number) => setRightValue(v)}
                                optionList={currencies.map(({ currency, sre }) => ({
                                    label: currency.toUpperCase(),
                                    value: sre,
                                }))}
                                width={!(isMobileWidth || isSmallScreenWidth) ? '83px' : '50%'}
                                color={colors.gray}
                                defaultSelectedOption={
                                    rsdSelectOption && {
                                        label: rsdSelectOption.currency.toUpperCase(),
                                        value: rsdSelectOption.sre,
                                    }
                                }
                            />
                        </CurrencyValues>

                        <InputContainer>
                            <Input
                                className="bordered"
                                type={InputComponentType.Amount}
                                value={course?.toString()}
                                onChange={(v: string) => {
                                    const parsedValue = v !== '' ? parseFloat(v.replace(',', '.')) : 0;
                                    setCourse(v);
                                    // setResult((parsedValue * leftValue) / rightValue);
                                    setInputValue(parsedValue);
                                }}
                            />
                        </InputContainer>

                        <Result>
                            <strong>= {formatToSerbianNumber(result, 4)}</strong>
                        </Result>
                    </Footer>
                </Container>
            )}
        </>
    );
};

const Container = styled.div<ContainerProps>`
    border-radius: 4px;
    background: var(--white);
    border: 1px solid var(--purple);
    font-size: 12px;
    z-index: 9999;
    width: ${(props) => (props.width ? `${props.width}` : '185px')};
    height: ${(props) => (props.height ? `${props.height}` : 'max-content')};

    > p {
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
    }

    animation: slide-up 0.25s ease;
    @keyframes slide-up {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0);
        }
        100% {
            opacity: 1;
            transform: translateY(-50%);
            top: 50%;
        }
    }

    bottom: 180px;
    .datepicker {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 70%;
        > p {
            text-align: center;
            font-weight: 700;
            font-size: 15px;
            line-height: 28px;
        }
    }
    .skeleton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
        border-radius: 4px;
        border: 1px solid var(--purple);
        padding: 15px;
    }
`;

const Currencies = styled.div`
    margin: 5px;
    border-radius: 4px;
    border: 1px solid var(--purple);
    padding: 15px;
    background-color: var(--white);
    > h4 {
        float: right;
        font-weight: 400;
        font-size: 12px;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled.div`
    display: block;
    width: 100%;
    height: 18px;
    padding: 3px;
    &.odd {
        background: var(--purple);
        color: var(--white);
    }
`;

const MobileRow = styled.div`
    display: block;
    width: 100%;
    height: 18px;
    padding: 3px;
    height: 30px;
    padding-top: 7px;
    font-size: 13px;
    &.odd {
        background: var(--purple);
        color: var(--white);
    }
`;

const MobileHeader = styled.div`
    svg {
        height: 17px;
    }
`;

const MobileFont = styled.div`
    h4 {
        font-size: 14px;
    }
    margin-bottom: 8px;
    margin-top: 8px;
    float: right;
`;

const Left = styled.div`
    float: left;
`;

const Right = styled.div`
    text-align: right;
`;

const Footer = styled.div`
    padding: 5px;
`;

const AddCurrency = styled.div`
    display: flex;
    margin: 10px 5px;
    font-weight: 700;
    cursor: pointer;
    align-items: center;

    > p {
        color: var(--purple);
        margin-left: 2px;
        font-size: 12px;
        font-weight: 700;
    }
`;

const InputContainer = styled.div`
    padding: 3px;
    margin-bottom: 3px;
`;

const CurrencyValues = styled.div`
    display: flex;
    justify-content: center;
    > div {
        :not(:nth-child(1)) {
            margin-left: 4px;
        }
    }
    font-size: 8px;
    margin-bottom: 3px;
`;

const Result = styled.div`
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 3px;
    color: var(--black);
    min-width: 50%;
    word-break: break-word;
    font-size: 14px;

    margin: 10px 0;

    &.marginTop {
        margin-top: 20px;
    }
`;
const skeletonAnimation = keyframes`
    0% { background-position: -200px 0; }
    100% { background-position: calc(200px + 100%) 0; }
`;

// Define a skeleton style
const Skeleton = styled.div`
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200px 100%;
    animation: ${skeletonAnimation} 1.5s infinite linear;
    border-radius: 4px;
    width: 100%;
`;
const SkeletonPurple = styled.div`
    height: 20px;
    background: linear-gradient(90deg, var(--purple) 25%, #e0e0e0 50%, var(--purple) 75%);
    background-size: 200px 100%;
    animation: ${skeletonAnimation} 1.5s infinite linear;
    border-radius: 4px;
    width: 100%;
`;

export default Nbs;
