import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '@/types/objectKeys';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import { formatTwoDecimals } from '../../functions/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
    template: ObjectKeys;
    onClick: () => void;
    isPaid: boolean;
};

const TransferOrderTemplate: FunctionComponent<Props> = ({ template, onClick, isPaid }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    return (
        <Content>
            <div onClick={onClick} className="box">
                <h1 className={'title'}>{template?.payment_slip_heading}</h1>
                <div className="price-container">
                    <span>{t('pages.transfers.amount').text}</span>
                    <p>
                        {formatTwoDecimals(template?.total_amount)} {template?.currency}
                    </p>
                </div>
                <div className={`icon-container ${isPaid ? 'paid' : 'not-paid'}`}>
                    {isPaid ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                    ) : (
                        <FontAwesomeIcon icon={faExclamationCircle} className="check-icon" />
                    )}
                </div>
            </div>
        </Content>
    );
};

const Content = styled.div`
    @media (max-width: 1079px) {
        width: 50%;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        max-width: 50%;
        &:nth-child(even) {
            padding-right: 0px;
        }
        &:nth-child(odd) {
            padding-left: 0px;
        }
    }
    width: 25%;
    flex: 1 1 25%;

    margin-top: 25px;
    padding-left: 10px;
    padding-right: 10px;

    .box {
        background: #fff;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border: 1px solid #9b7fe9;
        :hover {
            cursor: pointer;
            -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        }
        position: relative;
    }
    .icon-container {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
    .paid {
        color: var(--green);
    }

    .not-paid {
        color: #ff9999;
    }

    .check-icon {
        font-size: 24px;
    }
    .title {
        color: #92a5ac;
        font-size: 13px;
        line-height: 20px;
        padding: 18px 24px 0;
        min-height: 5.4em;
        margin-right: 0.83em;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .price-container {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        padding: 0 24px 24px;
        span {
            display: block;
            color: #92a5ac;
            font-size: 13px;
            margin-bottom: 5px;
        }
        p {
            font-weight: 400;
            font-size: 22px;
            color: #4c595f;
        }
    }
`;
export default TransferOrderTemplate;
