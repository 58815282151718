import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import TextArea from '../../../../components/input/textarea';
import Input, { InputComponentType } from '../../../../components/input';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
// import Checkbox from '../../../../components/checkbox';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { ObjectKeys } from '@/types/objectKeys';

interface ServiceFormProps {
    initialData?: ObjectKeys;
    onSave: (data: any, id?: string) => void;
    onCancel: () => void;
    isOpen: boolean;
    type: string;
    id?: string;
}

const ServiceForm: FunctionComponent<ServiceFormProps> = ({
    initialData = null,
    onSave,
    onCancel,
    isOpen,
    type,
    id,
}) => {
    const [renderKey, setRenderKey] = useState(0);
    const [showError, setShowError] = useState(false);
    const [questionModal, setQuestionModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [formData, setFormData] = useState({
        name_rs: initialData ? initialData.name_rs : '',
        name_en: initialData ? initialData.name_en : '',
        name_ru: initialData ? initialData.name_ru : '',
        description_rs: initialData ? initialData.description_rs : '',
        description_en: initialData ? initialData.description_en : '',
        description_ru: initialData ? initialData.description_ru : '',
        price: initialData ? initialData.price : 0,
    });

    const resetForm = (): void => {
        setFormData({
            name_rs: '',
            name_en: '',
            name_ru: '',
            description_rs: '',
            description_en: '',
            description_ru: '',
            price: 0,
        });
        setShowError(false);
        setRenderKey(renderKey + 1);
    };

    const handlevalidation = (): void => {
        if (
            formData.name_rs === '' ||
            formData.name_en === '' ||
            formData.name_ru === '' ||
            formData.description_rs === '' ||
            formData.description_en === '' ||
            formData.description_ru === '' ||
            formData.price === 0 ||
            formData.price === undefined
        ) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            if (type === 'create') {
                setModalMessage('Da li ste sigurni da želite da dodate uslugu?');
                setQuestionModal(true);
            } else {
                setModalMessage('Da li ste sigurni da želite da izmenite uslugu?');
                setQuestionModal(true);
            }
        }
    };

    return (
        <>
            {questionModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setQuestionModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setQuestionModal(false);
                            onSave(formData, id);
                            resetForm();
                        }}
                        close={() => {
                            setQuestionModal(false);
                        }}
                        active={questionModal}
                        message={modalMessage}
                    />
                </Modal>
            )}
            <Form className={isOpen ? 'open' : ''}>
                <div className="type">
                    <h2>{type === 'create' ? 'Dodaj uslugu' : 'Izmeni uslugu'}</h2>
                </div>
                <div>
                    <div>
                        <p className="bold">Naziv usluge:</p>
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_rs}
                            label={'Srpski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_rs: value,
                                });
                            }}
                            className={'input'}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_en}
                            label={'Engleski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_en: value,
                                });
                            }}
                            className={'input'}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_ru}
                            label={'Ruski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_ru: value,
                                });
                            }}
                            className={'input'}
                        />
                    </div>
                    <div>
                        <p className="bold">Cena (RSD):</p>
                        <Input
                            type={InputComponentType.Amount}
                            value={formData.price.toString()}
                            onChange={(value: any) => {
                                setFormData({
                                    ...formData,
                                    price: value,
                                });
                            }}
                            className={'input'}
                        />
                    </div>
                    <div>
                        <p className="bold">Opis:</p>
                        <TextArea
                            label={'Srpski'}
                            value={formData.description_rs}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_rs: value,
                                });
                            }}
                            height={'50px'}
                        />
                        <TextArea
                            label={'Engleski'}
                            value={formData.description_en}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_en: value,
                                });
                            }}
                            height={'50px'}
                        />
                        <TextArea
                            label={'Ruski'}
                            value={formData.description_ru}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_ru: value,
                                });
                            }}
                            height={'50px'}
                        />
                    </div>
                    {/* <div className="check-box-div" key={renderKey}>
                        <p className="bold">Spada u paket:</p>
                        <div>
                            <Checkbox
                                defaultChecked={formData.package.includes('Basic')}
                                onChange={(isChecked: boolean) => handlePackageChange(isChecked, 'Basic')}
                                label={'Basic'}
                            />
                            <Checkbox
                                defaultChecked={formData.package.includes('Medium')}
                                onChange={(isChecked: boolean) => handlePackageChange(isChecked, 'Medium')}
                                label={'Medium'}
                            />
                            <Checkbox
                                defaultChecked={formData.package.includes('Premium')}
                                onChange={(isChecked: boolean) => handlePackageChange(isChecked, 'Premium')}
                                label={'Premium'}
                            />
                        </div>
                    </div> */}
                </div>
                <div style={{ textAlign: 'center' }}>
                    {showError ? <span className="error">Neophodno je popuniti sva polja za unos usluge!</span> : null}
                </div>
                <div className="button">
                    <div className="button-2">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'60%'}
                            onClick={() => handlevalidation()}
                        >
                            <FontAwesomeIcon icon={faSave} className="fa-icon-save" />
                            Sačuvaj
                        </Button>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'60%'}
                            onClick={() => {
                                setShowError(false);
                                onCancel();
                            }}
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon-save" />
                            Odustani
                        </Button>
                    </div>
                    <Button variant={ButtonVariant.solid} color={colors.danger} size={'20%'} onClick={resetForm}>
                        <FontAwesomeIcon icon={faTrash} className="fa-icon-save" />
                        Poništi unos
                    </Button>
                </div>
            </Form>
        </>
    );
};
const Form = styled.div`
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;

    &.open {
        border: 2px solid var(--purple);
        margin-top: 20px;
        padding: 20px;
        opacity: 1;
        max-height: 100%;
        min-height: 690px;
        .bold {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .button {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
        .button-2 {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        .fa-icon-save {
            margin-right: 10px;
            color: var(--white);
        }
        .error {
            color: var(--danger);
            font-size: 14px;
            margin-top: 10px;
            animation: blink 1.5s infinite;
        }
        @keyframes blink {
            50% {
                opacity: 0;
            }
        }
        .type {
            margin-bottom: 20px;
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: var(--purple);
            }
            text-align: center;
        }
    }
`;

export default ServiceForm;
