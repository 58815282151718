import React, { useEffect, useState } from 'react';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import communication from '../../../../communication';
import moment from 'moment';
import colors from '../../../../global/colors';
import styled from 'styled-components';
import Loader from '../../../../components/loader';
import { formatTwoDecimals } from '../../../../functions/format';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';

Chart.register(ArcElement, Tooltip, Legend, Title);
Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(0, 0, 156)';
Chart.defaults.plugins.legend.position = 'top';
Chart.defaults.plugins.legend.title.font = { family: 'Helvetica Neue' };
Chart.defaults.plugins.legend.labels.boxWidth = 50;
Chart.defaults.plugins.legend.labels.boxHeight = 20;

const CreateDoughnutData: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('1 mesec');
    const [loader, setLoader] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [total, setTotal] = useState<number>(0);
    const [green, setGreen] = useState<number>(0);
    const [red, setRed] = useState<number>(0);
    const size = useWindowSize();
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 1000);
    const [is4KWidth, setIs4KWidth] = useState(!!size.width && size.width > 1440);
    const fetchTotale = (): void => {
        if (!startDate) return;
        setLoader(true);
        const propsTotal = {
            start_date: startDate,
            end_date: moment().format('YYYY-MM-DD'),
        };
        communication.getDoughnut(propsTotal).then((res: any) => {
            setGreen(res.data.paid_value_sum);
            setRed(res.data.saldo);
            setTotal(res.data.value_sum);
            setLoader(false);
        });
    };
    const handlePeriodClick = (period: string): void => {
        setSelectedPeriod(period);
        let daysBack = 0;
        switch (period) {
            case '1 mesec':
                daysBack = 30;
                break;
            case '3 meseca':
                daysBack = 90;
                break;
            case '6 meseci':
                daysBack = 180;
                break;
            case '12 meseci':
                daysBack = 365;
                break;
            default:
                daysBack = 30;
        }
        const newStartDate = moment().subtract(daysBack, 'days').format('YYYY-MM-DD');
        setStartDate(newStartDate);
    };
    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 1000);
        setIs4KWidth(!!size.width && size.width > 1440);
    }, [size.width]);

    useEffect(() => {
        fetchTotale();
    }, [startDate]);

    const data = {
        labels: [`${t('pages.reports.paid').text}`, `${t('pages.reports.unpaid').text}`],
        datasets: [
            {
                data: [green, red],
                backgroundColor: ['#9b7fe9', '#cebcff'],
                borderWidth: 3,
                radius: '80%',
                borderColor: 'white',
            },
        ],
    };
    const options = {
        plugins: {
            datalabels: {
                formatter: (value: any, ctx: any) => {
                    const percentage = ((ctx.dataset.data[ctx.dataIndex] * 100) / total).toFixed(2) + '%';
                    return `${percentage.replace('.', ',')}`;
                },
                color: 'black',
                borderColor: 'black',
                anchor: 'end',
                align: 'start',
                offset: is4KWidth ? 20 : 2,
                font: {
                    weight: 'normal',
                    size: '14',
                },
            } as any,
        },
        responsive: true,
        layout: {
            padding: {
                left: isTabletWidth ? 0 : is4KWidth ? 100 : 50,
                right: isTabletWidth ? 0 : is4KWidth ? 100 : 50,
                top: isTabletWidth ? 0 : is4KWidth ? 100 : 50,
                bottom: isTabletWidth ? 0 : is4KWidth ? 100 : 50,
            },
        },
        legend: {
            display: true,
            position: 'top', // Adjust the legend position as needed
        },
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
    };

    return (
        <div>
            {loader && <Loader />}
            <Content>
                <h3>{t('pages.reports.charts.invoiced_balans').text}</h3>
                <div className="period-container">
                    {t('pages.reports.period').text}:
                    <span
                        className={`period ${selectedPeriod === '1 mesec' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('1 mesec')}
                    >
                        {t('pages.reports.charts.one_month').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '3 meseca' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('3 meseca')}
                    >
                        {t('pages.reports.charts.three_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '6 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('6 meseci')}
                    >
                        {t('pages.reports.charts.six_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '12 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('12 meseci')}
                    >
                        {t('pages.reports.charts.twelve_months').text}
                    </span>
                    <br></br>
                    <br></br>
                </div>
            </Content>
            {total === 0 ? (
                <NoData>{t('pages.reports.charts.noData').text}</NoData>
            ) : (
                <ResponsiveDoughnut data={data} options={options} />
            )}
            <Values>
                <p>
                    {t('pages.reports.charts.total_invoiced').text}: {formatTwoDecimals(total.toString())} RSD
                </p>
                <p>
                    {t('pages.reports.charts.paid_total').text}: {formatTwoDecimals(green.toString())} RSD
                </p>
                <p>
                    {t('pages.reports.charts.unpaid_total').text}: {formatTwoDecimals(red.toString())} RSD
                </p>
            </Values>
        </div>
    );
};
const ResponsiveDoughnut = styled(Doughnut)`
    max-width: 100%;
    max-height: 100%;
    margin-top: -10px;
    @media only screen and (max-width: 1024px) {
        max-width: 200%;
        max-height: 200%;
    }
`;
const Values = styled.div`
    @media only screen and (min-width: 1440px) {
        margin-top: -80px;
    }
    @media only screen and (man-width: 1440px) {
        margin-top: -30px;
    }
    p {
        color: var(--gray);
        font-size: 15px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;
const NoData = styled.div`
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;
`;
const Content = styled.div`
    margin-top: 20px;
    h3 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 20px;
    }
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .period-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
    }

    .period {
        margin: 0 10px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
    }

    .period:hover {
        background-color: #f0f0f0;
    }
    .period.active {
        background-color: ${colors.purple};
        color: #fff;
    }
    @media only screen and (max-width: 768px) {
        .period-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .period {
            margin: 5px 0;
        }
    }
`;
export default CreateDoughnutData;
