import { FunctionComponent } from 'react';
import styled from 'styled-components';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faMinusCircle,
    faPenToSquare,
    faPlus,
    faRefresh,
    faA,
    faBell,
    faEraser,
} from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../../types/objectKeys';
interface Props {
    type: string;

    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleAdvance?: Function;
    handleClick?: Function;
    handleRefresh?: Function;
    handleRemovePayments?: Function;
    cardData: ObjectKeys;
    t: Function;
    eInvoice?: boolean;
}
const InvoiceActionsWithoutDropdown: FunctionComponent<Props> = ({
    cardData,
    handleDownload,
    handleInvoice,
    handleAdvance,
    handleCopy,
    handlePayment,
    handleCancel,
    handleChange,
    handleDelete,
    handleSend,
    handleReminder,
    handleEInvoice,
    handleClick,
    handleRefresh,
    handleRemovePayments,
    type,
    t,
    eInvoice,
}) => {
    const role = localStorage.getItem('role');

    return (
        <ActionWithoutDropdown>
            {role !== 'admin' ? (
                cardData?.status === 'paid' ? (
                    <>
                        {eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.refresh').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => handleDelete && handleDelete(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                        {cardData?.type !== 'foreign' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faE}
                                    style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                    onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                />
                                <div className="label">
                                    {type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text}
                                </div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEraser}
                                style={{ color: colors.danger }}
                                onClick={(e) => handleRemovePayments && handleRemovePayments(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.removePayment').text}</div>
                        </Icon>
                    </>
                ) : cardData?.status === 'open' && (type === 'invoice' || type === 'advanceInvoice') ? (
                    <>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleChange && handleChange(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => {
                                        handleClick && handleClick(cardData.id);
                                        handleDelete && handleDelete(e);
                                    }}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                    </>
                ) : cardData?.status === 'sent' ? (
                    <>
                        {eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.refresh').text}</div>
                            </Icon>
                        ) : null}
                        {!eInvoice && (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    style={{ color: colors.blue }}
                                    onClick={(e) => handleChange && handleChange(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                            </Icon>
                        )}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faMinusCircle}
                                style={{ color: colors.gray }}
                                onClick={(e) => handleCancel && handleCancel(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.cancel').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => handleDelete && handleDelete(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                        {type !== 'advanceInvoice' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ color: colors.green }}
                                    onClick={(e) => handlePayment && handlePayment(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.payment').text}</div>
                            </Icon>
                        ) : (
                            <></>
                        )}
                        {cardData?.type !== 'foreign' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faE}
                                    style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                    onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                />
                                <div className="label">
                                    {type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text}
                                </div>
                            </Icon>
                        ) : null}
                        {type !== 'advanceInvoice' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faBell}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleReminder && handleReminder(e)}
                                />
                                <div className="label">{t('pages.invoices.sendModal.send_reminder').text}</div>
                            </Icon>
                        ) : (
                            <></>
                        )}
                    </>
                ) : cardData?.status === 'partially' ? (
                    <>
                        {eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.refresh').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => handleDelete && handleDelete(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                        {type !== 'advanceInvoice' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ color: colors.green }}
                                    onClick={(e) => handlePayment && handlePayment(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.payment').text}</div>
                            </Icon>
                        ) : (
                            <></>
                        )}
                        {cardData?.type !== 'foreign' ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faE}
                                    style={{ color: cardData.is_einvoice ? colors.gray : colors.green }}
                                    onClick={(e) => handleEInvoice && handleEInvoice(e)}
                                />
                                <div className="label">
                                    {type !== 'advanceInvoice'
                                        ? t('pages.invoiceCard.dropdownItems.eInvoice').text
                                        : t('pages.invoiceCard.dropdownItems.eAdvance').text}
                                </div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEraser}
                                style={{ color: colors.danger }}
                                onClick={(e) => handleRemovePayments && handleRemovePayments(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.removePayment').text}</div>
                        </Icon>
                    </>
                ) : cardData?.status === 'cancelled' ? (
                    <>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => handleDelete && handleDelete(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                    </>
                ) : cardData?.status === 'open' && type === 'proforma' ? (
                    <>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleChange && handleChange(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: colors.green }}
                                onClick={(e) => handleInvoice && handleInvoice(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.invoice').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faA}
                                style={{ color: colors.brown }}
                                onClick={(e) => handleAdvance && handleAdvance(e)}
                            />
                            <div className="label">{t('pages.advanceInvoices.single').text}</div>
                        </Icon>
                        {!eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ color: colors.danger }}
                                    onClick={(e) => handleDelete && handleDelete(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                    </>
                ) : cardData?.status === 'closed' ? (
                    <>
                        {eInvoice ? (
                            <Icon>
                                <FontAwesomeIcon
                                    icon={faRefresh}
                                    style={{ color: colors.purple }}
                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                />
                                <div className="label">{t('pages.invoiceCard.dropdownItems.refresh').text}</div>
                            </Icon>
                        ) : null}
                        <Icon>
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleDownload && handleDownload(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faClone}
                                style={{ color: colors.purple }}
                                onClick={(e) => handleCopy && handleCopy(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                style={{ color: colors.blue }}
                                onClick={(e) => handleSend && handleSend(e)}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                        </Icon>
                    </>
                ) : (
                    <></>
                )
            ) : (
                <>
                    <Icon>
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{ color: colors.blue }}
                            onClick={(e) => handleChange && handleChange(e)}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                    </Icon>
                    <Icon>
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            style={{ color: colors.danger }}
                            onClick={(e) => handleDelete && handleDelete(e)}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                    </Icon>
                    <Icon>
                        <FontAwesomeIcon
                            icon={faDownload}
                            style={{ color: colors.blue }}
                            onClick={(e) => handleDownload && handleDownload(e)}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.download').text}</div>
                    </Icon>
                </>
            )}
        </ActionWithoutDropdown>
    );
};
const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 10px;
    > div {
        margin: 0 5px;
    }
`;
export const Icon = styled.div`
    position: relative;

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            top: -40px;
            opacity: 1;
            transition: top 0.5s, opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;
export default InvoiceActionsWithoutDropdown;
