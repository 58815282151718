import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components';
import DragAndDrop from '../../../components/dragAndDrop';
import useWindowSize from '../../../hooks/useResize';

interface Props {
    tabs?: [];
}

const PaymentInstructions: FunctionComponent<Props> = ({
    tabs = ['EUR', 'USD', 'CHF', 'GBP', 'AUD', 'CAD', 'NOK'],
}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    return (
        <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {tabs.map((tab, index) => (
                        <Tab className={'custom-tab'} key={index}>
                            {tab}
                        </Tab>
                    ))}
                </TabList>
                {tabs.map((tab, index) => (
                    <TabPanel key={index}>
                        <DragAndDrop type={tab.toLowerCase()} key={index} />{' '}
                    </TabPanel>
                ))}
            </Tabs>
        </Content>
    );
};

const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 14.27%;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
`;

export default PaymentInstructions;
