import moment from 'moment';

export function formatNumber(number: number, n: number, x: number, s: string, c: string): number | string {
    if (!number) {
        return '0';
    }

    if (typeof number === 'string') {
        number = parseFloat(number);
    }

    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(/\./g, c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function formatToSerbianNumber(num: number, n = 4): number | string {
    return formatNumber(num, n, 3, '.', ',');
}

export function formatDateTime(dateTime: string): string {
    return moment(dateTime).format('DD.MM.YYYY hh:mm');
}

export function formatTwoDecimals(num: string): string {
    const parsedNum = parseFloat(num.replace(',', '.')); // Replace comma with dot
    if (isNaN(parsedNum)) {
        // Handle the case where the input is not a valid number
        return 'Invalid Number';
    }
    // Use toFixed(2) to round the number to two decimal places and then replace the dot with a comma
    const roundedNumber = parsedNum
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return roundedNumber;
}
