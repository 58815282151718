// import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import useStatisticStore from '../../../store/statistics';

const getStatistics = (): Promise<ObjectKeys> => {
    return new Promise((resolve, reject) => {
        communication
            .getInvoiceStatistics()
            .then((res: ObjectKeys) => {
                if (res?.status === 200) {
                    useStatisticStore.setState({ statisticData: res?.data });
                    resolve(res);
                } else {
                    reject(new Error(`Failed to fetch statistics. Status: ${res?.status}`));
                }
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};
export default getStatistics;
