import React from 'react';
import styled from 'styled-components';

interface TooltipProps {
    text: string;
    position: 'top' | 'right' | 'bottom' | 'left';
    label: React.ReactNode;
    isHtml?: boolean;
}

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipText = styled.span<{ position: 'top' | 'right' | 'bottom' | 'left' }>`
    visibility: hidden;
    height: auto;
    width: 200px;
    background-color: white;
    color: var(--gray);
    text-align: center;
    border: 3px solid var(--purple);
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;

    /* Positioning logic based on props */
    ${({ position }) =>
        position === 'top' &&
        `
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    `}
    ${({ position }) =>
        position === 'right' &&
        `
        top: 50%;
        left: 100%;
        margin-left: 10px;
        transform: translateY(-50%);
    `}
    ${({ position }) =>
        position === 'bottom' &&
        `
        top: 100%;
        left: 50%;
        margin-top: 10px;
        transform: translateX(-50%);
    `}
    ${({ position }) =>
        position === 'left' &&
        `
        top: 50%;
        right: 100%;
        margin-right: 10px;
        transform: translateY(-50%);
    `}
`;

const TooltipLabel = styled.span`
    cursor: help;
    &:hover ${TooltipText} {
        visibility: visible;
    }
`;

const Tooltip: React.FC<TooltipProps> = ({ text, position, label, isHtml }) => {
    return (
        <TooltipContainer>
            <TooltipLabel>
                {label}
                <TooltipText position={position}>
                    {isHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text}
                </TooltipText>
            </TooltipLabel>
        </TooltipContainer>
    );
};

export default Tooltip;
