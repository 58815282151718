import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import useGeneralStore from '../../../../store/general';
import usePaymentValueValidation from '../../../../hooks/usePaymentValueValidation';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import moment from 'moment';
import getAllInvoices from '../getAllInvoices';
import getStatistics from '../getStatistics';

type Props = {
    t: Function;
    setOpenPaymentUpdateModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: string;
    clickedPayment: string;
    invoicePaidValue: any;
    success?: Function;
    setLoaderVisible?: Dispatch<SetStateAction<boolean>>;
    setStatistics: Dispatch<SetStateAction<ObjectKeys>>;
    formattedParams?: string;
};

const PaymentUpdateModal: FunctionComponent<Props> = ({
    t,
    setOpenPaymentUpdateModal,
    clickedInvoice,
    clickedPayment,
    invoicePaidValue,
    success,
    setLoaderVisible,
    setStatistics,
    formattedParams,
}) => {
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paidNumber, setPaidNumber] = useState(invoicePaidValue);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const isPaidValid = usePaymentValueValidation(paidNumber, invoicePaidValue, t);

    const handlePayment = (invoiceId: string, paymentId: string): void => {
        setShowValidationMessage(true);
        if (isPaidValid === '') {
            setLoaderVisible && setLoaderVisible(true);
            communication
                .updateInvoiceSpecificPayment(invoiceId, paymentId, {
                    invoiceId,
                    paymentId,
                    paid_date: moment(paymentDate.toString()).format('YYYY-MM-DD hh:mm:ss'),
                    value: paidNumber?.replace(/\./g, '')?.replace(',', '.'),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setOpenPaymentUpdateModal(false);
                        success && success();
                        return;
                    }
                })
                .then(() => {
                    getAllInvoices(formattedParams).then(() => {
                        getStatistics().then(() => {
                            setLoaderVisible && setLoaderVisible(false);
                        });
                    });
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible && setLoaderVisible(false);
                        setOpenPaymentUpdateModal(false);
                        setGlobalModal(
                            <ErrorModalWrapper>
                                <ModalText>
                                    <h4>{t('modal.error.title').text}</h4>
                                    <p>{error.response.data.message}</p>
                                </ModalText>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    size={'100%'}
                                    onClick={() => {
                                        closeGlobalModal();
                                    }}
                                >
                                    {t('buttons.ok').text}
                                </Button>
                            </ErrorModalWrapper>,
                        );
                    }
                });
        }
    };

    return (
        <PaymentUpdateModalWrapper>
            <ModalContent>
                <h4>{t('pages.invoices.paymentModal.title').text}</h4>
                <DateWrapper>
                    <Input
                        type={InputComponentType.Date}
                        label={t('pages.invoices.paymentModal.paymentDate').text}
                        date={paymentDate}
                        // validation={paidDate !== null ? isPaidDateValid : ''}
                        onChange={(value: any) => {
                            setPaymentDate(value);
                        }}
                    />
                </DateWrapper>
                <Input
                    type={InputComponentType.Amount}
                    label={t('pages.invoices.paymentModal.paid').text}
                    value={paidNumber}
                    validation={showValidationMessage || paidNumber !== '' ? isPaidValid : ''}
                    onChange={(value: string) => setPaidNumber(value)}
                />
            </ModalContent>

            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handlePayment(clickedInvoice, clickedPayment);
                    }}
                    size={'100%'}
                >
                    {t('pages.invoices.paymentModal.confirmPayment').text}
                </Button>
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    onClick={() => setOpenPaymentUpdateModal(false)}
                    size={'100%'}
                >
                    {t('pages.invoices.paymentModal.close').text}
                </Button>
            </ButtonContainer>
        </PaymentUpdateModalWrapper>
    );
};

const PaymentUpdateModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--light-gray);
    }
`;
const ModalContent = styled.div`
    padding: 35px 50px;

    h4 {
        margin-bottom: 30px;
        text-align: center;
    }
    .input {
        text-align: left;
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;
export default PaymentUpdateModal;
