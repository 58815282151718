import { useEffect, useState } from 'react';
import styled from 'styled-components';
import communication from '../../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../../components/loader';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useWindowSize from '../../../../hooks/useResize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import visaLogo from '../../../../assets/cards/Visa50.gif';
import dinaLogo from '../../../../assets/cards/dinacard50.png';
import masterLogo from '../../../../assets/cards/MasterCard50.gif';
import maestroLogo from '../../../../assets/cards/maestro50.gif';
import masterIdCheckLogo from '../../../../assets/cards/masterIdCheck.png';
import visaSecureLogo from '../../../../assets/cards/visa-secure.png';
import dinaMobile from '../../../../assets/cards/mobile/dinacard23.png';
import masterMobile from '../../../../assets/cards/mobile/MasterCard23.png';
import maestroMobile from '../../../../assets/cards/mobile/maestro23.png';
import otpLogo from '../../../../assets/cards/otpLogo.png';
import visaMobile from '../../../../assets/cards/mobile/Visa23.png';
import { formatTwoDecimals } from '../../../../functions/format';
import useEmailValidation from '../../../../hooks/useEmailValidation';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useVatNoValidation from '../../../../hooks/useVatNoValidation';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StepControlButton } from '../../../../components/stepControlButton';
import ReCAPTCHA from 'react-google-recaptcha';

interface StepThreeProps {
    cart: ObjectKeys;
    previousStep: Function;
}

const StepThree: React.FC<StepThreeProps> = ({ cart, previousStep }) => {
    const { currentLang } = useLanguageStore();

    const returnUrl = process.env.REACT_APP_RETURN_URL;
    const wspayFormUrl = process.env.REACT_APP_WSPAY_FORM_URL;
    const isDevEnviroment = process.env.REACT_APP_RETURN_URL === 'https://dev.pausal.rs' ? true : false;

    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const defaultLang = lang === 'Srpski' ? 'sr' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'sr';
    const size = useWindowSize();
    const paramPackage = useParams().package;

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(paramPackage ? 2 : 1);

    const { agency } = useAgencyStore();
    const isTrialUser = agency.user.data.trial;
    const userPackage = agency.user.data.payment_package.data;

    const [prepaidDiscount, setPrepaidDiscount] = useState(0);
    const [renderKey, setRenderKey] = useState<number>(0);

    const [loader, setLoader] = useState<boolean>(false);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);

    const [isChecked, setChecked] = useState(false);

    const [errorMessageTerms, setErrorMessageTerms] = useState<boolean>(false);
    const [errorMessageNotRobot, setErrorMessageNotRobot] = useState<boolean>(false);

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    const [recaptchaToken, setRecaptchaToken] = useState<string>('');

    const [firstName, setFirstName] = useState<string>(agency.user.data.first_name.slice(0, 50));
    const [lastName, setLastName] = useState<string>(agency.user.data.last_name.slice(0, 50));
    const [email, setEmail] = useState<string>(agency.user.data.email);
    const [pib, setPib] = useState<string>(agency.pib);

    const isEmailValid = useEmailValidation(email);
    const isFirstNameValid = useCharLengthValidation(firstName, 3, true);
    const isLastNameValid = useCharLengthValidation(lastName, 3, true);
    const isPibValid = useVatNoValidation(pib);

    const [validationError, setValidationError] = useState(false);

    const handleRecaptchaChange = (token: string | null): void => {
        if (token) {
            setRecaptchaToken(token);
        } else {
            setRecaptchaToken('');
        }
    };

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                const activePackages = response.data.data.filter((item: ObjectKeys) => item.active);
                setPackages(activePackages);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const chosenPackage = (id: string): string => {
        const packageOption = packages.find((item: ObjectKeys) => item.id === id);
        return packageOption ? packageOption.name : '';
    };

    const isDowngrade = (id: string): boolean => {
        const packageOption = packages.find((item: ObjectKeys) => item.id === id);
        return packageOption ? packageOption.monthly <= userPackage.monthly : false;
    };

    const handlePreviousStep = (): void => {
        setCurrentStep(currentStep - 1); // Move to the previous step
        previousStep();
        navigate('/settings/subscription/2');
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        if (packages.length > 0 && cart.items.length > 0) {
            if (isDowngrade(cart.items[0].id)) {
                setPrepaidDiscount(0);
                setRenderKey(renderKey + 1);
            } else {
                setPrepaidDiscount(agency.user.data.upgrade_package_data.upgrade_left_over_amount);
                setRenderKey(renderKey + 1);
            }
        }
    }, [packages]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    const addCartIdToURL = (id: string): Promise<boolean> => {
        return new Promise((resolve) => {
            const newUrl = `/settings/subscription/3/${id}`;
            navigate(newUrl);
            resolve(true);
        });
    };

    const calculatePackageDiscount = (entireCart: ObjectKeys): string => {
        const total = entireCart.items[0].price * entireCart.items[0].months;
        const packageFromCart = packages.find((item: ObjectKeys) => item.id === entireCart.items[0].id);
        const period = entireCart.items[0].months;
        const discount =
            period === 12 ? packageFromCart?.year_discount : period === 6 ? packageFromCart?.half_year_discount : 0;
        const value = total * (discount / 100);
        return formatTwoDecimals(value.toString());
    };

    const initiateWsPay = (): void => {
        // setLoader(true);
        const { total_price_discounted, ...restCart } = cart;

        const updatedItems = [...restCart.items];

        updatedItems[0] = {
            ...updatedItems[0],
            price: total_price_discounted,
        };

        const newCart = {
            ...restCart,
            items: updatedItems,
            total_price: total_price_discounted,
            is_upgrade: isTrialUser || isDowngrade(cart.items[0].id) ? false : true,
        };

        try {
            communication.createCart(newCart).then((res: ObjectKeys) => {
                const amount = cart.total_price_discounted;
                // const amount = 11.3;
                const formattedAmount = amount.toFixed(2).replace('.', ',');
                const cart_id = res.data.data.id;
                addCartIdToURL(cart_id).then((added: boolean) => {
                    if (!added) {
                        setLoader(false);
                        return;
                    } else {
                        communication.getSignature(formattedAmount, cart_id).then((response: ObjectKeys) => {
                            const signature = response.data.signature;
                            const shop_id = response.data.shop_id;
                            const payForm = document.querySelector('form[name="pay"]') as HTMLFormElement;
                            if (payForm && signature) {
                                const signatureInput = payForm.querySelector('input[name="Signature"]');
                                const shopIdInput = payForm.querySelector('input[name="ShopID"]');
                                const shoppingCartIdInput = payForm.querySelector('input[name="ShoppingCartID"]');
                                const totalAmountInput = payForm.querySelector('input[name="TotalAmount"]');
                                if (
                                    signatureInput instanceof HTMLInputElement &&
                                    shopIdInput instanceof HTMLInputElement &&
                                    shoppingCartIdInput instanceof HTMLInputElement &&
                                    totalAmountInput instanceof HTMLInputElement
                                ) {
                                    signatureInput.value = signature;
                                    shopIdInput.value = shop_id;
                                    shoppingCartIdInput.value = cart_id;
                                    totalAmountInput.value = formatTwoDecimals(formattedAmount.toString());
                                    payForm.submit();
                                } else {
                                    console.error('Signature input element not found or not an HTMLInputElement');
                                }
                            }
                        });
                    }
                });
            });
        } catch (error: any) {
            console.error('Error:', error);
            setLoader(false);
            toast.error(error.message);
        }
    };

    const openInNewTab = (link: string): void => {
        const baseUrl = window.location.origin;
        const url = new URL(link, baseUrl);
        window.open(url.href, '_blank');
    };

    return (
        <>
            <ToastContainer />
            {loader && <Loader />}
            <>
                <ContentThirdStep>
                    <div className="leftMain">
                        <div className="leftPage">
                            <div className="inputFields">
                                <div>
                                    <label>{t('pages.agency.personalInfo.name').text}</label>
                                    <input
                                        type="text"
                                        defaultValue={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }}
                                        readOnly
                                    />
                                    {validationError && (
                                        <p className="error2">{isFirstNameValid ? isFirstNameValid : ''}</p>
                                    )}
                                </div>
                                <div>
                                    <label>{t('pages.agency.personalInfo.lastName').text}</label>
                                    <input
                                        type="text"
                                        defaultValue={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }}
                                        readOnly
                                    />
                                    {validationError && (
                                        <p className="error2">{isLastNameValid ? isLastNameValid : ''}</p>
                                    )}
                                </div>
                                <div>
                                    <label>{t('pages.agency.personalInfo.email').text}</label>
                                    <input
                                        type="text"
                                        defaultValue={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        readOnly
                                    />
                                    {validationError && <p className="error2">{isEmailValid ? isEmailValid : ''}</p>}
                                </div>
                                <div>
                                    <label>{t('pages.agency.subscription.pib').text}</label>
                                    <input
                                        type="text"
                                        maxLength={9}
                                        inputMode="numeric"
                                        pattern="\d*" // Allow only numbers
                                        value={pib}
                                        onChange={(e) => {
                                            const value: string = e.target.value.replace(/\D/g, '').slice(0, 9);
                                            setPib(value);
                                        }}
                                        readOnly
                                    />
                                    {validationError && <p className="error2">{isPibValid ? isPibValid : ''}</p>}
                                </div>
                            </div>
                        </div>
                        {!isMobileWidth && <StepControlButton step="previous" handleClick={handlePreviousStep} />}
                    </div>

                    <div className="rightPage">
                        <div className="title">
                            <h2>{t('pages.agency.subscription.chosenPackage').text}</h2>
                            {cart.items[0] && (
                                <h3>
                                    {packages.length > 0 && cart.items.length > 0 ? (
                                        <>{chosenPackage(cart.items[0].id)}/</>
                                    ) : null}
                                    {cart.items[0].months === 1
                                        ? t('pages.agency.subscription.one_month').text
                                        : cart.items[0].months === 6
                                        ? t('pages.agency.subscription.half_year').text
                                        : t('pages.agency.subscription.one_year').text}
                                </h3>
                            )}
                        </div>
                        <div className="table">
                            <div>
                                <p>{t('pages.agency.subscription.price').text}</p>
                                <p>{formatTwoDecimals((cart.items[0].price * cart.items[0].months).toString())} RSD</p>
                            </div>
                            <div>
                                <p>{t('pages.agency.subscription.discount').text}</p>
                                <p>{calculatePackageDiscount(cart)} RSD</p>
                            </div>
                            <div>
                                <p>{t('pages.agency.subscription.paid').text}</p>
                                <p>{formatTwoDecimals(prepaidDiscount.toString())} RSD</p>
                            </div>
                        </div>
                        <div className="total">
                            <p>{t('pages.agency.subscription.total').text}</p>
                            {cart.total_price_discounted ? (
                                <p>{formatTwoDecimals(cart.total_price_discounted.toString())} RSD</p>
                            ) : null}
                        </div>
                        <Row>
                            <div className="checkbox">
                                <FontAwesomeIcon
                                    icon={isChecked ? faCheckSquare : faSquare}
                                    className={`check-icon ${isChecked ? 'checked' : ''}`}
                                    onClick={() => setChecked(!isChecked)}
                                />
                                <div className="terms">
                                    <span>{t('pages.agency.subscription.introduced').text}</span>
                                    <span onClick={() => openInNewTab('Privacy.pdf')} className="link">
                                        {t('pages.agency.subscription.privacy').text},
                                    </span>
                                    <span onClick={() => openInNewTab('Terms.pdf')} className="link">
                                        {t('pages.agency.subscription.terms').text}
                                    </span>
                                    <span>{t('pages.agency.subscription.introducedAnd').text}</span>
                                    <span onClick={() => openInNewTab('Payment_terms.pdf')} className="link">
                                        {t('pages.agency.subscription.cardPayment').text}
                                    </span>
                                </div>
                            </div>
                        </Row>
                        {!isDevEnviroment ? (
                            <div className="recaptcha">
                                <ReCAPTCHA
                                    sitekey="6LfMDIwpAAAAAEu8oskJGaSeDcqvcAqFRdXqDUGs"
                                    onChange={handleRecaptchaChange}
                                    hl={defaultLang}
                                    className="g-recaptcha"
                                />
                            </div>
                        ) : null}

                        <div style={{ marginTop: '30px' }}>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                className="big no-border-radius"
                                size={200}
                                disabled={loader}
                                onClick={() => {
                                    if (!isChecked) {
                                        setErrorMessageTerms(true);
                                        return;
                                    } else if (!recaptchaToken && !isDevEnviroment) {
                                        setErrorMessageNotRobot(true);
                                        return;
                                    } else {
                                        setLoader(true);
                                        setErrorMessageTerms(false);
                                        setErrorMessageNotRobot(false);
                                        initiateWsPay();
                                    }
                                }}
                            >
                                {t('pages.agency.subscription.card_pay').text}
                            </Button>
                        </div>
                        {errorMessageTerms ? (
                            <p className="error">{t('pages.agency.subscription.error_terms').text}</p>
                        ) : null}
                        {errorMessageNotRobot ? (
                            <p className="error">{t('pages.agency.subscription.error_not_robot').text}</p>
                        ) : null}

                        <div>
                            <form name="pay" action={wspayFormUrl} method="POST">
                                <input type="hidden" name="ShopID" value="" />
                                <input type="hidden" name="ShoppingCartID" value="" />
                                <input type="hidden" name="Version" value="2.0" />
                                <input type="hidden" name="TotalAmount" value="" />
                                <input type="hidden" name="Signature" value="" />
                                {/* <input type="hidden" name="ReturnURL" value="http://localhost:3000/payment-success" />
                                <input type="hidden" name="CancelURL" value="http://localhost:3000/payment-cancel" />
                                <input
                                    type="hidden"
                                    name="ReturnErrorURL"
                                    value="http://localhost:3000/payment-error"
                                /> */}
                                <input type="hidden" name="ReturnURL" value={`${returnUrl}/payment-success`} />
                                <input type="hidden" name="CancelURL" value={`${returnUrl}/payment-cancel`} />
                                <input type="hidden" name="ReturnErrorURL" value={`${returnUrl}/payment-error`} />
                                <input type="hidden" name="Lang" value={defaultLang} />
                                <input type="hidden" name="CustomerFirstName" value={firstName} />
                                <input type="hidden" name="CustomerLastName" value={lastName} />
                                {/* <input type="hidden" name="CustomerEmail" value={email} /> */}
                                {/* <input type="hidden" name="CustomerAddress" value={`${agency.user.data.address}`} />
                                <input type="hidden" name="CustomerCity" value={agency.user.data.city} />
                                <input type="hidden" name="CustomerZIP" value="" />
                                <input type="hidden" name="CustomerCountry" value="SR" /> */}
                                <input type="hidden" name="PaymentPlan" value="0000" />
                                <input type="hidden" name="IsTokenRequest" value="1" />
                                {/* <input type="hidden" name="CreditCardName" value="VISA" />
                            <input type="hidden" name="IntAmount" value="750,00" />
                            <input type="hidden" name="IntCurrency" value="RSD" /> */}
                                {/* <input type="submit" value="Pay" /> */}
                            </form>
                        </div>
                        <div className="paymentIcons">
                            {isMobileWidth ? (
                                <>
                                    <Cards>
                                        <img src={visaMobile} className="mobile" />
                                        <img src={masterMobile} className="mobile" />
                                        <img src={maestroMobile} className="mobile" />
                                        <img src={dinaMobile} className="mobile" />
                                    </Cards>
                                </>
                            ) : (
                                <>
                                    <Cards>
                                        <img src={visaLogo} />
                                        <img src={masterLogo} />
                                        <img src={maestroLogo} />
                                        <img src={dinaLogo} />
                                    </Cards>
                                </>
                            )}
                            <WSpay>
                                <a
                                    href="http://www.wspay.rs"
                                    title="Monri WSpay - Web Secure Payment Gateway"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="Monri WSpay - Web Secure Payment Gateway"
                                        src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png"
                                    />
                                </a>
                                <a href="https://www.otpbanka.rs/" title="OTP banka" target="_blank" rel="noreferrer">
                                    <img alt="OTP Banka" src={otpLogo} className="otp-logo" />
                                </a>
                            </WSpay>
                            <CardSecure>
                                <a
                                    href="http://www.mastercard.com/rs/consumer/credit-cards.html"
                                    title="Master Card ID Check"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={masterIdCheckLogo} />
                                </a>
                                <a
                                    href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                                    title="Visa Secure"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={visaSecureLogo} />
                                </a>
                            </CardSecure>
                        </div>
                        {isMobileWidth && (
                            <div style={{ marginTop: '30px' }}>
                                <StepControlButton step="previous" handleClick={handlePreviousStep} />
                            </div>
                        )}
                    </div>
                </ContentThirdStep>
            </>
        </>
    );
};

const ContentThirdStep = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0;
    }
    .leftMain {
        width: 100%;
        max-width: 50%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        height: fit-content;
        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
    }
    .leftPage {
        width: 100%;
        background-color: var(--purple);
        padding: 20px;
        margin-bottom: 2rem;
        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0rem;
        }
        .inputFields {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            label {
                color: var(--white);
                font-size: 15px;
            }
            input {
                width: 100%;
                height: 50px;
                padding: 10px;
                margin-top: 5px;
                border: none;
            }
        }
    }
    .rightPage {
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        color: var(--purple);
        padding: 20px 0;
        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
        .title {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h2 {
                font-size: 15px;
                color: var(--purple);
                margin: 10px;
            }
            h3 {
                font-size: 15px;
            }
        }
        .table {
            width: 100%;
            padding: 20px 0;
            border-top: 1px solid var(--purple);
            border-bottom: 1px solid var(--purple);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin-top: 20px;

            div {
                display: flex;
                justify-content: space-between;
                width: 100%;
                p {
                    font-size: 15px;
                }
            }
        }
        .total {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
            @media only screen and (max-width: 768px) {
                padding: 20px 0 20px 0;
            }
            p {
                font-size: 15px;
            }
        }
        .paymentIcons {
            width: 100%;
            padding: 20px;
            @media only screen and (max-width: 768px) {
                padding: 0;
            }
        }
        .recaptcha {
            margin-left: 20px;
            @media only screen and (max-width: 768px) {
                margin: 0;
                .g-recaptcha {
                    transform: scale(0.75);
                    // transform-origin: 0 0;
                }
            }
        }
        .widget {
            margin-top: 20px;
            background-color: red;
            width: 120%;
        }
    }
    input[type='radio'] {
        accent-color: #9b7fe9;
        height: 25px;
        width: 20px;
        margin-bottom: 10px;
    }
    .note {
        margin-top: 25px;
        font-size: 14px;
        color: black;
        text-align: center;
        line-height: 20px;
        border-radius: 30px;
        padding: 5px;
        max-width: 520px;
    }
    .error {
        color: red;
        text-align: center;
        margin-top: 10px;
    }
    .error2 {
        color: red;
        margin-top: 5px;
        font-size: 14px;
        max-width: 170px;
    }
    .no-border-radius {
        border-radius: 0;
    }
`;
const Cards = styled.div`
    width: 100%;
    text-align: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 5px;
    .mobile {
        height: 23px;
    }
    @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const CardSecure = styled.div`
    width: 100%;
    text-align: center;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 5px;
    .mobile {
        height: 23px;
    }
    @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const WSpay = styled.div`
    display: flex;
    text-align: center;
    margin-top: 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
    .otp-logo {
        height: 50px;
        margin-left: 10px;
        @media only screen and (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
`;
const Row = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    .checkbox {
        padding: 20px 0;
        display: flex;
        align-items: center;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 25px;
            color: var(--white);
            cursor: pointer;
        }
        .check-icon.checked {
            background-color: var(--purple);
            cursor: pointer;
        }
        .terms {
            margin-left: 10px;
            font-size: 15px;
            color: var(--purple);
        }
    }
    .link {
        cursor: pointer;
        text-decoration: underline;
    }
`;
export default StepThree;
