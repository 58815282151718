import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useWindowSize from '../../../hooks/useResize';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAgencyStore from '../../../store/agency';
import Modal from '../../../components/modal';
import NoItems from '../../../components/noItems';
import Button, { ButtonVariant } from '../../../components/button';
import SelectComponent from '../../../components/select';
import Search from '../../../components/input/search';
import Pagination from '../../../components/pagination';
import Loader from '../../../components/loader';
import Sidebar from '../../../components/sideModal';
import Success from '../../../components/successModal';
import ArchiveModal from './archiveModal';
import EditArticleModal from './editArticleModal';
import AddArticleModal from './addArticleModal';
import Warning from '../../../components/successModal';
import ArticleCard from './articleCard';
import { getAllMeasurementUnits } from '../common/measurementUnits';
import useUnitsStore from '../../../store/measurementUnits';
import { getUnitByValue } from '../common/measurementUnits';

const ListOfArticles: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const size = useWindowSize();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const articleTypeOptions = [
        { label: t('pages.editInvoice.addServiceModal.product').text, value: 'product' },
        { label: t('pages.editInvoice.addServiceModal.service').text, value: 'service' },
    ];
    const [measurementUnits, setMeasurementUnits] = useState<Array<{ value: string; label: string }>>([]);
    const [successModal, setSuccessModal] = useState(false);
    const [addArticleDropdown, setAddArticleDropdown] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [clickedArticle, setClickedArticle] = useState<ObjectKeys>({});
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const [articles, setArticles] = useState<ObjectKeys>({});
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 500);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 768);

    const [warningModal, setWarningModal] = useState(false);

    const [showSearch, setShowSearch] = useState(false);
    const [searchWord, setSearchWord] = useState('');

    const { units } = useUnitsStore();

    const tabs = [
        t('pages.listOfArticles.tabs.articlesList').text,
        t('pages.listOfArticles.tabs.archivedArticles').text,
    ];

    const mobileTabs: any = [
        { value: 0, label: t('pages.listOfArticles.tabs.articlesList').text },
        { value: 1, label: t('pages.listOfArticles.tabs.archivedArticles').text },
    ];

    const [searchValue, setSearchValue] = useState('');

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: '',
        searchFields: 'name:=;',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });

    const getArticles = (): void => {
        setLoaderVisible(true);
        if (tabIndex === 0) {
            communication
                .getAllArticles({
                    ...params,
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        const newUnits = res?.data.data
                            .map((item: ObjectKeys) => item.default_measurement_unit)
                            .filter((unit: any) => !units.includes(unit));

                        useUnitsStore.setState({ units: [...units, ...newUnits] });
                        setLoaderVisible(false);
                        setArticles(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setArticles([]);
                    }
                });
        } else if (tabIndex === 1)
            communication
                .getArchivedArticles({
                    ...params,
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setLoaderVisible(false);
                        setArticles(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setArticles([]);
                    }
                });
    };

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };

    //Client Change
    const handleChange = (service: ObjectKeys): void => {
        setClickedArticle(service);
        setEditClientModal(true);
    };

    //Client Archive Modal - not archived clients
    const handleArchiveModal = (service: ObjectKeys): void => {
        setGlobalModal(
            <ArchiveModal
                t={t}
                clickedService={service}
                closeGlobalModal={closeGlobalModal}
                setClients={setArticles}
                tabIndex={tabIndex}
                success={() => {
                    getArticles();
                }}
            />,
        );
    };

    const handleActivate = (serviceId: string): void => {
        setLoaderVisible(true);
        communication
            .activateArticle(serviceId)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setSuccessModal(true);
                }
            })
            .then(() => {
                if (tabIndex === 1) {
                    communication
                        .getArchivedArticles({
                            ...params,
                            search: '',
                        })
                        .then((res: ObjectKeys) => {
                            if (res.status === 200) {
                                setLoaderVisible(false);
                                setArticles(res?.data);
                            }
                        });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };

    const handleClickArticle = (service: ObjectKeys): void => {
        setClickedArticle(service);
    };

    useEffect(() => {
        getArticles();
        if (tabIndex === 1) setAddArticleDropdown(false);
    }, [params, tabIndex]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 500);
        setIsTabletWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        const unitMeasurementData = getAllMeasurementUnits(t, 'domestic');
        setMeasurementUnits(unitMeasurementData as { value: string; label: string }[]);
    }, []);

    useEffect(() => {
        setMeasurementUnits(() => {
            const updatedMeasurementUnits: { value: string; label: string }[] = [];
            units.forEach((item) => {
                const label = getUnitByValue(item, false, false);
                if (label !== null) {
                    updatedMeasurementUnits.push({ value: item, label: label });
                }
            });
            return updatedMeasurementUnits;
        });
    }, [units]);

    useEffect(() => {
        if (searchWord !== '') {
            setParams({
                ...params,
                search: `name:${searchWord}`,
                page: 1,
                searchFields: 'name:like',
            });
        } else {
            setParams({
                ...params,
                search: '',
                page: 1,
                searchFields: 'name:=;',
            });
        }
    }, [searchWord]);

    return (
        <>
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success
                        close={() => setSuccessModal(false)}
                        message={t('pages.listOfArticles.successActivate').text}
                    />
                </Modal>
            )}
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        {/* <WarningArticleCard clients={clientsDuplicated} /> */}
                    </Warning>
                </Modal>
            )}
            {editClientModal && clickedArticle && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditArticleModal
                        t={t}
                        clickedArticle={clickedArticle}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setArticles}
                        tabIndex={tabIndex}
                        success={() => {
                            getArticles();
                        }}
                        measurementUnits={measurementUnits}
                        articleTypeOptions={articleTypeOptions}
                    />
                </Sidebar>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="articles page">
                <Header>
                    <div>
                        <h1>{t('pages.listOfArticles.title').text}</h1>
                    </div>
                    <AddButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            icon={!isMobileWidth ? true : false}
                            size={'auto'}
                            onClick={() => {
                                setAddArticleDropdown((prev) => !prev);
                                setTabIndex(0);
                            }}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                            {!isMobileWidth && t('pages.listOfArticles.addArticle').text}
                        </Button>
                    </AddButtonContainer>
                </Header>

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabsItems>
                        <TabList>
                            {isTabletWidth ? (
                                <div style={{ marginTop: '10px' }}>
                                    <SelectComponent
                                        className="select-container tabs-select"
                                        optionList={mobileTabs}
                                        handleSelectedValue={(value: any) => setTabIndex(value)}
                                        defaultSelectedOption={tabs[0]}
                                        placeholder={t('pages.transfers.choose').text}
                                    />
                                </div>
                            ) : (
                                <>
                                    {tabs?.map((tab, index) => (
                                        <Tab key={index}>{tab}</Tab>
                                    ))}
                                </>
                            )}
                        </TabList>
                    </TabsItems>
                </Tabs>
                <Filters>
                    <ShowSelect>
                        <SelectComponent
                            optionList={showItems}
                            label={t('pages.listOfArticles.filters.show.label').text}
                            handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                            defaultSelectedOption={showItems[0]}
                        />
                        <p>{t('pages.listOfArticles.filters.show.articles').text}</p>
                    </ShowSelect>
                    <Search
                        placeholder={t('pages.invoices.filters.search').text}
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            setShowSearch(true);
                        }}
                        onLeaveOrEnter={(value: string) => {
                            setSearchWord(value);
                            setShowSearch(false);
                            setSearchValue(value);
                        }}
                        showSearchNote={showSearch}
                        className="search-wrapper"
                    />
                </Filters>
                {addArticleDropdown && (
                    <Sidebar close={() => setAddArticleDropdown(false)}>
                        <AddArticleModal
                            t={t}
                            setEditClientModal={setAddArticleDropdown}
                            success={() => {
                                setAddArticleDropdown(false);
                                getArticles();
                            }}
                            type={'service'}
                            measurementUnits={measurementUnits}
                        />
                    </Sidebar>
                )}

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    {tabs?.map((tab: string, index: number) => (
                        <TabPanel key={index} className={tab}>
                            {articles?.data?.length > 0 ? (
                                articles?.data?.map((service: ObjectKeys) => {
                                    return (
                                        <div
                                            key={service.id}
                                            onClick={() => {
                                                tabIndex === 0 && handleChange(service);
                                            }}
                                        >
                                            {tabIndex === 0 ? (
                                                <ArticleCard
                                                    cardData={service}
                                                    cardType={'sd'}
                                                    handleChange={() => handleChange(service)}
                                                    handleDelete={(
                                                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    ) => {
                                                        handleArchiveModal(service);
                                                        e.stopPropagation();
                                                    }}
                                                    handleClick={() => handleClickArticle(service)}
                                                />
                                            ) : (
                                                <ArticleCard
                                                    cardData={service}
                                                    cardType={'archived'}
                                                    handleActivate={(
                                                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    ) => {
                                                        handleActivate(service?.id);
                                                        e.stopPropagation();
                                                    }}
                                                    handleClick={() => {
                                                        console.log('test');
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })
                            ) : tabIndex === 0 ? (
                                <NoItems text={t('pages.listOfArticles.noArticles').text} />
                            ) : (
                                <NoItems text={t('pages.listOfArticles.noAchivedArticles').text} />
                            )}
                        </TabPanel>
                    ))}
                </Tabs>

                {articles?.data?.length > 0 && articles?.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={articles?.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            articles?.meta?.pagination?.current_page === articles?.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${articles?.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={articles?.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    button {
        margin-left: 35px;
        height: 35px;
        font-size: 12px;
        line-height: initial;
    }
    > div {
        display: flex;
        align-items: center;
    }
    @media only screen and (max-width: 500px) {
        justify-content: space-between;
    }
`;
const AddButtonContainer = styled.div`
    position: relative;
`;

const TabsItems = styled.div`
    display: flex;
    align-items: center;
    background: var(--purple);
    color: var(--white);
    text-transform: uppercase;
    padding: 10px 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 860px) {
        display: flex;
        flex-direction: column;
    }
    > p {
        margin-right: 6px;
        font-size: 14px;
    }
    .react-tabs__tab-list {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        .react-tabs__tab {
            padding: 11px 20px;
            font-size: 14px;
            border-bottom: 3px solid transparent;
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                border-color: var(--white);
                color: var(--white);
                border-radius: 0;
                border-bottom: 3px solid var(--white);
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
`;
const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
        height: 42px;
    }
`;
const ShowSelect = styled.div`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: var(--gray);
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: var(--gray);
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
export default ListOfArticles;
