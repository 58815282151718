import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import CityPhoto from '../../assets/city.png';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import Button, { ButtonVariant } from '../../components/button';
import PayModal from './payModal';
import Modal from '../../components/modal';
import useAgencyStore from '../../store/agency';

const IndependenceTest: FunctionComponent = () => {
    const [payModal, setPayModal] = useState<boolean>(false);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const isTrial = agency.user.data.trial;
    const beginingHtml = t('pages.independenceTest.begining').text;
    const list1Html = t('pages.independenceTest.list1').text;
    const list2Html = t('pages.independenceTest.list2').text;
    const paragraph1Html = t('pages.independenceTest.paragraph1').text;
    const paragraph2Html = t('pages.independenceTest.paragraph2').text;
    const trialHtml = t('pages.independenceTest.trial').text;
    const noTrialHtml = t('pages.independenceTest.noTrial').text;

    return (
        <Container>
            <HeaderContainer>
                <Header />
            </HeaderContainer>
            <Content>
                <h1>{t('pages.independenceTest.title').text}</h1>
                <p dangerouslySetInnerHTML={{ __html: beginingHtml }} />
                <p className="list" dangerouslySetInnerHTML={{ __html: list1Html }} />
                <p className="list" dangerouslySetInnerHTML={{ __html: list2Html }} />
                <p dangerouslySetInnerHTML={{ __html: paragraph1Html }} />
                <p dangerouslySetInnerHTML={{ __html: paragraph2Html }} />
                {isTrial ? (
                    <p dangerouslySetInnerHTML={{ __html: trialHtml }} />
                ) : (
                    <p dangerouslySetInnerHTML={{ __html: noTrialHtml }} />
                )}
                <div className="button-div">
                    <Button
                        variant={ButtonVariant.solid}
                        color={'var(--purple)'}
                        size={200}
                        onClick={() => setPayModal(true)}
                    >
                        {t('pages.independenceTest.button').text}
                    </Button>
                </div>
            </Content>
            <Modal
                modalVisible={payModal}
                closeModal={() => setPayModal(false)}
                size={'80px'}
                className={'preview-modal'}
            >
                <PayModal onClose={() => setPayModal(false)} action={'preview'} />
            </Modal>
        </Container>
    );
};
export default IndependenceTest;

const Container = styled.div`
    h1 {
        font-size: 32px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 30px;
    }
    span {
        padding-left: 25px;
    }
    .list {
        padding-left: 30px;
    }
    .button-div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
        padding-top: 30px;
    }
    background: var(--white);
    @media screen and (max-width: 768px) {
        background: var(--body-gray);
    }
    .select__control {
        background: var(--light-gray);
    }
    .preview-modal {
        .modal-wrapper {
            width: 850px;
            padding: 15px;
            overflow: auto;
            max-height: 76vh;
        }
    }
`;

const Content = styled.div`
    margin-left: 6%;
    margin-right: 6%;
    background: var(--white);
    @media screen and (max-width: 768px) {
        background: var(--body-gray);
    }
    .select__control {
        background: var(--light-gray);
    }
    .button-div {
        text-align: center;
    }
`;

const HeaderContainer = styled.div`
    background: var(--body-gray);

    @media only screen and (max-width: 1079px) {
        margin-top: 100px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

const Header = styled.div`
    height: 160px;
    background: url(${CityPhoto}) bottom no-repeat;
    background-size: 100% auto;
    border-bottom: 2px solid var(--border-color);
    position: relative;
    text-align: center;
    @media screen and (max-width: 768px) {
        height: 60px;
    }
`;
