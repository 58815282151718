import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';
import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../types/objectKeys';

const AuthorizationTransfer: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const currentLangName = localStorage.getItem('language');
    const t = useTranslations(currentLang);
    const authorised = useAgencyStore((props) => props.agency.user.data.authorised);
    const request_sent = useAgencyStore((props) => props.agency.user.data.authorised_by_user);
    const pib = useAgencyStore((props) => props.agency.pib);
    const userID = useAgencyStore((props) => props.agency.user.data.id);

    const hasAuthorization =
        currentLangName === 'Srpski'
            ? `Za tvoju paušalnu agenciju, PIB ${pib}, već imamo ovlašćenje na portalu ePorezi i portalu LPA. Ne zaboravi, ukoliko ti je potrebna usluga provere stanja plaćenih poreza, podnošenje prijave za eko taksu ili preuzimanje poreskog rešenja usluge možeš naručiti direktno iz aplikacije u odeljku “Pausal usluge”`
            : currentLangName === 'English'
            ? `For your agency, VAT number ${pib} we already have authorization on the ePorezi portal and the LPA portal. Don't forget, if you need the service of checking the status of paid taxes, submitting an application for an eco tax, or downloading a tax resolution, you can order the services directly from the application in the 'Pausal usluge' section.`
            : currentLangName === 'Русский'
            ? `Для вашего единого агентства, ИНН ${pib}, уже есть авторизация на портале ePorezi и портале LPA. Не забудь, если тебе нужна услуга проверки состояния уплаченных налогов, подачи заявки на экологический налог или загрузки налогового решения, ты можешь заказать эти услуги непосредственно из приложения в разделе «Pausal usluge».`
            : '';

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const update = async (): Promise<void> => {
        try {
            setLoaderVisible(true);
            communication
                .authorizeUser({ authorised_by_user: true }, userID)
                .then(() => {
                    setLoaderVisible(false);
                    setSuccessfullySaved(true);
                    communication.getAgency().then((response: ObjectKeys) => {
                        useAgencyStore.setState({
                            agency: response?.data?.data,
                        });
                    });
                })
                .catch((error: any) => {
                    setLoaderVisible(false);
                    console.error('error', error);
                });
        } catch (error) {
            setLoaderVisible(false);
            console.error('error', error);
        }
        useChangeModalStore.setState({ changeModalVisible: false });
    };

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.authorizationTransfer.success').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {!authorised ? (
                <>
                    {request_sent ? (
                        <Content>
                            <div className="center">
                                <div className="lang-selector">
                                    {t('pages.agency.authorizationTransfer.success').text}
                                </div>
                                <div className="button-container">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        className="small"
                                        size={200}
                                        onClick={update}
                                    >
                                        {t('pages.agency.authorizationTransfer.repeat_mail').text}
                                    </Button>
                                </div>
                            </div>
                        </Content>
                    ) : (
                        <Content>
                            <div className="center">
                                <div className="lang-selector">
                                    {t('pages.agency.authorizationTransfer.description').text}
                                </div>
                                <div className="button-container">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        className="small"
                                        size={200}
                                        onClick={update}
                                    >
                                        {t('pages.agency.authorizationTransfer.button').text}
                                    </Button>
                                </div>
                            </div>
                        </Content>
                    )}
                </>
            ) : (
                <Content>
                    <div className="center">
                        <div className="lang-selector">{hasAuthorization}</div>
                    </div>
                </Content>
            )}
        </>
    );
};
export default AuthorizationTransfer;

const Content = styled.div`
    text-align: center;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }

    p {
        font-size: 15px;
        color: var(--gray);
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 2px;
        border: 0;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }

    @media screen and (min-width: 1580px) {
        width: 59%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
