import communication from '../../../communication';
import useInvoicesStore from '../../../store/invoices';
import useParamsStore from '../../../store/params';

const getAllAInvoices = async (): Promise<boolean> => {
    const page = useParamsStore.getState().a_page;
    try {
        const res = await communication.getInvoices({
            limit: '10',
            page: page ? page : 1,
            search: 'is_prepaid:1;',
            searchFields: 'is_prepaid:=;',
            searchJoin: 'and',
        });

        if (res.status === 200) {
            useInvoicesStore.setState({ advanceInvoiceData: res.data });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
};

export default getAllAInvoices;
