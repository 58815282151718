import { FunctionComponent, useEffect, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useTranslations from '../../../../hooks/useTranslation';
import useUserStore from '../../../../store/user';
import useLanguageStore from '../../../../store/language';
import useGeneralStore from '../../../../store/general';
import SelectSearch from '../../../../components/selectSearch';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import ToggleSwitch from '../../../../components/input/toggleSwitch';
import { ObjectKeys } from '../../../../types/objectKeys';
import communication from '../../../../communication';
import Success from '../../../../components/successModal';
import ConfirmModal from '../../../../components/confirmModal';
import getUser from '../getUser';
import getAllUsers from '../getAllUsers';
import useEmailValidation from '../../../../pages/pausal/common/useEmailValidation';
import useNumLengthValidation from '../../../../hooks/useNumLengthValidation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCountriesStore from '../../../../store/countries';
import Loader from '../../../../components/loader';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};
interface Props {
    selectedUserInfo: ObjectKeys;
    setSelectedUserInfo: Dispatch<SetStateAction<ObjectKeys>>;
    setAllUsersOptions: Dispatch<SetStateAction<Array<{ value: string; label: string }>>>;
}
const ActionsForm: FunctionComponent<Props> = ({ selectedUserInfo, setSelectedUserInfo, setAllUsersOptions }) => {
    const { municipalities } = useUserStore();
    const { currentLang } = useLanguageStore();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const t = useTranslations(currentLang);

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const { countries } = useCountriesStore();
    const [municipalitiesOptions, setMunicipalitiesOptions] = useState<SelectOption[]>([]);
    const [citizenshipOptions, setCitizenshipOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [genderOptions, setGenderOptions] = useState<Array<{ value: string; label: string }>>([]);

    const [formData, setFormData] = useState<any>({
        name: '',
        lastName: '',
        middleName: '',
        country_id: '',
        personalId: '',
        personalQualifications: '',
        gender: '',
        city: '',
        municipalityId: '',
        address: '',
        addressNumber: '',
        email: '',
        confirmedAccount: false,
        activeAccount: false,
    });
    const isEmailValid = useEmailValidation(formData.email, []);
    const isJmbgValid = useNumLengthValidation(formData.personalId, 13);
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        //Get municipalities
        setMunicipalitiesOptions(
            municipalities?.map((m: any) => {
                return { value: m?.id, label: m?.name };
            }),
        );
    }, []);

    useEffect(() => {
        setCitizenshipOptions(
            countries.map((country) => {
                return {
                    value: country.id,
                    label: country.name,
                };
            }),
        );
        setGenderOptions([
            { value: 'male', label: t('pages.admin.users.tabsContent.actionsForm.male').text },
            { value: 'female', label: t('pages.admin.users.tabsContent.actionsForm.female').text },
        ]);
    }, [currentLang]);

    useEffect(() => {
        if (selectedUserInfo) {
            setFormData({
                name: selectedUserInfo?.first_name ? selectedUserInfo?.first_name : '',
                lastName: selectedUserInfo?.last_name ? selectedUserInfo?.last_name : '',
                middleName: selectedUserInfo?.middle_name ? selectedUserInfo?.middle_name : '',
                country_id: selectedUserInfo?.country?.data ? selectedUserInfo?.country.data.id : '',
                personalId: selectedUserInfo?.jmbg ? selectedUserInfo?.jmbg : '',
                personalQualifications: selectedUserInfo?.education ? selectedUserInfo?.education : '',
                gender: selectedUserInfo?.gender ? selectedUserInfo?.gender : '',
                city: selectedUserInfo?.city ? selectedUserInfo?.city : '',
                municipalityId: selectedUserInfo?.municipality_id ? selectedUserInfo?.municipality_id : '',
                address: selectedUserInfo?.address ? selectedUserInfo?.address : '',
                addressNumber: selectedUserInfo?.street_number ? selectedUserInfo?.street_number : '',
                email: selectedUserInfo?.email ? selectedUserInfo?.email : '',
                confirmedAccount: selectedUserInfo?.confirmed ? selectedUserInfo?.confirmed : false,
                activeAccount: selectedUserInfo?.active === 1 ? true : false,
            });
        }
    }, [selectedUserInfo]);

    const handleSave = (): void => {
        if (isEmailValid === '' && (formData?.personalId?.length === 13 || formData?.personalId === '')) {
            setShowError(false);
            handleSaveUserData();
        } else {
            setShowError(true);
        }
    };
    //Save user data
    const handleSaveUserData = (): void => {
        const dataToSend = {
            ...selectedUserInfo,
            first_name: formData?.name,
            last_name: formData?.lastName,
            middle_name: formData?.middleName,
            country_id: formData?.country_id ? formData?.country_id : null,
            jmbg: formData?.personalId,
            education: formData?.personalQualifications,
            gender: formData?.gender ? formData?.gender : null,
            city: formData?.city,
            municipality_id: formData?.municipalityId ? formData?.municipalityId : null,
            address: formData?.address,
            street_number: formData?.addressNumber,
            email: formData?.email,
            confirmed: formData?.confirmedAccount,
            active: formData?.activeAccount === true ? 1 : 0,
        };
        communication
            .editUser(selectedUserInfo?.id, dataToSend)
            .then((res: ObjectKeys) => {
                if (res) {
                    setGlobalModal(
                        <Success
                            message={t('pages.admin.users.tabsContent.actionsForm.saveModal.message').text}
                            close={() => closeGlobalModal()}
                        />,
                    );
                }
            })
            .then(() => {
                getAllUsers(setAllUsersOptions);
            })
            .then(() => {
                getUser(selectedUserInfo?.id, setSelectedUserInfo);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    toast.error(t('pages.tryAgain.message').text);
                }
            });
    };

    // Delete user
    const handleUser = (): void => {
        setLoaderVisible(true);
        communication
            .deleteUser(selectedUserInfo?.id)
            .then((res: ObjectKeys) => {
                if (res) {
                    toast.success(res.data.message);
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                if (error) {
                    toast.error(t('pages.tryAgain.message').text);
                }
            })
            .then(() => {
                getAllUsers(setAllUsersOptions).then((data: any) => {
                    if (data) {
                        useUserStore.setState({ userBOid: data[0].id });
                    }
                });
                setLoaderVisible(false);
            });
    };

    // Activate account
    const handleActivateModal = (): void => {
        setGlobalModal(
            <ConfirmModal
                close={() => closeGlobalModal()}
                action={handleActivateAccount}
                message={t('pages.admin.users.tabsContent.actionsForm.activateModal.message').text}
            ></ConfirmModal>,
        );
    };

    const handleActivateAccount = (): void => {
        closeGlobalModal();
        communication
            .confirmUser(selectedUserInfo?.id, { email: selectedUserInfo.email })
            .then((res: ObjectKeys) => {
                if (res) {
                    toast.success('Nalog je aktiviran');
                }
            })
            .then(() => {
                getUser(selectedUserInfo?.id, setSelectedUserInfo);
            })
            .then(() => {
                getAllUsers(setAllUsersOptions);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    toast.error(t('pages.tryAgain.message').text);
                }
            });
    };

    return (
        <ActionsFormWrapper>
            <ToastContainer />
            {loaderVisible && <Loader />}
            <UserName>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.admin.users.tabsContent.actionsForm.name').text}
                    value={formData.name}
                    onChange={(value: string) => {
                        setFormData({ ...formData, name: value });
                    }}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.admin.users.tabsContent.actionsForm.lastName').text}
                    value={formData.lastName}
                    onChange={(value: string) => {
                        setFormData({ ...formData, lastName: value });
                    }}
                />
            </UserName>
            <Input
                type={InputComponentType.Text}
                label={t('pages.admin.users.tabsContent.actionsForm.middleName').text}
                value={formData.middleName}
                onChange={(value: string) => {
                    setFormData({ ...formData, middleName: value });
                }}
            />
            <SelectWrapper className="full-width">
                <SelectSearch
                    optionList={citizenshipOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({
                            ...formData,
                            country_id: data.value,
                        });
                    }}
                    searchPlaceholder={t('pages.admin.users.tabsContent.actionsForm.searchCitizenship').text}
                    placeholder={t('pages.admin.users.tabsContent.actionsForm.chooseCitizenship').text}
                    defaultValue={citizenshipOptions.find((option) => option.value === formData.country_id)}
                />
            </SelectWrapper>
            <Input
                type={InputComponentType.Number}
                label={t('pages.admin.users.tabsContent.actionsForm.personalId').text}
                value={formData.personalId}
                onChange={(value: string) => {
                    setFormData({ ...formData, personalId: value });
                }}
                maxLength={13}
                validation={showError ? isJmbgValid : ''}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.admin.users.tabsContent.actionsForm.personalQualifications').text}
                value={formData.personalQualifications}
                onChange={(value: string) => {
                    setFormData({ ...formData, personalQualifications: value });
                }}
            />
            <SelectWrapper className="full-width">
                <SelectSearch
                    optionList={genderOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({
                            ...formData,
                            gender: data.value,
                        });
                    }}
                    searchPlaceholder={t('pages.admin.users.tabsContent.actionsForm.searchGender').text}
                    placeholder={t('pages.admin.users.tabsContent.actionsForm.chooseGender').text}
                    defaultValue={
                        formData.gender
                            ? { value: formData.gender, label: formData.gender }
                            : { value: '', label: t('pages.admin.users.tabsContent.actionsForm.chooseGender').text }
                    }
                />
            </SelectWrapper>
            <Input
                type={InputComponentType.Text}
                label={t('pages.admin.users.tabsContent.actionsForm.city').text}
                value={formData.city}
                onChange={(value: string) => {
                    setFormData({ ...formData, city: value });
                }}
            />
            <SelectWrapper className="full-width">
                <SelectSearch
                    optionList={municipalitiesOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({
                            ...formData,
                            municipalityId: data.value,
                        });
                    }}
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.searchMunicipality').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text}
                    defaultValue={
                        formData.municipalityId
                            ? {
                                  value: formData.municipalityId,
                                  label: municipalitiesOptions.find((m) => m?.value === formData.municipalityId)?.label,
                              }
                            : {
                                  value: '',
                                  label: t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text,
                              }
                    }
                />
            </SelectWrapper>
            <Address>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.admin.users.tabsContent.actionsForm.address').text}
                    value={formData.address}
                    onChange={(value: string) => {
                        setFormData({ ...formData, address: value });
                    }}
                    className="address-name"
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.admin.users.tabsContent.actionsForm.addressNumber').text}
                    value={formData.addressNumber}
                    onChange={(value: string) => {
                        setFormData({ ...formData, addressNumber: value });
                    }}
                    className="address-num"
                />
            </Address>
            <Input
                type={InputComponentType.Text}
                label={t('pages.admin.users.tabsContent.actionsForm.email').text}
                value={formData.email}
                onChange={(value: string) => {
                    setFormData({ ...formData, email: value });
                }}
                validation={showError ? isEmailValid : ''}
            />
            <ToggleContainer>
                <p>{t('pages.admin.users.tabsContent.actionsForm.confirmedAccount').text}</p>
                <ToggleSwitch
                    defaultChecked={formData.confirmedAccount}
                    onChange={() =>
                        setFormData({
                            ...formData,
                            confirmedAccount: !formData.confirmedAccount,
                        })
                    }
                />
            </ToggleContainer>
            <ToggleContainer>
                <p>{t('pages.admin.users.tabsContent.actionsForm.activeAccount').text}</p>
                <ToggleSwitch
                    defaultChecked={formData.activeAccount}
                    onChange={() =>
                        setFormData({
                            ...formData,
                            activeAccount: !formData.activeAccount,
                        })
                    }
                />
            </ToggleContainer>
            <ButtonsContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    className="big"
                    size={200}
                    onClick={() => {
                        handleSave();
                    }}
                >
                    {t('pages.admin.users.tabsContent.actionsForm.saveData').text}
                </Button>
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.danger}
                    icon
                    className="big delete-btn"
                    size={200}
                    onClick={() => {
                        handleUser();
                    }}
                >
                    <FontAwesomeIcon icon={faTrashCan} style={{ color: colors.danger }} />
                    {t('pages.admin.users.tabsContent.actionsForm.deleteAccount').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    className="big"
                    size={200}
                    onClick={() => {
                        handleActivateModal();
                    }}
                >
                    {t('pages.admin.users.tabsContent.actionsForm.activateAccount').text}
                </Button>
            </ButtonsContainer>
        </ActionsFormWrapper>
    );
};
const ActionsFormWrapper = styled.div`
    label {
        text-transform: uppercase;
    }
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const UserName = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    .input {
        width: 50%;
    }
`;
const Address = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    button {
        &.delete-btn {
            margin: 0 20px;
        }
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        button {
            &:not(:last-child) {
                margin: 0 0 20px 0;
            }
        }
    }
`;
const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
    p {
        color: var(--gray);
        text-transform: uppercase;
    }
    &:hover {
        border-bottom: 1px solid var(--purple);
    }
`;
export default ActionsForm;
