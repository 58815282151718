import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import { GetNotificationsRequest, GetNotificationsResponse } from './types';

export default class Notifications {
    public getNotifications(params: GetNotificationsRequest): Promise<GetNotificationsResponse> {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.getNotifications,
            params: params,
        });
    }
    public markAllAsRead(): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.markAllAsRead,
        });
    }
    public markAsRead(): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.markAsRead,
        });
    }
}
