import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, some } from 'lodash';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import ErrorModal from '../../../../components/errorModal';
import AddClientModal from '../../clients/addClientModal';
import SendModal from '../sendModal';
import EditClientModal from '../../clients/editClientModal';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import WebFooter from '../../common/footer/webFooter';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModal';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModal2';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModal3';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModal4';
import useUserStore from '../../../../store/user';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import { Body, Container, Invoice } from '../../common/style';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import { formatParams } from '../../../../functions/formatParams';
import useYearsStore from '../../../../store/years';
import countTotal from '../../common/countTotal';
import getAllInvoices from '../getAllInvoices';
import getAllClients from '../../clients/getAllClients';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../clients/warningCard';
import ConfirmModal from '../../../../components/confirmModal';
import useUnitsStore from '../../../../store/measurementUnits';
import getStatistics from '../getStatistics';

const CopyEInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { agency } = useAgencyStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const token = getAccessToken();
    const size = useWindowSize();
    const { pathname } = useLocation();
    const invoiceId = useParams().id;
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.display_name;

    const firstRender = useRef(true);

    const { units } = useUnitsStore();

    const isAdvance = pathname.includes('advance');
    const [totalAvance, setTotalAvance] = useState('');

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({});
    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [pastYearModal, setPastYearModal] = useState(false);
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmail, setClientEmail] = useState('');

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const [oldInvoiceDate, setOldInvoiceDate] = useState('');

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const [params] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });

    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        console.log('invoice', invoice);
    }, [invoice]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmail('');
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, invoice.type === 'national' ? 0 : 1);
                    setDeleteClientModal(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setInvoice((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
                setLoading(false);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    toast.error(error.response.data.message);
                    setLoading(false);
                }
            });
    };

    const handleInvoiceDelete = async (): Promise<void> => {
        setDeleteInvoiceModal(false);
        setLoading(true);
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                navigate('/invoices');
                updateYears();
                setLoading(false);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const thisYear = parseInt(moment().format('YYYY'));

    const update = async (): Promise<any> => {
        try {
            setChangeHappened(false);
            setLoading(true);
            const updateInvoice = { ...invoice };
            updateInvoice.services = updateInvoice.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                discount: service.pivot.discount,
            }));
            updateInvoice.invoice_number = updateInvoice.real_invoice_number;
            updateInvoice.value = Number(countTotal(invoice.services).replace(',', '.'));
            updateInvoice.value_in_rsd = Number(countTotal(invoice.services).replace(',', '.'));
            updateInvoice.trading_date = moment(updateInvoice.trading_date).format('YYYY-MM-DD').toString();
            updateInvoice.rate = Number(updateInvoice.rate);
            updateInvoice.paid_date = moment(updateInvoice.paid_date).format('YYYY-MM-DD').toString();
            updateInvoice.invoice_date = moment(updateInvoice.invoice_date).format('YYYY-MM-DD').toString();
            updateInvoice.cancelled = false;
            updateInvoice.cancel_number = false;
            updateInvoice.custom_number = false;
            updateInvoice.is_custom = false;
            updateInvoice.custom_text = false;
            updateInvoice.auto_increment = true;
            updateInvoice.invoice_per_owner = true;
            if (isAdvance) {
                updateInvoice.is_prepaid = true;
            }
            const res = await communication.editInvoice(invoice.id, updateInvoice);

            if (res) {
                setLoading(false);
                updateYears();
                setChangeHappened(false);
                navigate('/invoices');
                return res?.data?.data;
            }
        } catch (error: any) {
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const isInvoiceValid = async (): Promise<boolean> => {
        if (!invoice.services || invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            return false;
        }

        if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            return false;
        }

        if (!invoice.client_id || Object.keys(invoice.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            return false;
        }

        if (invoice.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            return false;
        }

        if (
            new Date(invoice.invoice_date).getFullYear() < thisYear &&
            moment(oldInvoiceDate).format('YYYY-MM-DD').toString() !==
                moment(invoice.invoice_date).format('YYYY-MM-DD').toString() &&
            invoice.invoice_number
        ) {
            setPastYearModal(true);
            return false;
        }

        return true;
    };

    const handleUpdateInvoice = async (): Promise<any> => {
        setChangeHappened(false);
        const isValid = await isInvoiceValid();
        if (!isValid) throw new Error('Invalid invoice');
        return update();
    };

    useEffect(() => {
        setLoading(true);
        if (invoiceId) {
            communication.getInvoice(invoiceId).then((res: ObjectKeys) => {
                setInvoice(res?.data?.data);
                setOldInvoiceDate(res?.data?.data?.invoice_date);
                const newUnits = res?.data.data.services
                    .map((item: ObjectKeys) => item.pivot.measurement_unit)
                    .filter((unit: any) => !units.includes(unit));

                useUnitsStore.setState({ units: [...units, ...newUnits] });
                setClientEmail(res?.data?.data?.client?.data?.email);
                setLoading(false);
                agency &&
                    handleGenerateQRCode(
                        agency,
                        res?.data?.data?.client?.data?.company_name,
                        totalAvance ? totalAvance : res?.data?.data?.value,
                        res?.data?.data?.invoice_number,
                        res?.data?.data?.bank_account,
                        res?.data?.data?.currency,
                        true,
                    );
            });
        }
    }, [invoiceId]);

    useEffect(() => {
        if (clickedClient) {
            const matchingClient = Array.isArray(clients.data)
                ? clients.data.find((client: ObjectKeys) => client.id === clickedClient)
                : null;
            matchingClient && handleSelectedClient(matchingClient);
        }
    }, [clients]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData.name,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                    else {
                        setQrCode('');
                    }
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        setClientEmail(client.email);
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            return newInvoiceData;
        });
        agency &&
            invoice &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                totalAvance ? totalAvance : invoice.value,
                invoice.invoice_number,
                invoice.bank_account,
                invoice.currency,
                true,
            );
    };

    //Invoice Download
    const handleDownloadInvoice = async (): Promise<void> => {
        try {
            await handleUpdateInvoice()
                .then(async (newInvoice: any) => {
                    await generateQrCode(
                        newInvoice.original_data.agency.name,
                        newInvoice.client ? invoice.client.data.company_name : {},
                        totalAvance ? totalAvance : newInvoice.value ? newInvoice.value : '0',
                        newInvoice.future_invoice_number
                            ? newInvoice.future_invoice_number
                            : invoice.invoice_number
                            ? invoice.invoice_number
                            : '',
                        newInvoice.bank_account,
                        newInvoice.currency,
                        true,
                        newInvoice.future_invoice_number
                            ? `00${newInvoice.future_invoice_number?.replace('/', '-')}`
                            : invoice.invoice_number
                            ? `00${invoice.invoice_number?.replace('/', '-')}`
                            : '',
                        '221',
                    ).then(async (res: ObjectKeys | any) => {
                        if (res.i) {
                            await communication.downloadInvoice(
                                invoice.id,
                                token,
                                newInvoice.future_invoice_number
                                    ? newInvoice.future_invoice_number
                                    : invoice.invoice_number
                                    ? invoice.invoice_number
                                    : '',
                                invoice.currency !== 'RSD',
                                false,
                                res.i ? res.i : undefined,
                            );
                        } else {
                            await communication.downloadInvoice(
                                invoice.id,
                                token,
                                newInvoice.future_invoice_number
                                    ? `00${newInvoice.future_invoice_number?.replace('/', '-')}`
                                    : invoice.invoice_number
                                    ? `00${invoice.invoice_number?.replace('/', '-')}`
                                    : '',
                                invoice.currency !== 'RSD',
                                false,
                                undefined,
                            );
                        }
                    });
                })
                .catch((error: any) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        } finally {
            updateYears();
            getAllInvoices(formattedParams);
            setLoading(false);
        }
    };

    const handleTotalChange = (modifiedTotal: any): void => {
        setTotalAvance(modifiedTotal);
    };

    useEffect(() => {
        if (invoice.services && invoice.services.length > 0 && !isEmpty(invoice)) {
            if (firstRender.current) {
                firstRender.current = false;
            } else {
                setChangeHappened(true);
            }
        }
    }, [invoice]);

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={invoice.id}
                        success={() => {
                            getStatistics();
                            setSendModal(false);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={clientEmail}
                        qrCode={qrCode}
                        handleUpdateInvoice={handleUpdateInvoice}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        totalAvance={totalAvance}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                    />
                </Sidebar>
            )}
            {pastYearModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setPastYearModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={update}
                        close={() => {
                            setPastYearModal(false);
                        }}
                        message={t('pages.editInvoice.pastYearModal').text}
                        confirmText={t('buttons.ok').text}
                        closeText={t('buttons.close').text}
                    />
                </Modal>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            setChangeHappened(false);
                            handleInvoiceDelete();
                        }}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'Plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'Business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'InvoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                </Modal>
            )}

            <Container>
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={async () => {
                            setPreviewModal(true);
                        }}
                        setSendModal={async () => {
                            const isValid = await isInvoiceValid();
                            isValid && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={async () => {
                            setPreviewModal(true);
                        }}
                        setSendModal={async () => {
                            const isValid = await isInvoiceValid();
                            isValid && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleChangeHappened={(change: boolean) => setChangeHappened(change)}
                    />
                )}
                <Body className="edit-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={invoice?.type === 'national' ? 'domestic' : invoice?.type === 'foreign' ? 'foreign' : ''}
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {agency && invoice && (
                        <Invoice>
                            {invoice?.type === 'national' ? (
                                <InvoiceEdit
                                    // qrCode={qrCode}
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    setLoading={setLoading}
                                    type={'domestic'}
                                    handleTotalAdvance={handleTotalChange}
                                    handleQrCode={(qr: string) => setQrCode(qr)}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    qrCode={qrCode}
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            total_for_payment: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    setLoading={setLoading}
                                    type={'foreign'}
                                    clientChanged={clientChanged}
                                    handleChangeHappend={(change: boolean) => {
                                        setChangeHappened(change);
                                    }}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobileWidth ? (
                        <MobileFooter
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={async () => {
                                setPreviewModal(true);
                            }}
                            setSendModal={async () => {
                                const isValid = await isInvoiceValid();
                                isValid && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={async () => {
                                setPreviewModal(true);
                            }}
                            setSendModal={async () => {
                                const isValid = await isInvoiceValid();
                                isValid && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
        </>
    );
};
export default CopyEInvoice;
