import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/pausal-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../global/colors';
import Dropdown from '../../dropdown';
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { AccountExpires, UserDropdown, AccountExpired } from '../web';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import SelectLang from '../../selectLang';
import Nbs from '../nbs';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Button, { ButtonVariant } from '../../button';
import Modal from '../../modal';
import Success from '../../successModal';
import AccountExpiration from '../accountExpiration';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NavItem, { NavItemType } from '../navItem';

interface Props {
    navItems: NavItemType[];
    userDropdown: UserDropdown[];
    role: string | null;
    openSuggestionModal: Function;
    expires: string | undefined;
    isAccountActive: boolean;
    packageName: string;
}

const MobileNavbar: FunctionComponent<Props> = ({
    navItems,
    userDropdown,
    role,
    openSuggestionModal,
    expires,
    isAccountActive,
    packageName,
}) => {
    const { pathname } = useLocation();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const [userDropdownVisible, setUserDropdownVisible] = useState(false);
    const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);

    const [nbsDropdownVisible, setNbsDropdownVisible] = useState(false);
    const [accountExpires, setAccountExpires] = useState(false);

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const handleSelect = (text: string): void => {
        if (!useChangeModalStore.getState().changeModalVisible) {
            if (text === t('navbar.pausal.nbs').text) {
                setNbsDropdownVisible(true);
                return;
            } else if (text === t('navbar.pausal.suggestion').text) openSuggestionModal();
        }
    };

    return (
        <>
            {accountExpires && <AccountExpiration close={() => setAccountExpires(false)} />}
            <MobileContainer>
                {successfullySaved && (
                    <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                        <Success
                            close={() => setSuccessfullySaved(false)}
                            message={t('pages.agency.profile.saveSuccess').text}
                        />
                    </Modal>
                )}
                <Nav className={accountExpires ? 'hide' : ''}>
                    <Container>
                        <LeftSide>
                            <BurgerPlace
                                onClick={() => {
                                    setMenuDropdownVisible((prev) => !prev);
                                    setUserDropdownVisible(false);
                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faBars} style={{ fontSize: '26px' }} />
                            </BurgerPlace>
                            <Border />
                            <UserPlace
                                onClick={() => {
                                    setMenuDropdownVisible(false);
                                    setUserDropdownVisible((prev) => !prev);
                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faUser} />
                                <FontAwesomeIcon style={{ paddingLeft: '10px' }} color="white" icon={faCaretDown} />
                            </UserPlace>
                            {userDropdownVisible && (
                                <DropdownContainer
                                    onClick={() => {
                                        setUserDropdownVisible(false);
                                    }}
                                >
                                    <Dropdown arrowLeft="53px" itemList={userDropdown} />
                                </DropdownContainer>
                            )}
                        </LeftSide>

                        <Link style={{ cursor: 'pointer' }} to="/invoices">
                            <Image src={logo} height={'40px'} width={'120px'} />
                        </Link>
                    </Container>

                    {menuDropdownVisible && (
                        <BurgerContainer>
                            <>
                                {navItems.map((navItem, index) => {
                                    let action: () => void;

                                    switch (navItem.actionType) {
                                        case 'toggleNbs':
                                            action = (): void => {
                                                setNbsDropdownVisible(true);
                                            };
                                            break;
                                        default:
                                            action = (): void => {
                                                setMenuDropdownVisible(false);
                                            };
                                            break;
                                    }

                                    const handleClick = (): void => {
                                        handleSelect(t(navItem.text).text);
                                        action && action();
                                    };

                                    return (
                                        <NavItem {...navItem} action={handleClick} key={navItem.text + `${index}`} />
                                    );
                                })}
                                {nbsDropdownVisible && (
                                    <NbsContainer>
                                        <Nbs height="auto" width="100%" onClose={() => setNbsDropdownVisible(false)} />
                                    </NbsContainer>
                                )}
                            </>
                            {role && role !== 'admin' && (
                                <>
                                    {isAccountActive ? (
                                        <AccountExpires
                                            onClick={() => {
                                                navigate('/settings/subscription/1');
                                                setMenuDropdownVisible(false);
                                            }}
                                        >
                                            <p>
                                                {t('navbar.pausal.expires').text +
                                                    ' ' +
                                                    moment(expires).format('DD.MM.YYYY.')}
                                            </p>
                                            <p>{packageName}</p>
                                        </AccountExpires>
                                    ) : (
                                        <AccountExpired>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={colors.danger}
                                                size={190}
                                                onClick={() => navigate('/settings/subscription/1')}
                                            >
                                                {t('navbar.pausal.accountExpired').text}
                                            </Button>
                                        </AccountExpired>
                                    )}
                                    <SelectContainer>
                                        <SelectLang />
                                    </SelectContainer>
                                    {/* {isAccountActive ? (
                                        <ButtonContainer>
                                            <Link to="/independenceTest">
                                                <button
                                                    className="digital-test"
                                                    onClick={() => {
                                                        setMenuDropdownVisible(false);
                                                    }}
                                                >
                                                    {t('navbar.pausal.independenceTest').text}
                                                </button>
                                            </Link>
                                        </ButtonContainer>
                                    ) : (
                                        <></>
                                    )} */}
                                </>
                            )}
                            {role && role === 'admin' && !pathname.includes('admin') && <Navigate to="/admin/users" />}
                        </BurgerContainer>
                    )}
                </Nav>
            </MobileContainer>
        </>
    );
};
const MobileContainer = styled.div`
    .modal-wrapper {
        max-width: 100%;
    }

    @media only screen and (max-width: 425px) {
    }
`;
const Nav = styled.div`
    &.hide {
        display: none;
    }
`;

const Container = styled.div`
    top: 0;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    background: var(--purple);
    overflow: visible;
    padding: 22px 30px;
    height: 84px;
    border-bottom: 1px solid var(--border-color);
`;

const Image = styled.img`
    float: right;
`;

const UserPlace = styled.div`
    margin: -16px 0 0 15px;
    margin-left: 34px;
    margin-top: -12px;
    width: 83px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

const BurgerPlace = styled.div`
    margin-top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;
const Border = styled.div`
    border-right: 1px solid var(--border-color);
    height: 84px;
    position: absolute;
    top: 0;
    left: 88px;
`;

const LeftSide = styled.div`
    float: left;
    display: flex;
    flex-direction: row;
`;

const DropdownContainer = styled.div`
    top: 98px;
    width: 182px;
    left: 88px;
    position: absolute;
    z-index: 999;
`;

const BurgerContainer = styled.div`
    width: 100%;
    background: var(--purple);
    margin-top: 84px;
    padding-bottom: 124px;
    height: calc(100vh - 84px);
    overflow-y: scroll;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 999;
    float: none;

    position: fixed;
`;

const SelectContainer = styled.div`
    padding-left: 30px;
    margin-top: 25px;

    > div {
        height: 36px;
        width: 100px;
    }
`;

// const ButtonContainer = styled.div`
//     padding-left: 30px;
//     margin-top: 25px;
//     margin-bottom: 50px;
//     height: 100px;
//     p {
//         width: auto;
//         font-size: 18px;
//         font-weight: 400px;
//         padding: 20px 10px;
//         color: var(--white);
//         text-transform: uppercase;
//     }
//     .digital-test {
//         border: 1px solid var(--white);
//         background-color: var(--purple);
//         color: var(--white);
//         font-size: 13px;
//         line-height: 16px;
//         padding: 9px 16px;
//         border-radius: 5px;
//         min-width: 100px;
//         width: 190px;
//         height: 40px;
//         transition: ease all 0.3s;
//         text-transform: uppercase;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         text-align: center;
//         cursor: pointer;
//     }
// `;

const NbsContainer = styled.div`
    height: 680px;
    position: absolute;
    width: 400px;
    max-width: 90%;
    z-index: 9999;
    overflow-y: scroll;
    left: 50%;
    transform: translateX(-50%);
`;
export default MobileNavbar;
