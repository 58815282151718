import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../../../components/input';
import SelectComponent from '../../../components/select';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import communication from '../../../communication';
import useWindowSize from '../../../hooks/useResize';
import useAgencyStore from '../../../store/agency';
import Loader from '../../../components/loader';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';

const Einvoices: FunctionComponent = () => {
    const agency = useAgencyStore((state) => state.agency);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [apiKey, setApiKey] = useState<string>(agency.e_invoice_api_key ? agency.e_invoice_api_key : '');
    const [vatExemption, setVatExemption] = useState<string>(
        agency.e_invoice_vat_exception_code ? agency.e_invoice_vat_exception_code : 'PDV-RS-33',
    );
    const vatCategory = 'SS';
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltip2, setShowTooltip2] = useState(false);
    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const handleInputFocus = (): void => {
        setShowTooltip(true);
    };

    const handleInputBlur = (): void => {
        setShowTooltip(false);
    };
    const handleInputFocus2 = (): void => {
        setShowTooltip2(true);
    };

    const handleInputBlur2 = (): void => {
        setShowTooltip2(false);
    };
    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);
    const vatExemptions = [
        {
            value: 'PDV-RS-33',
            label: 'PDV-RS-33',
        },
        {
            value: 'PDV-RS-35-7',
            label: 'PDV-RS-35-7',
        },
        {
            value: 'PDV-RS-36-5',
            label: 'PDV-RS-36-5',
        },
        {
            value: 'PDV-RS-36b-4',
            label: 'PDV-RS-36b-4',
        },
    ];

    const handleSave = async (): Promise<void> => {
        setLoaderVisible(true);
        const updatedProfile = {
            ...agency,
            e_invoice_api_key: apiKey,
            e_invoice_vat_exception_code: vatExemption,
            e_invoice_vat_sign: vatCategory,
        };
        const res = await communication.updateAgency(agency.id, updatedProfile);
        if (res.status === 200) {
            setSuccessfullySaved(true);
            useAgencyStore.setState({ agency: res.data.data });
            setLoaderVisible(false);
        }
    };
    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <Content className={isMobileWidth ? 'mobile-width' : 'desktop'}>
                <div className={'description'}>{t('pages.agency.eInvoices.description').text}</div>
                <div>
                    <div onMouseEnter={handleInputFocus} onMouseLeave={handleInputBlur} className="tooltip-container">
                        <Input
                            type={'text'}
                            onChange={(e: string) => {
                                setApiKey(e);
                            }}
                            label={t('pages.agency.eInvoices.apiKeyLabel').text}
                            value={apiKey}
                            inputClassName={'bordered'}
                        />
                        {showTooltip && (
                            <div className="tooltip-text">{t('pages.agency.eInvoices.apiKeyTooltip').text}</div>
                        )}
                    </div>
                    <div onMouseEnter={handleInputFocus2} onMouseLeave={handleInputBlur2} className="tooltip-container">
                        <SelectComponent
                            className={'einvoices'}
                            width={'100%'}
                            value={vatExemption}
                            handleSelectedValue={(e: string) => {
                                setVatExemption(e);
                            }}
                            label={t('pages.agency.eInvoices.vatExemptionLabel').text}
                            optionList={vatExemptions}
                        />
                        {showTooltip2 && (
                            <div className="tooltip-text">{t('pages.agency.eInvoices.vatExemptionTooltip').text}</div>
                        )}
                    </div>
                    <Input
                        type={'text'}
                        value={agency.e_invoice_vat_sign || vatCategory}
                        label={t('pages.agency.eInvoices.vatCategoryLabel').text}
                        inputClassName={'bordered'}
                        readOnly={true}
                    />
                    <div className={'button-container'}>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'200px'}
                            onClick={() => handleSave()}
                        >
                            {t('pages.agency.eInvoices.button').text}
                        </Button>
                    </div>
                </div>
            </Content>
        </>
    );
};

const Content = styled.div`
    display: inline-block;
    vertical-align: top;
    &.desktop {
        width: 56%;
    }
    &.mobile {
        width: 100%;
    }
    .description {
        margin-bottom: 20px;
        color: var(--gray);
        font-size: 15px;
    }
    .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 25px;
    }
    .tooltip-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .tooltip-trigger {
        cursor: pointer;
    }
    .tooltip-text {
        font-size: 14px;
        width: 100%;
        border: 1px solid var(--purple);
        border-radius: 4px;
        padding: 5px;
        position: apsolute;
        background-color: white;
        color: black;
        padding: 5px;
        top: -155px; /* Move it up 20 pixels */
        left: 215px; /* Move it right 20 pixels */
        display: inline-block;
        opacity: 0.9;
        z-index: 1; /* Ensure it's above other elements */
    }
`;
export default Einvoices;
