import { FunctionComponent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/pausal-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from '../../dropdown';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Button, { ButtonVariant } from '../../button';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import SelectLang from '../../selectLang';
import { Link, useLocation, Navigate } from 'react-router-dom';
import moment from 'moment';
import Modal from '../../modal';
import Success from '../../successModal';
import AccountExpiration from '../accountExpiration';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import Nbs from '../nbs';
import NavItem, { NavItemType } from '../navItem';

interface Props {
    navItems: NavItemType[];
    userDropdown: UserDropdown[];
    role: string | null;
    expires: string | undefined;
    openSuggestionModal: Function;
    isAccountActive: boolean;
    packageName: string;
}

export interface UserDropdown {
    label: string;
    icon: any;
    link?: string;
    newTab?: boolean;
    handler?: MouseEventHandler;
}

const WebNavbar: FunctionComponent<Props> = ({
    navItems,
    userDropdown,
    role,
    expires,
    openSuggestionModal,
    isAccountActive,
    packageName,
}) => {
    const { pathname } = useLocation();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const [_selectedItem, setSelectedItem] = useState(
        navItems.findIndex((nav) => nav.link && pathname.includes(nav.link)) || 0,
    );

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [nbsDropdownVisible, setNbsDropdownVisible] = useState(false);
    const [suggestionVisible, setSuggestionVisible] = useState(false);
    const [accountExpires, setAccountExpires] = useState(false);

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const userDropdownItemsList = userDropdown.map((item) => {
        if (!item.handler) {
            return {
                ...item,
                handler: () => closeDropdown(),
            };
        } else {
            return {
                ...item,
                handler: (e: MouseEvent) => {
                    item.handler && item.handler(e);
                    closeDropdown();
                },
            };
        }
    });

    const closeDropdown = (): void => {
        setDropdownVisible(false);
    };

    const toggleNbsDropdown = (): void => {
        setNbsDropdownVisible((prev) => !prev);
    };

    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    const handleSelect = (text: string): void => {
        ReactGA.event({
            category: text,
            action: 'Pregledana stranica',
        });
        if (!useChangeModalStore.getState().changeModalVisible) {
            if (text === t('navbar.pausal.nbs').text) setNbsDropdownVisible(!nbsDropdownVisible);
            else if (text === t('navbar.pausal.suggestion').text) {
                setSuggestionVisible(!suggestionVisible);
                openSuggestionModal();
            } else setNbsDropdownVisible(false);
        }
    };

    useEffect(() => {
        setSelectedItem(navItems.findIndex((nav) => nav.link && pathname.includes(nav.link)) || 0);
    }, [pathname]);

    return (
        <Wrapper>
            {accountExpires && (
                <Modal modalVisible={true} closeModal={() => setAccountExpires(false)}>
                    <AccountExpiration close={() => setAccountExpires(false)} />
                </Modal>
            )}
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <Container>
                <div className="top-items">
                    <Header ref={ref}>
                        <Link style={{ cursor: 'pointer' }} to="/invoices">
                            <img src={logo} height={'40px'} />
                        </Link>
                        <UserPlace
                            onClick={() => {
                                setDropdownVisible(!dropdownVisible);
                            }}
                        >
                            <FontAwesomeIcon color="white" icon={faUser} />
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color="white" icon={faCaretDown} />
                        </UserPlace>
                        {dropdownVisible && (
                            <DropdownContainer>
                                <Dropdown arrowRight="4px" itemList={userDropdownItemsList} />
                            </DropdownContainer>
                        )}
                    </Header>
                    <nav>
                        {navItems.map((navItem, index) => {
                            const handleClick = (): void => {
                                handleSelect(t(navItem.text).text);

                                switch (navItem.actionType) {
                                    case 'toggleNbs':
                                        setDropdownVisible((prev) => !!prev);
                                        break;
                                    default:
                                        break;
                                }
                            };

                            return <NavItem {...navItem} action={handleClick} key={navItem.text + `${index}`} />;
                        })}
                    </nav>

                    {nbsDropdownVisible && (
                        <NbsContainer>
                            <Nbs onClose={toggleNbsDropdown} width="100%" />
                        </NbsContainer>
                    )}
                </div>

                <div className="bottom-items">
                    {role && role !== 'admin' && (
                        <>
                            <SelectContainer>
                                <SelectLang />
                            </SelectContainer>

                            {isAccountActive ? (
                                <AccountExpires onClick={() => navigate('/settings/subscription/1')}>
                                    <p>
                                        {t('navbar.pausal.expires').text + ' ' + moment(expires).format('DD.MM.YYYY.')}
                                    </p>
                                    <p>{packageName}</p>
                                </AccountExpires>
                            ) : (
                                <AccountExpired>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.danger}
                                        size={190}
                                        onClick={() => navigate('/settings/subscription/1')}
                                    >
                                        {t('navbar.pausal.accountExpired').text}
                                    </Button>
                                </AccountExpired>
                            )}
                        </>
                    )}
                    {role && role === 'admin' && !pathname.includes('admin') && <Navigate to="/admin/users" />}
                </div>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .modal-wrapper {
        width: 800px;
    }
`;

const Container = styled.div`
    height: 100%;
    overflow-y: auto;
    width: 248px;
    position: fixed;
    z-index: 10;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
    background: linear-gradient(180deg, var(--navbar-black) 0, var(--navbar-gray));
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }
    .digital-test {
        border: 1px solid rgb(155, 127, 233);
        background-color: var(--purple);
        color: var(--white);
        font-size: 13px;
        line-height: 16px;
        padding: 9px 16px;
        border-radius: 5px;
        min-width: 100px;
        width: 190px;
        height: 40px;
        transition: ease all 0.3s;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
    }
    .bottom-items {
    }
`;

const Header = styled.div`
    display: inline-block;
    vertical-align: top;
    background: var(--purple);
    width: 100%;
    border-bottom: 1px solid var(--white);
    padding: 30px 0 0 20px;
    position: relative;
    z-index: 54;
    margin-bottom: 5px;
    height: 84px;
`;

export const UserPlace = styled.div`
    float: right;
    margin: -16px 0 0 15px;
    width: 50px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

export const DropdownContainer = styled.div`
    margin-top: 33px;
    width: 182px;
    right: 10px;
    position: absolute;
`;

export const AccountExpires = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin: 10px 0px 10px 30px;
    border-left: 3px solid transparent;
    font-size: 13px;
    p {
        font-size: 13px;
        margin-top: 3px;
    }

    color: var(--white);
`;
export const AccountExpired = styled.div`
    margin: 25px 0px 25px 10px;
`;

const SelectContainer = styled.div`
    padding-left: 30px;
    margin-top: 20px;

    > div {
        height: 36px;
        width: 100px;
    }
`;

const NbsContainer = styled.div`
    z-index: 100;
    height: 500px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

export default WebNavbar;
