import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { faBars, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useYearsStore from '../../../../store/years';
import useCurrencyStore from '../../../../store/currency';
import SelectComponent from '../../../../components/select';
import Search from '../../../../components/input/search';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    searchValue?: string;

    setSearchValue: Dispatch<SetStateAction<string>>;
    setDisplay: Dispatch<SetStateAction<string>>;
}

const Filters: FunctionComponent<Props> = ({ setParams, params, searchValue, setSearchValue, setDisplay }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { quotationYears } = useYearsStore();
    const { currency } = useCurrencyStore();
    const [renderKey, setRenderKey] = useState(0);
    const [showSearch, setShowSearch] = useState(false);

    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    useEffect(() => {
        const yearsData = quotationYears?.map((year) => {
            return { value: year, label: year };
        });
        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);

        setRenderKey((prev) => prev + 1);
    }, [useYearsStore()]);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });
        const yearsData = quotationYears?.map((year) => {
            return { value: year, label: year };
        });

        setCurrencies([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...currencyData]);
        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
    }, [currentLang]);

    return (
        <FiltersWrapper>
            <TopFilters>
                <FiltersInner>
                    <SelectComponent
                        className="select-container"
                        optionList={currencies}
                        label={t('pages.proforma.filters.currency.label').text}
                        handleSelectedValue={(value: string) =>
                            setParams({
                                ...params,
                                search: { ...params.search, currency: value },
                                page: 1,
                                searchFields:
                                    value !== ''
                                        ? { ...params.searchFields, currency: '=' }
                                        : { ...params.searchFields, currency: value },
                            })
                        }
                        defaultSelectedOption={currencies[0]}
                    />
                    <SelectComponent
                        className="select-container"
                        optionList={yearsOptions}
                        label={t('pages.proforma.filters.year.label').text}
                        handleSelectedValue={(value: string) => {
                            setParams({
                                ...params,
                                year: value,
                                page: 1,
                            });
                        }}
                        defaultSelectedOption={yearsOptions[0]}
                        key={renderKey}
                    />
                </FiltersInner>
                <DisplayIcons>
                    <FontAwesomeIcon
                        icon={faBars}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('list')}
                    />
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('grid')}
                    />
                </DisplayIcons>
            </TopFilters>
            <BottomFilters>
                <ShowSelect>
                    <SelectComponent
                        optionList={showItems}
                        label={t('pages.proforma.filters.show.label').text}
                        handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                        defaultSelectedOption={showItems[0]}
                    />
                    <p>{t('pages.proforma.filters.show.proforma').text}</p>
                </ShowSelect>
                <Search
                    placeholder={t('pages.proforma.filters.search').text}
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setShowSearch(true);
                    }}
                    onLeaveOrEnter={(value: string) => {
                        setShowSearch(false);
                        setSearchValue(value);
                        setParams({
                            ...params,
                            search: { ...params.search, 'client.company_name': value },
                            page: 1,
                            searchFields:
                                value !== ''
                                    ? { ...params.searchFields, 'client.company_name': 'like' }
                                    : { ...params.searchFields, 'client.company_name': value },
                        });
                    }}
                    showSearchNote={showSearch}
                />
            </BottomFilters>
        </FiltersWrapper>
    );
};

const FiltersWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
            @media screen and (max-width: 426px) {
                display: flow;
            }
        }
    }
`;
const TopFilters = styled.div`
    display: flex;
    align-item: center;
    padding: 10px 20px;
    background: var(--purple);
    color: var(--white);
    justify-content: space-between;
    .select-wrapper {
        width: 100%;
        margin-right: 20px;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
`;
const FiltersInner = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .select-container {
        > div {
            width: 120px;
        }
        div {
            font-size: 14px;
        }
    }
`;
const DisplayIcons = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 15px;
        font-size: 22px;
    }
    @media screen and (max-width: 768px) {
        justify-content: flex-end;
    }
`;
const BottomFilters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
    }
`;
const ShowSelect = styled.div`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: var(--gray);
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: var(--gray);
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
`;
export default Filters;
