import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPaste, faLifeRing, faRightToBracket, faCog, faFile } from '@fortawesome/free-solid-svg-icons';
import communication from '../../communication';
import useWindowSize from '../../hooks/useResize';
import WebNavbar from './web';
import MobileNavbar from './mobile';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../modal';
import Suggestion from './suggestion';
import { ObjectKeys } from '../../types/objectKeys';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAgencyStore from '../../store/agency';
import { activeUserNavigation, adminNavigation, userNavigation } from '../../constants/routes';
import { queryClient } from '../../App';

const Navbar: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const role = localStorage.getItem('role');
    const navigate = useNavigate();

    const { agency } = useAgencyStore();
    const isTrial = role !== 'admin' ? agency.user.data.trial : false;
    const userPackage =
        role !== 'admin' ? (isTrial ? t('navbar.main.trial').text : agency.user.data.payment_package.data.name) : '';

    const expires = role !== 'admin' ? agency.user.data.account_expiration_date : '';
    const [suggestionModal, setSuggestionModal] = useState(false);

    const isAccountActive = role !== 'admin' ? agency.user.data.active : true;

    const logoutHandler = async (): Promise<void> => {
        await communication
            .logout()
            .then((res: ObjectKeys) => {
                if (res) {
                    navigate('/login');
                }
            })
            .catch((error: any) => {
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
        queryClient.removeQueries();
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('role');
        sessionStorage.setItem('isAuthenticated', 'false');
    };

    const userDropdown = [
        {
            icon: faCog,
            label: t('navbar.main.settings').text,
            link: '/settings/notifications',
            newTab: false,
        },
        {
            icon: faLifeRing,
            label: t('navbar.main.help').text,
            link: 'https://pausal.rs/pausal-app/pitanja/',
            newTab: true,
        },
        {
            icon: faPaste,
            label: t('navbar.main.privacy').text,
            link: '/Privacy.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.paymentTerms').text,
            link: '/Payment_terms.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.terms').text,
            link: '/Terms.pdf',
            newTab: true,
        },
        { icon: faRightToBracket, label: t('navbar.main.logOut').text, handler: logoutHandler },
    ];

    const navItemsData = role === 'admin' ? adminNavigation : isAccountActive ? activeUserNavigation : userNavigation;

    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
        setIsSmallScreenWidth(!!size.width && size.width >= 768 && size.width <= 1079);
    }, [size.width]);

    return (
        <>
            <ToastContainer />
            {suggestionModal && (
                <Modal modalVisible={true} closeModal={() => setSuggestionModal(false)}>
                    <Suggestion close={() => setSuggestionModal(false)} />
                </Modal>
            )}

            {!(isMobileWidth || isSmallScreenWidth) ? (
                <WebNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    expires={expires}
                    role={role}
                    navItems={navItemsData}
                    userDropdown={userDropdown}
                    isAccountActive={isAccountActive}
                    packageName={userPackage}
                />
            ) : (
                <MobileNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    role={role}
                    navItems={navItemsData}
                    userDropdown={userDropdown}
                    expires={expires}
                    isAccountActive={isAccountActive}
                    packageName={userPackage}
                />
            )}
        </>
    );
};

export default Navbar;
