import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from '../../components/dropdown';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../components/select';
import MemorandumCard from '../../components/memorandumCard';
import communication from '../../communication';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import Loader from '../../components/loader';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import ConfirmModal from '../../components/confirmModal';
import Modal from '../../components/modal';
import Sidebar from '../../components/sideModal';
import SendDocumentModal from '../myAgency/documents/sendModal';
import { SendDocumentFormData } from '../../types/communication/document';
import useMemoStore from '../../store/memos';
import { ObjectKeys } from '@/types/objectKeys';
import NoItems from '../../components/noItems';
import Search from '../../components/input/search';

export interface EmailOption {
    label: string;
    value: string;
}

const Memorandums: FunctionComponent = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [limit, setLimit] = useState<any>('');
    const [status, setStatus] = useState<any>('all');
    const [find, setFind] = useState<string>('');
    const [page, setPage] = useState<string>('1');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedMemorandumId, setSelectedMemorandumId] = useState<string | undefined>('');
    const [isConfirmModalActive, setIsConfirmModalActive] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { memoData } = useMemoStore();

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const items = [
        {
            label: t('pages.memos.newMemo').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.newMemo').text, template: '1' },
                }),
        },
        {
            label: t('pages.memos.claim').text,
            handler: () =>
                navigate('/memorandums/new', { state: { name: t('pages.memos.claim').text, template: '2' } }),
        },
        {
            label: t('pages.memos.bid').text,
            handler: () => navigate('/memorandums/new', { state: { name: t('pages.memos.bid').text, template: '3' } }),
        },
        {
            label: t('pages.memos.statementOfDelay').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfDelay').text, template: '4' },
                }),
        },
        {
            label: t('pages.memos.decisionOfTravel').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.decisionOfTravel').text, template: '5' },
                }),
        },
        {
            label: t('pages.memos.statementOfSigning').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfSigning').text, template: '6' },
                }),
        },
        {
            label: t('pages.memos.employmentIncomeConfirmation').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.employmentIncomeConfirmation').text, template: '7' },
                }),
        },
        {
            label: t('pages.memos.loanAgreement').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.loanAgreement').text, template: '8' },
                }),
        },
    ];
    const showSelectOptions = [
        {
            value: '10',
            label: '10',
        },
        {
            value: '20',
            label: '20',
        },
        {
            value: '30',
            label: '30',
        },
        {
            value: '40',
            label: '40',
        },
    ];
    const statusSelectOptions = [
        {
            value: 'all',
            label: t('pages.memos.all').text,
        },
        {
            value: 'preparation',
            label: t('pages.memos.preparation').text,
        },
        {
            value: 'sent',
            label: t('pages.memos.sent').text,
        },
    ];

    const getMemorandums = async (): Promise<void> => {
        setIsLoader(true);
        const res = await communication.getAllMemorandums(page, limit, status, find);
        useMemoStore.setState({ memoData: res.data });
        setIsLoader(false);
    };

    useEffect(() => {
        getMemorandums();
    }, [page, status, limit]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    const handleEdit = (id: string | undefined): void => {
        navigate(`/memorandums/edit/${id}`);
    };

    const handleSend = async (id: string | undefined): Promise<void> => {
        setSelectedMemorandumId(id);
        setModalVisible(true);
    };

    const handleDelete = async (id: string | undefined): Promise<void> => {
        setSelectedMemorandumId(id);
        setIsConfirmModalActive(true);
    };

    const deleteMemorandum = async (): Promise<void> => {
        setIsLoader(true);
        await communication.deleteMemorandum(selectedMemorandumId);
        setIsConfirmModalActive(false);
        getMemorandums();
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            <Modal modalVisible={isConfirmModalActive} closeModal={() => setIsConfirmModalActive(false)}>
                <ConfirmModal
                    close={() => setIsConfirmModalActive(false)}
                    message={t('pages.memos.deleteMemo').text}
                    action={deleteMemorandum}
                />
            </Modal>
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendDocumentModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            getMemorandums().then(() => setIsLoader(false));
                        }}
                        loading={() => setIsLoader(true)}
                        memorandum={true}
                        memoId={selectedMemorandumId}
                        modalVisible={modalVisible}
                    />
                </Sidebar>
            )}
            <Header>
                <div className="title-and-button">
                    <h1>{t('navbar.pausal.memorandums').text}</h1>

                    <div className="header-options-buttons">
                        <Button
                            className={'button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                            icon={true}
                            height={35}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {t('pages.memos.newMemo').text}
                        </Button>
                        {isDropdownVisible && (
                            <DropdownContainer>
                                <Dropdown arrowLeft="53px" itemList={items} />
                            </DropdownContainer>
                        )}
                    </div>
                </div>

                <Filters>
                    <div className="statuses-wrapper">
                        <ul>
                            {statusSelectOptions.map((s) => {
                                return (
                                    <li
                                        key={s.value}
                                        onClick={() => setStatus(s.value)}
                                        className={`status-item ${s.value === status ? 'active' : ''}`}
                                    >
                                        {s.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="filter-bottom">
                        <div className="filter">
                            <label className="show-2">{t('pages.memos.filters.show.label').text}</label>
                            <SelectComponent
                                optionList={showSelectOptions}
                                defaultSelectedOption={showSelectOptions[0]}
                                color={colors.gray}
                                className={'select-items'}
                                handleSelectedValue={(value: any) => {
                                    setLimit(value);
                                }}
                                width={'70px'}
                            />
                            <label className="show-2">{t('pages.memos.memoPlural').text}</label>
                        </div>
                        <div className="filter-search">
                            <Search
                                placeholder={t('pages.memos.filters.search').text}
                                value={find}
                                onChange={(value: string) => {
                                    setShowSearch(true);
                                    setFind(value);
                                }}
                                onLeaveOrEnter={(value: string) => {
                                    setFind(value);
                                    setShowSearch(false);
                                    getMemorandums();
                                }}
                                showSearchNote={showSearch}
                                noteColor={colors.white}
                                className="select-search-filter"
                            />
                        </div>
                    </div>
                </Filters>
            </Header>
            <Content>
                {memoData && memoData.data && memoData.data.length > 0 ? (
                    memoData?.data?.map((memorandum: ObjectKeys, index: any) => (
                        <MemorandumCard
                            key={index}
                            memorandum={memorandum}
                            handleSend={handleSend}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    ))
                ) : (
                    <NoItems text={t('pages.memos.noMemos').text} />
                )}
            </Content>
            {memoData?.data?.length > 0 && memoData?.meta?.pagination?.total_pages > 1 && (
                <Pagination
                    pageCount={memoData?.meta?.pagination?.total_pages}
                    initialPage={0}
                    onPageChange={(e: object) => {
                        handlePageClick(e);
                    }}
                    nextLabel={(memoData?.meta?.pagination?.current_page === memoData?.meta?.pagination?.total_pages
                        ? ''
                        : '>'
                    ).toString()}
                    previousLabel={(memoData?.meta?.pagination?.current_page === 1 ? '' : '<').toString()}
                />
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
`;
const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    .title-and-button {
        display: flex;
        align-items: center;
        gap: 35px;
        @media only screen and (max-width: 426px) {
            flex-direction: column;
            gap: 5;
        }
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }

    .header-options-buttons {
        position: relative;
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
    }
    .find-icon {
        position: absolute;
        align-self: flex-start;
        justify-self: center;
        top: 50%;
        transform: translateY(-50%);
        left: 9px;
    }
`;
const DropdownContainer = styled.div`
    top: 60px;
    width: 200px;
    right: 0px;
    position: absolute;
    background-color: var(--white);
`;

const Content = styled.div``;

const Filters = styled.div`
    display: inline-block;
    width: 100%;
    padding: 20px 0;

    .statuses-wrapper {
        display: flex;
        background-color: var(--purple);
        padding: 10px 30px;
        align-items: center;
        p,
        li {
            color: var(--white);
            text-transform: uppercase;
            font-size: 14px;
        }
        ul {
            display: flex;
            .status-item {
                cursor: pointer;
                padding: 11px 1rem;
                list-style: none;
                &.active {
                    border-bottom: 3px solid var(--white);
                }
            }
            @media screen and (max-width: 426px) {
                display: block;
            }
        }
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start;
        }
        .filter {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show-2 {
            font-size: 14px;
            color: var(--gray);
            text-transform: uppercase;
        }
    }
`;

export default Memorandums;
