import { ReactElement } from 'react';
import styled from 'styled-components';

interface Instruction {
    id: number;
    title: string;
    link_rs: string;
    link_en: string;
    link_ru: string;
}

interface AccordionProps {
    instructions: Instruction[];
    lang: 'rs' | 'en' | 'ru';
}

const Accordion = ({ instructions, lang }: AccordionProps) => {
    return (
        <AccordionContainer>
            {instructions.map((instruction, index) => (
                <AccordionItemComponent
                    key={instruction.id}
                    index={index}
                    title={instruction.title}
                    link={instruction[`link_${lang}`]}
                />
            ))}
        </AccordionContainer>
    );
};

interface AccordionItemProps {
    title: string;
    link: string;
    index: number;
}

const AccordionItemComponent = ({ title, link, index }: AccordionItemProps): ReactElement => {
    const handleClick = () => {
        window.open(link, '_blank');
    };

    return (
        <AccordionItem>
            <AccordionHeader onClick={handleClick}>
                {index + 1}. {title}
            </AccordionHeader>
        </AccordionItem>
    );
};

export { Accordion, AccordionItemComponent };

const AccordionContainer = styled.div`
    border: 1px solid #ddd;
    margin-bottom: 1rem;
`;

const AccordionItem = styled.div`
    &:last-child {
        .acc-header {
            border-bottom: 0;
        }
    }
`;

const AccordionHeader = styled.div`
    background: #fff;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    border-bottom: 1px solid #ddd;

    &:hover {
        background: var(--light-gray);
    }
`;
