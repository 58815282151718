import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import Doughnut from './doughnut';
import BarChart from './barChart';
import useWindowSize from '../../../hooks/useResize';
import { useState } from 'react';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';

interface SplitScreenProps {
    leftComponent: ReactNode;
    rightComponent: ReactNode;
}

const SplitScreen: React.FC<SplitScreenProps> = ({ leftComponent, rightComponent }) => {
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);
    return (
        <>
            {isMobileWidth ? (
                <Mobile>{t('pages.reports.charts.mobile').text}</Mobile>
            ) : (
                <SplitScreenContainer>
                    <LeftHalf>{leftComponent}</LeftHalf>
                    <RightHalf>{rightComponent}</RightHalf>
                </SplitScreenContainer>
            )}
        </>
    );
};

const Charts: React.FC = () => {
    return <SplitScreen leftComponent={<Doughnut />} rightComponent={<BarChart />} />;
};
const SplitScreenContainer = styled.div`
    display: flex;
    flex-direction: column; /* Stack items vertically by default */
    height: 80vh;

    @media only screen and (min-width: 768px) {
        flex-direction: row; /* Switch to horizontal layout on screens wider than 768px */
    }
`;

const LeftHalf = styled.div`
    flex: 1;
    border-right: 1px solid #ccc;
    padding: 20px;
`;

const RightHalf = styled.div`
    flex: 1;
    padding: 20px;
`;
const Mobile = styled.div`
    margin-top: 50px;
    padding: 20px;
    text-align: center;
`;

export default Charts;
