import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCopy, faTrashCan, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import communication from '../../communication';
import colors from '../../global/colors';
import moment from 'moment';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import { useNavigate } from 'react-router-dom';

interface MemorandumCardType {
    memorandum: {
        created_at?: string;
        heading?: string;
        id?: string;
        object?: string;
        status?: string;
        template?: string;
        updated_at?: string;
        user?: any;
        user_id?: string;
    };
    handleEdit: (id: string | undefined) => void;
    handleSend: (id: string | undefined) => void;
    handleDelete: (id: string | undefined) => void;
}

const MemorandumCard: FunctionComponent<MemorandumCardType> = ({
    memorandum,
    handleEdit,
    handleSend,
    handleDelete,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const token = localStorage.getItem('access_token');

    const navigate = useNavigate();

    const handleDownload = async (id: string | undefined): Promise<void> => {
        const memorandumName = memorandum.heading ? memorandum.heading : 'memorandum';
        communication.downloadMemorandum(id, token).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${memorandumName}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    };

    const edit = (id: string | undefined): void => {
        handleEdit(id);
    };

    const handleDuplicate = (id: string | undefined): void => {
        navigate(`/memorandums/copy/${id}`, { state: { memorandum: memorandum } });
    };

    const handleOnClick = (id: string | undefined): void => {
        memorandum.status !== 'sent' ? edit(id) : handleDownload(id);
    };

    return (
        <Card>
            <Content>
                <div className={'header'} onClick={() => handleOnClick(memorandum?.id)}>
                    <span>{t('pages.memos.name').text}</span>
                    <p>{memorandum.heading}</p>
                </div>
                <div className={'status'} onClick={() => handleOnClick(memorandum?.id)}>
                    <span>Status</span>
                    <p className={memorandum.status === 'sent' ? 'sent' : 'in-progress'}>
                        {memorandum.status === 'sent' ? t('pages.memos.sent').text : t('pages.memos.draft').text}
                    </p>
                </div>
                <div className={'date'} onClick={() => handleOnClick(memorandum?.id)}>
                    <span>{t('pages.memos.date').text}</span>
                    <p>{moment(memorandum.created_at).format('D.MM.YYYY')}</p>
                </div>
                <div className={'actions'}>
                    {memorandum.status !== 'sent' && (
                        <Icon>
                            <FontAwesomeIcon
                                onClick={() => handleEdit(memorandum.id)}
                                className={'icon'}
                                icon={faEdit}
                                color={colors.gray}
                                style={{ color: colors.blue }}
                            />
                            <div className="label">{t('pages.invoiceCard.dropdownItems.change').text}</div>
                        </Icon>
                    )}
                    <Icon>
                        <FontAwesomeIcon
                            onClick={() => handleDuplicate(memorandum?.id)}
                            className={'icon'}
                            icon={faCopy}
                            color={colors.gray}
                            style={{ color: colors.purple }}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.copy').text}</div>
                    </Icon>
                    <Icon>
                        <FontAwesomeIcon
                            onClick={() => handleDelete(memorandum?.id)}
                            className={'icon'}
                            icon={faTrashCan}
                            color={colors.gray}
                            style={{ color: colors.danger }}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.delete').text}</div>
                    </Icon>
                    <Icon>
                        <FontAwesomeIcon
                            onClick={() => handleSend(memorandum?.id)}
                            className={'icon'}
                            icon={faEnvelope}
                            color={colors.gray}
                            style={{ color: colors.blue }}
                        />
                        <div className="label">{t('pages.invoiceCard.dropdownItems.send').text}</div>
                    </Icon>
                </div>
            </Content>
        </Card>
    );
};

const Card = styled.div`
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
    max-width: 25%;
    display: inline-block;
    @media (max-width: 1500px) {
        flex: 1 1 33%;
        max-width: 33%;
        -ms-flex: 1 1 33%;
    }
    @media (max-width: 1240px) {
        flex: 1 1 50%;
        max-width: 50%;
        -ms-flex: 1 1 50%;
    }
    @media (max-width: 600px) {
        flex: 1 1 100%;
        max-width: 100%;
        -ms-flex: 1 1 100%;
    }
    @media (min-width: 1800px) {
        max-width: 19%;
    }
`;

const Content = styled.div`
    background: var(--white);
    color: var(--gray);
    min-height: 215px;
    padding: 20px 17px;
    margin: 0 26px 30px 0;
    border: 1px solid var(--purple);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    cursor: pointer;
    &:hover {
        -webkit-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
    }
    .header {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
    }
    span {
        display: block;
        line-height: 17px;
        font-size: 12px;
    }

    p {
        display: block;
        line-height: 17px;
        font-size: 16px;
        font-weight: 400;
        color: var(--black);
    }
    .in-progress {
        background: var(--gray);
        font-size: 12px;
        display: inline;
        padding: 0 10px 2px;
        line-height: 20px;
        border-radius: 5px;
        color: var(--light-gray);
    }

    .sent {
        background: var(--blue);
        font-size: 12px;
        display: inline;
        padding: 0 10px 2px;
        line-height: 20px;
        border-radius: 5px;
        color: var(--light-gray);
    }
    .status {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        -ms-flex-item-align: end;
        align-self: flex-end;
    }
    .date {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        -ms-flex-item-align: end;
        align-self: flex-end;
        color: var(--gray);
    }
    .actions {
        -ms-flex-item-align: end;
        align-self: flex-end;
        text-align: center;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: flex;
    }
    .icon {
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
        height: 20px;
    }
`;
export const Icon = styled.div`
    position: relative;
    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            top: -35px;
            opacity: 1;
            transition: top 0.5s, opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;

export default MemorandumCard;
