import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatClientParams } from '../../../functions/formatClientParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import Button, { ButtonVariant } from '../../../components/button';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import Filters from './filters';
import DeleteModal from './deleteModal';
import SendDocumentModal from './sendModal';
import useParamsStore from '../../../store/params';
import useYearsStore from '../../../store/years';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';

const Proforma: FunctionComponent = () => {
    const page = useParamsStore.getState().p_page;
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const agency = useAgencyStore((props) => props.agency);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();

    const token = getAccessToken();

    const [proforma, setProforma] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');
    const [clientEmail, setClientEmail] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [qrCode, setQrCode] = useState('');

    const [display, setDisplay] = useState('list');
    const [clickedProforma, setClickedProforma] = useState('');

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: '',
        searchJoin: 'and',
        year: '',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const formattedParams = useMemo(() => formatClientParams(params.search), [params]);

    useEffect(() => {
        getQuotation();
    }, [formattedParams, params.limit, params.page, params.year]);

    useEffect(
        () => () => {
            if (page > 1) {
                setProforma([]);
                setParams({ ...params, page: 1 });
                useParamsStore.setState({ p_page: 1 });
            }
        },
        [],
    );

    useEffect(() => {
        useParamsStore.setState({ p_page: params.page });
    }, [params.page]);

    useEffect(() => {
        communication
            .getQuotationYears()
            .then((res: ObjectKeys) => {
                useYearsStore.setState({ quotationYears: res?.data?.years });
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    useYearsStore.setState({ quotationYears: [] });
                }
            });
    }, []);

    const getQuotation = (): void => {
        setShowSkeleton(true);
        communication
            .getProforma({
                ...params,
                page: page ? page : 1,
                search: formattedParams,
                searchFields: 'currency:=;search:like%;status:=;year:=',
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setShowSkeleton(false);
                    setProforma(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setShowSkeleton(false);
                    setProforma([]);
                }
            });
    };

    //Proforma Change
    const handleChange = (proformaId: string): void => {
        navigate(`/proforma/edit/${proformaId}`);
    };

    //Proforma Copy
    const handleCopy = (proformaId: string): void => {
        navigate(`/proforma/copy/${proformaId}`);
    };

    //Proforma Delete Modal
    const handleDeleteModal = (id: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedProforma={id}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => getQuotation()}
            />,
        );
    };

    //Proforma Download
    const handleDownloadProforma = (
        proformaId: string,
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        pForma: ObjectKeys,
    ): void => {
        generateQrCode(
            agencyData.name,
            clientData.company_name,
            amount,
            proformaNumber,
            bankAccount,
            currency,
            convert,
            `00${proformaNumber.replace('/', '-')}`,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res)
                    communication.downloadProforma(
                        proformaId,
                        token,
                        pForma.quotation_number,
                        pForma.currency !== 'RSD',
                        res?.i ? res?.i : '',
                    );
            })
            .catch((error: any) => {
                communication.downloadProforma(proformaId, token, pForma.quotation_number, pForma.currency !== 'RSD');
                console.error(error);
            });
    };
    const handleClickedProforma = (proformaId: string): void => {
        setClickedProforma(proformaId);
    };
    const handleSendModal = (
        proformaId: string,
        proformaClientEmail: string,
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        setClickedProforma(proformaId);
        setClientEmail(proformaClientEmail);
        generateQrCode(
            agencyData.name,
            clientData.company_name,
            amount,
            proformaNumber,
            bankAccount,
            currency,
            convert,
            `00${proformaNumber?.replace('/', '-')}`,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res) setQrCode(res?.i ? res?.i : '');
                setSendModal(true);
            })
            .catch((error: any) => {
                setQrCode('');
                console.error(error);
                setSendModal(true);
            });
    };
    const handleSendInvoice = (): void => {
        setClickedProforma('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new proforma
    const handleAddProforma = (proformaType: string): void => {
        navigate(`/proforma/add/${proformaType}`);
    };

    // Create invoice from proforma
    const handleInvoice = (proformaData: ObjectKeys): void => {
        setLoaderVisible(true);
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = 'false';
        navigate(`/invoices/copy/${proformaData.id}/quotation`);
    };
    // Create advance from proforma
    const handleAdvance = (proformaData: ObjectKeys): void => {
        setLoaderVisible(true);
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = true;
        navigate(`/advance-invoices/copy/${proformaData.id}/quotation`);
    };

    return (
        <>
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedProforma}
                        success={() => {
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        setProforma={setProforma}
                        clientEmail={clientEmail}
                        modalVisible={sendModal}
                        qrCode={qrCode}
                    />
                </Sidebar>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="proforma page">
                <Header>
                    <div>
                        <h1>{t('pages.proforma.title').text}</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    setAddInvoiceDropdown(!addInvoiceDropdown);
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.proforma.newProforma').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.proforma.addProformaDropdown.proforma').text,
                                                handler: () => handleAddProforma('domestic'),
                                            },
                                            {
                                                label: t('pages.proforma.addProformaDropdown.foreign').text,
                                                handler: () => handleAddProforma('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                </Header>
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                {showSkeleton ? (
                    Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                ) : (
                    <>
                        <InvoicesList className={`display-${display}`}>
                            {proforma?.data?.length > 0 ? (
                                proforma?.data?.map((pForma: ObjectKeys, index: number) => {
                                    return (
                                        <div key={pForma.id} className={`${display}`}>
                                            <InvoiceCard
                                                type={InvoiceCardComponentType.Proforma}
                                                dropdownDirection={index > 6 ? 'up' : 'down'}
                                                cardData={pForma}
                                                cardDisplay={display}
                                                handleChange={() => handleChange(pForma.id)}
                                                handleCopy={() => handleCopy(pForma.id)}
                                                handleDelete={() => handleDeleteModal(pForma?.id)}
                                                handleDownload={() =>
                                                    handleDownloadProforma(
                                                        pForma?.id,
                                                        agency,
                                                        pForma?.client?.data,
                                                        pForma.value_in_rsd,
                                                        pForma.quotation_number,
                                                        pForma.bank_account,
                                                        pForma.currency,
                                                        true,
                                                        pForma,
                                                    )
                                                }
                                                handleInvoice={() => handleInvoice(pForma)}
                                                handleAdvance={() => handleAdvance(pForma)}
                                                handleSend={() =>
                                                    handleSendModal(
                                                        pForma.id,
                                                        pForma.client.data.email ? pForma.client.data.email : '',
                                                        agency,
                                                        pForma?.client?.data,
                                                        pForma.value_in_rsd,
                                                        pForma.quotation_number,
                                                        pForma.bank_account,
                                                        pForma.currency,
                                                        true,
                                                    )
                                                }
                                                handleClick={() => handleClickedProforma(pForma.id)}
                                                eInvoice={pForma?.is_einvoice}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <NoItems text={t('pages.invoices.noItems.descriptionProforma').text} />
                            )}
                        </InvoicesList>
                        {proforma?.data?.length > 0 && (
                            <Pagination
                                pageCount={proforma.meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => {
                                    setParams({ ...params, page: e.selected + 1 });
                                    useParamsStore.setState({ p_page: e.selected + 1 });
                                }}
                                nextLabel={`${
                                    proforma.meta?.pagination?.current_page === proforma.meta?.pagination?.total_pages
                                        ? ''
                                        : '>'
                                }`}
                                previousLabel={`${proforma.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={proforma.meta?.pagination?.current_page - 1}
                            />
                        )}
                    </>
                )}
            </PageWrapper>
        </>
    );
};
export default Proforma;

const PageWrapper = styled.div``;
const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: 1700px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
