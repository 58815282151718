import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { z } from 'zod';

const serbianIbanRegex = /^RS[0-9]{2}[0-9]{3}[0-9]{13}[0-9]{2}$/;

const useForeignBankAccountSchema = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const ForeignBankAccountSchema = z.array(
        z.object({
            bank_id: z
                .string({ message: t('pages.agency.bankAccounts.foreign.bank_required').text })
                .min(1, { message: t('pages.agency.bankAccounts.foreign.bank_required').text }),
            currency: z
                .string({ message: t('pages.agency.bankAccounts.foreign.currency_required').text })
                .min(3, { message: t('pages.agency.bankAccounts.foreign.currency_required').text }),
            iban: z
                .string({ message: t('pages.agency.bankAccounts.foreign.iban_length').text })
                .min(15, { message: t('pages.agency.bankAccounts.foreign.iban_length').text })
                .max(34, { message: t('pages.agency.bankAccounts.foreign.iban_length').text }),
            swift: z
                .string({ message: t('pages.agency.bankAccounts.foreign.swift_required').text })
                .min(1, { message: t('pages.agency.bankAccounts.foreign.swift_required').text }),
        }),
    );

    return { ForeignBankAccountSchema };
};

export default useForeignBankAccountSchema;
