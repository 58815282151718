import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import NewUsers, { Data, Heading, Row, Table } from './newUsers';
import Payment from './payment';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import Users from './users';
import exportToExcel from '../../../functions/downloadXlsx';
import { usePasswordProtect } from '../../../hooks/passwordProtect';
import Loader from '../../../components/loader';

const Statistics: FunctionComponent = () => {
    usePasswordProtect(['/admin/statistics']);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [tabIndex, setTabIndex] = useState(1);
    const [users, setUsers] = useState([]);

    const [loader, setLoader] = useState<boolean>(false);

    const tabs = [
        t('pages.admin.statistics.payment').text,
        t('pages.admin.statistics.bankNewUsers').text,
        t('pages.admin.statistics.bankUsers').text,
        t('pages.admin.statistics.soGe').text,
        t('pages.admin.statistics.activeUsers').text,
    ];

    async function getData(): Promise<void> {
        setLoader(true);
        const res = await communication.getUsers();
        setUsers(res.data.data);
        setLoader(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {loader && <Loader />}
            <Container>
                <h1>{t('pages.admin.statistics.title').text}</h1>
                <TabsWrapper>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList>
                            {tabs.map((tab, index) => (
                                <Tab
                                    onClick={async () => {
                                        if (tab === t('pages.admin.statistics.activeUsers').text) {
                                            getData().then(() => {
                                                exportToExcel(
                                                    'download-users',
                                                    'Aktivni korisnici',
                                                    'Aktivni korisnici.xlsx',
                                                );
                                            });
                                        }
                                    }}
                                    key={index}
                                >
                                    {tab}
                                </Tab>
                            ))}
                        </TabList>

                        {tabs.map((tab, index) => (
                            <TabPanel key={index} className={tab}>
                                {index === 0 ? (
                                    <Payment t={t} />
                                ) : index === 1 ? (
                                    <NewUsers t={t} />
                                ) : index === 2 ? (
                                    <Users t={t} />
                                ) : (
                                    <></>
                                )}
                            </TabPanel>
                        ))}
                    </Tabs>
                </TabsWrapper>

                {/* table for xlsx download active users */}
                {users && (
                    <Table style={{ display: 'none' }} id="download-users">
                        <tbody>
                            <Row>
                                <Heading className="data">{t('pages.admin.statistics.users.id').text}</Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.email').text}</Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.firstName').text}</Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.lastName').text}</Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.jmbg').text}</Heading>
                                <Heading className="data">
                                    {t('pages.admin.statistics.users.accountExprirationDate').text}
                                </Heading>
                                <Heading className="data">
                                    {t('pages.admin.statistics.users.registrationDate').text}
                                </Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.lastLogin').text}</Heading>
                                <Heading className="data">{t('pages.admin.statistics.users.pib').text}</Heading>
                                <Heading className="data">
                                    {t('pages.admin.statistics.users.identificationNumber').text}
                                </Heading>
                                <Heading className="data">
                                    {t('pages.admin.statistics.users.activityCode').text}
                                </Heading>
                                <Heading className="data">{'authorizations'}</Heading>
                                <Heading className="data">{'package'}</Heading>
                                <Heading className="data">{'citizenship'}</Heading>
                            </Row>
                            {users &&
                                users?.map((v: ObjectKeys, key: any) => (
                                    <Row key={key}>
                                        <Data>{v.id}</Data>
                                        <Data>{v?.email}</Data>
                                        <Data>{v?.first_name}</Data>
                                        <Data>{v?.last_name}</Data>
                                        <Data>{v?.jmbg}</Data>
                                        <Data>{v?.account_expiration_date}</Data>
                                        <Data>{v?.registration_date}</Data>
                                        <Data>{v?.last_login}</Data>
                                        <Data>{v?.pib}</Data>
                                        <Data>{v?.identification_number}</Data>
                                        <Data>{v?.activity_code}</Data>
                                        <Data>{v?.authorised ? 'DA' : 'NE'}</Data>
                                        <Data>{v?.package_name ? v.package_name : '-'}</Data>
                                        <Data>{v?.country.data ? v.country.data.name : '-'}</Data>
                                    </Row>
                                ))}
                        </tbody>
                    </Table>
                )}
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
`;
const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid var(--border-color);

    .react-tabs {
        width: 100%;
    }

    .react-tabs__tab-list {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        .react-tabs__tab {
            font-size: 17px;
            padding: 20px;
            border-bottom: 2px solid transparent;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                border-color: var(--purple);
                color: var(--black);
                border-radius: 0;
                border-bottom: 2px solid var(--purple);
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
            :hover {
                &.react-tabs__tab {
                    border-bottom: 2px solid var(--border-color);
                }
            }
        }
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
    .react-tabs__tab-panel,
    .react-tabs__tab-panel--selected {
        /* background: var(--white); */
        /* padding: 20px; */
    }
`;

export default Statistics;
