import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Modal from '../../../components/modal';
import ConfirmModal from '../../../components/confirmModal';
import moment from 'moment';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Success from '../../../components/successModal';
import Loader from '../../../components/loader';
import useAgencyStore from '../../../store/agency';
import useProfileStore from '../../../store/profile';
import CartCard from './cartCard';
import Pagination from '../../../components/pagination';
import useServicesStore from '../../../store/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Input, { InputComponentType } from '../../../components/input';
import SelectSearch from '../../../components/selectSearch';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import { ToastContainer, toast } from 'react-toastify';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};

const Wallet: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const lang = getCurrentLanguage();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const [questionModal, setQuestionModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [loader, setLoader] = useState<boolean>(false);

    const { agency } = useAgencyStore();
    const userID = agency.user.data.id;
    const isTrial = agency.user.data.trial;
    const isActive = agency.user.data.active;
    const reccuringPayment = agency.user.data.execute_recurring_payment;
    const reccuringFailed = agency.user.data.recurring_failed;
    const userPackage = !isActive
        ? t('pages.agency.wallet.expired').text
        : isTrial
        ? t('navbar.main.trial').text
        : agency.user.data.payment_package.data.name;
    const [period, setPeriod] = useState<string>('');
    const [enabledSubscription, setEnabledSubscription] = useState(reccuringPayment);

    const params = useParams();
    const isActivationSuccess = params?.success && params.success === 'success' ? true : false;

    const [successfullyActivated, setSuccessfullyActivated] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [servicesOptions, setServicesOptions] = useState<SelectOption[]>([]);

    const [searchOption, setSearchOption] = useState('');
    const didMountRef = useRef<boolean>(false);
    const [searchDate, setSearchDate] = useState<Date | null>(null);

    const handleCheckboxChange = (option: string): void => {
        setSearchOption((prevOption) => (prevOption === option ? '' : option));
    };

    useEffect(() => {
        if (searchOption === 'option2' && searchDate) {
            const date = new Date(searchDate);

            // Ensuring single digits are padded with a zero
            const formattedDate =
                date.getFullYear() +
                '-' +
                String(date.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(date.getDate()).padStart(2, '0');
            setFilters({
                ...filters,
                search: `status:completed;created_at:${formattedDate};`,
                searchFields: 'created_at:like;status:like;',
            });
        }
    }, [searchDate]);

    const [cartsData, setCartsData] = useState<ObjectKeys>();

    const [filters, setFilters] = useState<ObjectKeys>({
        page: 1,
        limit: 10,
        orderBy: 'created_at',
        sortedBy: 'desc',
        search: 'status:completed;',
        searchFields: 'status:like;',
        searchJoin: 'and',
    });

    const handlePageClick = (e: any): void => {
        setFilters({ ...filters, page: e.selected + 1 });
    };

    const [expires, setExpires] = useState();
    const handleTogglePayment = (): void => {
        setModalMessage(t('pages.agency.subscription.are_you_sure_cancel_subscription').text);
        setQuestionModal(true);
    };
    const handlePayment = (): void => {
        setLoader(true);
        const formData = {
            execute_recurring_payment: false,
        };
        communication.updateUser(formData, userID).then((res: ObjectKeys) => {
            if (res.status === 200) {
                useAgencyStore.setState((prevState) => ({
                    agency: {
                        ...prevState.agency,
                        user: {
                            ...prevState.agency.user,
                            data: {
                                ...prevState.agency.user.data,
                                execute_recurring_payment: res.data.data.execute_recurring_payment,
                            },
                        },
                    },
                }));
                setLoader(false);
                setEnabledSubscription(res.data.data.execute_recurring_payment);
            }
        });
        setQuestionModal(false);
    };

    const handlePaymentModal = (): void => {
        setModalMessage(t('pages.agency.wallet.sure_payment_now').text);
        setPaymentModal(true);
    };

    const handleDirectPayment = (): void => {
        setLoader(true);
        setPaymentModal(false);
        communication.payViaToken().then((res: ObjectKeys) => {
            if (res.status === 204) {
                setLoader(false);
                setPaymentSuccess(true);
                setTimeout(() => {
                    communication.getAgency().then((agencyData: ObjectKeys) => {
                        useAgencyStore.setState({ agency: agencyData.data.data });
                        useProfileStore.setState({ profile: agencyData.data.data.user.data });
                    });
                }, 4000);
            } else {
                setLoader(false);
                toast.error(t('suggestion.error').text);
            }
        });
    };

    const getCarts = (): void => {
        setLoader(true);
        communication
            .getUserCarts(filters)
            .then((response: ObjectKeys) => {
                setCartsData(response.data);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getPeriodCarts = (): void => {
        setLoader(true);
        communication
            .getActivePackagePeriod()
            .then((response: ObjectKeys) => {
                if (response.data.data) {
                    const months = Number(response.data.data.transaction.data.package);
                    const packageMonths = !isActive
                        ? ''
                        : isTrial
                        ? ''
                        : months === 1
                        ? t('pages.agency.subscription.one_month').text
                        : months === 6
                        ? t('pages.agency.subscription.half_year').text
                        : months === 12
                        ? t('pages.agency.subscription.one_year').text
                        : '';
                    setPeriod(packageMonths);
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getAllServices = (): void => {
        setLoader(true);
        communication
            .getAllAdminServices({ page: 1, limit: 1000, orderBy: `name_${lang}`, sortedBy: 'asc' })
            .then((response: ObjectKeys) => {
                const options: SelectOption[] = response.data.data.map((item: ObjectKeys) => ({
                    value: item.id,
                    label: item[`name_${lang}`],
                }));

                setServicesOptions(options);

                useServicesStore.setState(() => ({
                    servicesData: response.data.data,
                }));
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoader(false);
            });
    };

    const getUserCartById = (id: string): void => {
        setLoader(true);
        communication
            .getUserCartById(id)
            .then((response: ObjectKeys) => {
                setCartsData(response.data);
                setLoader(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setCartsData([]);
                setLoader(false);
            });
    };

    useEffect(() => {
        getAllServices();
        getPeriodCarts();
        if (agency) {
            setExpires(agency.user.data.account_expiration_date);
        }
        if (isActivationSuccess) {
            setSuccessfullyActivated(true);
        }
    }, []);

    useEffect(() => {
        getCarts();
    }, [filters]);

    useEffect(() => {
        if (lang) {
            getAllServices();
        }
    }, [lang]);

    useEffect(() => {
        // Skip the first execution on mount and execute getCarts() when both are unchecked
        if (didMountRef.current) {
            if (searchOption !== 'option2') {
                setFilters({
                    ...filters,
                    search: 'status:completed;',
                    searchFields: 'created_at:like;status:like;',
                });
                setSearchDate(null);
            }
        } else {
            didMountRef.current = true;
        }
    }, [searchOption]);

    return (
        <>
            {loader && <Loader />}
            <ToastContainer />
            {successfullyActivated && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullyActivated(false)}>
                    <Success
                        close={() => setSuccessfullyActivated(false)}
                        message={t('pages.agency.wallet.activate_success').text}
                    />
                </Modal>
            )}
            {paymentSuccess && (
                <Modal modalVisible={true} closeModal={() => setPaymentSuccess(false)}>
                    <Success
                        close={() => setPaymentSuccess(false)}
                        message={t('pages.agency.wallet.success_payment_call').text}
                    />
                </Modal>
            )}
            {questionModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setQuestionModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            handlePayment();
                        }}
                        close={() => {
                            setQuestionModal(false);
                        }}
                        active={questionModal}
                        message={modalMessage}
                    />
                </Modal>
            )}
            {paymentModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setPaymentModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            handleDirectPayment();
                        }}
                        close={() => {
                            setPaymentModal(false);
                        }}
                        active={paymentModal}
                        message={modalMessage}
                    />
                </Modal>
            )}
            <Content>
                <ToggleRow>
                    <div className={reccuringFailed ? 'info-wrapper' : ''}>
                        <p>{t('pages.agency.subscription.continuing_payment').text}</p>
                        {reccuringFailed ? (
                            <span className="payment-error">{t('pages.agency.wallet.card_payment_error').text}</span>
                        ) : null}
                    </div>

                    <div className="button-container">
                        {reccuringPayment ? (
                            <>
                                {reccuringFailed ? (
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        size={120}
                                        onClick={() => handlePaymentModal()}
                                        className="enabled-button"
                                    >
                                        {t('pages.agency.wallet.pay_now').text}
                                    </Button>
                                ) : (
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        size={100}
                                        onClick={() => handleTogglePayment()}
                                        className="enabled-button"
                                    >
                                        {t('pages.agency.wallet.disable').text}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                variant={ButtonVariant.solid}
                                color={'var(--gray)'}
                                size={100}
                                onClick={() => navigate('/settings/subscription/1')}
                            >
                                {t('pages.agency.wallet.enable').text}
                            </Button>
                        )}
                    </div>
                </ToggleRow>
                <ToggleRow>
                    <p>
                        {t('pages.agency.wallet.package').text}: {userPackage} {period ? `- ${period}` : ''}
                    </p>
                    <p className="changeButton" onClick={() => navigate('/settings/subscription/1')}>
                        {t('pages.agency.wallet.change').text}
                    </p>
                </ToggleRow>
                <ToggleRow>
                    <p>{t('pages.agency.wallet.accExpire').text}</p>{' '}
                    <span>{moment(expires).format('DD.MM.YYYY.')}</span>
                </ToggleRow>
                <PageContainer>
                    <div className="search-services">
                        <p className="choseService">{t('pages.agency.wallet.payment_history').text}:</p>
                        <div className="search-input-container">
                            <div className="checkboxes">
                                <p>{t('pages.agency.wallet.search_by').text}:</p>
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="searchOption1"
                                        checked={searchOption === 'option1'}
                                        onChange={() => handleCheckboxChange('option1')}
                                    />
                                    <label htmlFor="searchOption1">{t('pages.agency.wallet.service').text}</label>
                                </div>
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="searchOption2"
                                        checked={searchOption === 'option2'}
                                        onChange={() => handleCheckboxChange('option2')}
                                    />
                                    <label htmlFor="searchOption2">{t('pages.agency.wallet.date').text}</label>
                                </div>
                            </div>
                            {searchOption !== '' ? (
                                <div className="search-input">
                                    <FontAwesomeIcon icon={faSearch} />
                                    {searchOption === 'option1' ? (
                                        <SelectSearch
                                            optionList={servicesOptions}
                                            handleSelectedValue={(data: { value: string; label: string }) => {
                                                getUserCartById(data.value);
                                            }}
                                            searchPlaceholder={t('pages.agency.wallet.choose_service').text}
                                            placeholder={t('pages.agency.wallet.choose_service').text}
                                            className="select-with-search"
                                        />
                                    ) : (
                                        <Input
                                            type={InputComponentType.Date}
                                            date={searchDate}
                                            onChange={(value: Date) => setSearchDate(value)}
                                            inputClassName={'nbs-datepicker'}
                                            placeholder={t('pages.agency.wallet.choose_date').text}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {cartsData && cartsData.data.length > 0 ? (
                        <div className="cart-table-wrapper">
                            <CartTable>
                                <thead>
                                    <tr>
                                        <TableHeader colSpan={2}>{t('pages.agency.wallet.payment').text}</TableHeader>
                                        <TableHeader>{t('pages.agency.wallet.payment_date').text}</TableHeader>
                                        <TableHeader>{t('pages.agency.wallet.total_amount').text}</TableHeader>
                                        <TableHeader>{t('pages.agency.wallet.bill').text}</TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartsData.data.map((cart: any, index: number) => {
                                        // Calculate the serial number based on the current page and index in the current page
                                        const serialNumber = (filters.page - 1) * filters.limit + index + 1;
                                        return <CartCard key={cart.id} cart={cart} serialNumber={serialNumber} />;
                                    })}
                                </tbody>
                            </CartTable>
                        </div>
                    ) : (
                        <NoDataMessage>{t('pages.agency.wallet.no_payments_for_filter').text}</NoDataMessage>
                    )}
                    <Info>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <p>{t('pages.agency.wallet.more_info').text}</p>
                    </Info>
                </PageContainer>
                {cartsData && cartsData?.data?.length > 0 && cartsData.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={cartsData.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            cartsData.meta?.pagination?.current_page === cartsData.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${cartsData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={cartsData.meta?.pagination?.current_page - 1}
                    />
                )}
            </Content>
        </>
    );
};
const Content = styled.div`
    width: 70%;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
    .paymentList {
        margin-top: 20px;
        margin-bottom: 30px;
    }
`;
const PageContainer = styled.div`
    margin-top: 20px;
    border: 2px solid var(--purple);
    margin-bottom: 200px;

    .search-services {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 20px 0 20px;
    }
    .cart-table-wrapper {
        overflow: auto;
        padding: 0 20px;
        th {
            font-weight: 400;
        }
        th,
        td {
            font-size: 15px;
            color: var(--gray);
        }
    }

    .choseService {
        font-size: 15px;
        width: 100%;
        margin-bottom: 10px;
        color: var(--gray);
    }

    .search-input-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .search-input {
        display: flex;
        align-items: center;
        gap: 10px;
        /* background-color: #f0f0f0; */
        padding: 10px 0px;
        border-radius: 20px;
        width: 100%;
        margin-bottom: 10px;
    }

    .input {
        width: 100%;
        text-align: left;
        background-color: transparent;
        border: none;
        outline: none;
    }

    .checkboxes {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
        align-items: center;
        p {
            font-size: 15px;
            color: var(--gray);
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-size: 15px;
        color: var(--gray);
    }

    /* Custom checkbox styles */
    input[type='checkbox'] {
        accent-color: #9b7fe9;
    }

    @media (max-width: 768px) {
        .checkboxes {
            justify-content: flex-start;
        }
    }
    .select-with-search {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .select {
            width: 80%;
        }
    }
`;

const CartTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        font-size: 15px;
        color: var(--purple);
        justify-content: flex-start;
        p {
            font-size: 15px;
        }
    }
`;
const Info = styled.table`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    font-size: 15px;
    color: var(--purple);
    justify-content: flex-start;
    padding: 0px 20px 20px 20px;
    p {
        font-size: 15px;
    }
`;

const TableHeader = styled.th`
    padding: 10px;
`;

const NoDataMessage = styled.p`
    text-align: center;
    color: #999;
`;
const ToggleRow = styled.div`
    margin-top: 15px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);

    p {
        color: var(--gray);
        font-size: 15px;
        margin-right: 20px;
    }
    span {
        font-size: 15px;
    }
    :hover {
        p {
            color: var(--purple);
        }
        border-bottom: 1px solid var(--purple);
    }
    .changeButton {
        color: var(--purple);
        cursor: pointer;
        text-decoration: underline;
        margin-right: 0;
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .enabled-button {
            margin-right: 0;
            font-size: 14px;
        }
        .dissabled-button {
            margin-right: 0;
            font-size: 14px;
        }
    }
    .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .payment-error {
        color: red;
        font-size: 13px;
    }
`;
export default Wallet;
