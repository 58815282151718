import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useAllUsersStore from '../../../store/admin/allUsers';
import Pagination from '../../../components/pagination';
import { ObjectKeys } from '@/types/objectKeys';
import communication from '../../../communication';
import AdminSolutionCard from './adminSolutionCard';
import Input from '../../../components/input';
import DragAndDrop from '../../../components/dragAndDrop';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';

const AdminTaxSolutions: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const { allUsers } = useAllUsersStore();
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [page, setPage] = useState('1');
    const [solutions, setSolutions] = useState<ObjectKeys>([]);
    const [selectedYear, setSelectedYear] = useState<string>('');

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };
    useEffect(() => {
        setLoaderVisible(true);
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib };
        });
        setAllUsersOptions([{ value: 'All users', label: 'All users' }, ...userData]);
        setLoaderVisible(false);
    }, [allUsers]);

    useEffect(() => {
        if (selectedUserId === 'All users' || selectedUserId === '') {
            getAllSolutions();
        } else {
            getSolutionsByUserId(selectedUserId);
        }
    }, [page, selectedUserId]);

    const getAllSolutions = async (): Promise<void> => {
        setLoaderVisible(true);
        await communication.getAllSolutions(page).then((res: any) => {
            if (res.status === 200) {
                setLoaderVisible(false);
                setSolutions(res?.data);
            }
        });
    };

    const getSolutionsByUserId = async (userId: string): Promise<void> => {
        setSelectedUserId(userId);
        setLoaderVisible(true);
        await communication.getSolutionsByUser(userId, page).then((res: any) => {
            if (res.status === 200) {
                setLoaderVisible(false);
                setSolutions(res?.data);
            }
        });
    };

    const handleUpload = async (files: Blob[]): Promise<void> => {
        await communication.uploadFile(selectedUserId, files, selectedYear).then((res: any) => {
            if (res.status === 200) {
                setLoaderVisible(false);
                getSolutionsByUserId(selectedUserId);
            }
        });
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <PageWrapper className="page">
                <Header>
                    <h1>Poreska rešenja</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                </SelectWrapper>
                {selectedUserId !== 'All users' && (
                    <UploaderWrapper>
                        <p className="tax-info">Prvo unesite godinu za koju unosite poresko resenje za klijenta</p>
                        <Input
                            type={'text'}
                            value={selectedYear}
                            label={'Godina:'}
                            className={'year'}
                            onChange={(value: string) => setSelectedYear(value)}
                            maxLength={4}
                        />
                        {selectedYear.length === 4 && (
                            <div>
                                <DragAndDrop
                                    description={' '}
                                    className={'uploader'}
                                    multiple={true}
                                    uploadFunction={handleUpload}
                                />
                            </div>
                        )}
                    </UploaderWrapper>
                )}
                <Content>
                    {solutions?.data?.map((solution: ObjectKeys, index: number) => (
                        <AdminSolutionCard key={index} solution={solution} showEmail={selectedUserId === 'All users'} />
                    ))}
                </Content>
                {solutions?.data?.length > 0 && solutions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={solutions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            solutions.meta?.pagination?.current_page === solutions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${solutions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={solutions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const Content = styled.div`
    margin-top: 50px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        min-width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const UploaderWrapper = styled.div`
    .year {
        input {
            color: var(--gray);
            background: var(--white);
            border: 1px solid var(--light-gray);
            transition: opacity 0.4s ease-out;
            border-radius: 0;
            line-height: 2em;
            outline: none;
            border-radius: 2px;
            height: 34px;
            padding: 5px 5px 5px 10px;
            width: 100px;
            font-size: 14px;
            padding-right: 10px;
        }
    }
    .uploader {
        height: 150px;
        width: 150px;
        border: 2px solid var(--light-gray);
        background: #fff;
        padding: 0px;
        box-shadow: none;
        color: var(--light-gray);
        label {
            height: 150px;
            width: 150px;
        }
        .description {
            display: none;
        }
        .uploader-wrapper {
            min-height: 150px;
            height: 150px;
            width: 150px;
            padding: 0px;
            border: none;
            .upload-icon {
                height: 70px;
            }
        }
    }
`;
export default AdminTaxSolutions;
