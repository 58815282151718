import React, { FunctionComponent, useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import styled from 'styled-components';
import Loader from '../../../components/loader';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { generateQrCode } from '../../../communication/qr';
import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../types/objectKeys';
import { getAccessToken } from '../../../functions/auth';
import download from '../../../communication/download';

interface Props {
    onClose: Function;
    action: string;
}

const PayModal: FunctionComponent<Props> = ({ onClose }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = `${apiUrl}/transfers-print-pdf`;
    const [loader, setLoader] = useState<boolean>(false);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const [qrCode, setQrCode] = useState('');

    const domesticBankAccounts =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [newTransferOrder, setNewTransferOrder] = useState<ObjectKeys>({
        payment_slip_heading: 'nesto',
        orderer:
            `${agency.full_name}, ${agency.user.data.first_name} ${agency.user.data.last_name}, ${agency.city}`.slice(
                0,
                70,
            ),
        purpose_of_payment: agency.user.data.trial
            ? 'Digitalni test samostalnosti'
            : 'Digitalni test samostalnosti 30% popusta',
        recipient: 'CLEVER DOO',
        debtor_account: domesticBankAccounts ? domesticBankAccounts[0].value.account_number : '',
        model_number_debtor: '',
        model_number_recipient: '',
        year: '',
        currency: 'RSD',
        total_amount: agency.user.data.trial ? '4000' : '2800',
        payment_code: '221',
        recipient_account: '325-9500500271657-25',
        call_number_debtor: '32131231',
        call_number_recipient: agency.pib ? agency.pib : '',
        qr_code: `data:image/jpeg;base64,${qrCode}`,
        token: getAccessToken(),
    });

    useEffect(() => {
        setLoader(true);
        setLoader(false);
        generateQrCode(
            newTransferOrder.recipient,
            newTransferOrder.orderer,
            newTransferOrder.total_amount,
            newTransferOrder.purpose_of_payment,
            newTransferOrder.recipient_account,
            newTransferOrder.currency,
            true,
            `00${newTransferOrder.call_number_recipient}`,
            '221',
        )
            .then((response: ObjectKeys | any) => {
                if (response.i) {
                    setQrCode(response.i);
                    const updatedTransferOrder = {
                        ...newTransferOrder,
                        qr_code: `data:image/jpeg;base64,${response.i}`,
                    };
                    setNewTransferOrder(updatedTransferOrder);
                }
            })
            .catch((error: any) => {
                setQrCode('');
                console.error(error);
            });
    }, []);

    const printPdf = async (): Promise<void> => {
        download(url, newTransferOrder);
        onClose();
    };

    return (
        <Content>
            <Header>
                <h1>{t('pages.payModal.take_your_code').text}</h1>
                <p>{t('pages.payModal.get_paid').text}</p>
            </Header>

            {loader ? (
                <Loader />
            ) : (
                <Body>
                    <div className={'left-side'}>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.orderer').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={'Naziv firme'}
                                value={newTransferOrder.orderer}
                                readOnly
                            />
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.purpose_of_payment').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder.purpose_of_payment}
                                readOnly
                            />
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recipient').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder.recipient}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className={'right-side'}>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.payment_code').text}</span>
                            <input className={'input'} value={221} readOnly />
                        </div>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.currency').text}</span>
                            <input className={'input'} readOnly value={newTransferOrder.currency} />
                        </div>
                        <div className={'field l'}>
                            <span className={'label'}>{t('pages.transfers.amount').text}</span>
                            <input className={'input'} value={newTransferOrder.total_amount} readOnly />
                        </div>

                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recepient_bank_account').text}</span>
                            <input className={'input'} value={'325-9500500271657-25'} readOnly />
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.model_and_call_number_recipient').text}</span>
                            <input className={'input s'} value={''} readOnly />
                            <input
                                className={'input xxl input-left-margin'}
                                value={newTransferOrder.call_number_recipient}
                                readOnly
                            />
                        </div>
                        <div className={'footer'}>
                            <div className={'signature'}>
                                <span>{t('pages.transfers.transfer_order_signature').text}</span>
                            </div>
                            <div className={'signature'}>
                                <span>{t('pages.transfers.transfer_order_date_time').text}</span>
                            </div>
                            <div className={'signature'}>
                                <span>{t('pages.transfers.transfer_execution').text}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'qr-code'}>
                        <Button variant={ButtonVariant.outlined} color={colors.purple} onClick={() => printPdf()}>
                            {t('pages.transfers.print').text}
                        </Button>
                        {qrCode && <img src={`data:image/png;base64,${qrCode}`} />}
                    </div>
                </Body>
            )}
        </Content>
    );
};

const Content = styled.div`
    overflow: auto;
`;

const Header = styled.div`
    text-align: center;
    position: relative;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 2px 15px 20px;
    h1 {
        font-size: 24px;
        text-transform: uppercase;
        color: #4c595f;
        font-weight: 400;
    }
    .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
            no-repeat;
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        border: 0;
        &:hover {
            cursor: pointer;
        }
    }
`;

const Body = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    textarea {
        font-family: Open Sans, sans-serif;
        resize: none;
    }
    .top {
        width: 100%;
        .title {
            margin-bottom: 35px;
        }
        h2 {
            font-size: 18px;
            margin: 10px 10px;
        }
        input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: -webkit-fill-available;
            margin: 10px 10px;
        }
    }

    .accounts {
        margin-bottom: 30px;
        width: 100%;
        h2 {
            font-size: 18px;
            margin: 10px 10px;
        }
        .select-accounts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .select-wrapper {
                display: flex;
                margin: 10px 10px;
                width: 49%;
            }
            .select-wrapper:last-child {
                align-self: flex-end;
            }
        }
        .bank-name {
            width: 100%;
        }
        .bank-number {
            width: 100%;
        }
    }
    .left-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        .input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            min-height: 88px;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
        }
    }
    .right-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;

        .input {
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            min-height: auto;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
        }
    }
    .footer {
        margin: 10px 10px;
        .signature {
            border: 0;
            border-top: 1px solid #000;
            width: 210px;
            margin-top: 25px;
            display: block;
            margin-right: 40px;
            float: left;
        }
    }
    .qr-code {
        display: -ms-flexbox;
        display: flex;
        flex: 1;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        img {
            width: 200px;
        }
    }
    .field {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        margin: 10px 10px 2px 10px;
        overflow: hidden;

        .label {
            font-size: 13px;
            color: #92a5ac;
            display: block;
            margin-bottom: 5px;
            padding-left: 0px;
        }
    }

    .s {
        display: inline-table;
        float: left;
        width: 70px !important;
    }
    .m {
        display: inline-table;
        width: calc(29% - 10px) !important;
        margin-right: 0;
    }
    .l {
        display: inline-table;
        width: calc(38% - 10px) !important;
        margin-right: 0;
    }

    .xl {
        float: left;
        width: calc(100% - 160px) !important;
    }
    .xxl {
        float: left;
        width: calc(100% - 80px) !important;
    }

    .input-left-margin {
        margin-left: 10px;
    }

    textarea:disabled,
    input:disabled {
        background-color: white;
    }
`;
export default PayModal;
